import axios from "../libs/axios";
import { storage } from "../libs/localStorage";

const { retrieveToken } = storage();
const token = retrieveToken();
const GetProcessedJobs = async (body) => {
  const {
    pageno,
    pagesize,
    search,
    isactivecandidate,
    isactivejob,
    startdate,
    enddate,
  } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getprocessedjobs",
      pageno: pageno,
      pagesize: pagesize,
      search: search,
      isactivecandidate: isactivecandidate,
      isactivejob: isactivejob,
      startdate: startdate,
      enddate: enddate,
    },
  });
  return data;
};

const GetAtsUrlByAtsJobcode = async (body) => {
  const { atsjobcode } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getatsurlbyatsjobcode",
      atsjobcode: atsjobcode,
    },
  });
  return data;
};

const GetLatestProcessedJobs = async (body) => {
  const { customer, filterdate } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getlatestprocessedjobs",
      customer: customer,
      filterdate: filterdate,
    },
  });
  return data;
};

export const useJobsData = () => ({
  GetProcessedJobs,
  GetAtsUrlByAtsJobcode,
  GetLatestProcessedJobs,
});
