import React, { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import Navbar from "../../components/Navbar/Navbar";
import Box from "@mui/material/Box";
import "./ProcessedJobs.css";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { useParams } from "react-router-dom";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useJobsData } from "../../data/jobsdata";
import { auth } from "../../data/auth";
import Grid from "@mui/material/Grid";
import { experimentalStyled as styled } from "@mui/material/styles";
import { ListItemIcon, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";
import { storage } from "../../libs/localStorage";
import Pagination from "../../components/Pagination/Pagination";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";
import HeaderBar from "../../components/HeaderBar/HeaderBar";

export default function ProcessedJobs() {
  const location = useLocation();
  const { param } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const [data, setData] = useState(null);
  const [totalCounts, setTotalCounts] = useState(null);
  // const [userToken, setUserToken] = useState(null);
  const { GetProcessedJobs, GetAtsUrlByAtsJobcode } = useJobsData();
  const { VerifyToken } = auth();
  const { storeToken, retrieveToken, clearToken } = storage();
  // const [badgeContent, setBadgeContent] = useState(2);
  const navigate = useNavigate();
  const [toggleView, setToggleView] = useState(true);
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(totalCounts / pageSize);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("820"));
  const [searchText, setSearchText] = useState("");
  const [username, setUsername] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);

  const baseUrl = location.pathname;

  useEffect(() => {
    navigate(baseUrl, { replace: true });
  }, [baseUrl, navigate]);

  function getOpenDayLabel(openDays) {
    const dayLabels = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const today = new Date();
    const openDate = new Date(today);
    openDate.setDate(today.getDate() - openDays);

    const daysAgo = Math.floor((today - openDate) / (1000 * 60 * 60 * 24));
    const todayDayOfWeek = today.getDay();
    const openDayOfWeek = openDate.getDay();

    if (daysAgo === 0) {
      return "Opened Today";
    } else if (daysAgo === 1) {
      return "Opened Yesterday";
    } else if (daysAgo < 7) {
      if (openDayOfWeek > todayDayOfWeek) {
        return `Opened on ${openDate.toLocaleDateString("en-US")}`;
      } else {
        return `Opened ${dayLabels[openDayOfWeek]}`;
      }
    } else {
      return `Opened on ${openDate.toLocaleDateString("en-US")}`;
    }
  }
  const clearDates = () => {
    setStartDate(null);
    setEndDate(null);
    setDateRange([
      {
        startDate: null,
        endDate: null,
        key: "selection",
      },
    ]);
  };

  function truncateString(str, num) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  }

  const handleSelect = (ranges) => {
    const { selection } = ranges;

    if (!startDate && selection?.startDate) {
      // Set the start date and initialize the date range
      setStartDate(selection.startDate);
      setDateRange([{ ...selection, endDate: null }]);
    } else if (startDate && !endDate && selection?.endDate) {
      // Set the end date and complete the date range
      setEndDate(selection.endDate);
      setDateRange([selection]);
    } else if (startDate && endDate && selection?.startDate) {
      // Clear both dates and set the new start date
      setStartDate(selection.startDate);
      setEndDate(null);
      setDateRange([{ ...selection, endDate: null }]);
    }
  };

  const currentDate = new Date();
  const isDateSelected1 =
    dateRange[0]?.startDate !== null && dateRange[0]?.endDate !== null;
  const isDateSelected2 =
    dateRange[0]?.endDate !== null && dateRange[0]?.endDate !== null;
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(true);
  const [isActiveCand, setIsActiveCand] = useState(false);
  const [isActiveJobs, setIsActiveJobs] = useState(false);
  const [isDate, setIsDate] = useState(false);
  const searchTextChange = (event) => {
    setSearchText(event.target.value);
  };
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
      window.scrollTo(0, 0);
    }
  };

  const clearFilter = () => {
    setIsActiveCand(false);
    setIsActiveJobs(false);
    setIsDate(false);
  };

  const applyAllFilter = () => {
    setIsActiveCand(true);
    setIsActiveJobs(true);
    setIsDate(true);
  };

  const formatDate = (date) => {
    const day = date?.getDate();
    const month = date?.getMonth() + 1;
    const year = date?.getFullYear();
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${year}-${formattedMonth}-${formattedDay}`;
  };

  const handleDrawerClick = (event) => {
    event.stopPropagation();
  };

  const [openSecondDrawer, setOpenSecondDrawer] = useState(false);
  const [openPhoneDrawer, setOpenPhoneDrawer] = useState(false);

  const toggleSecondDrawer = () => {
    setOpenSecondDrawer(!openSecondDrawer);
  };

  const togglePhoneDrawer = () => {
    setOpenPhoneDrawer(!openPhoneDrawer);
  };

  const handleVerifyToken = async (token) => {
    try {
      const response = await VerifyToken({ token });
      if (response?.header?.status === 200) {
        // setUserToken(token);
        storeToken(token);
        localStorage.setItem("usertype", response?.data?.usertype);
        localStorage.setItem(
          "customerpanelurl",
          response?.data?.customerpanelurl
        );
        localStorage.setItem("customerid", response?.data?.customerid);
        localStorage.setItem("username", response?.data?.firstname);
        localStorage.setItem("customername", response?.data?.customername);
        localStorage.setItem("emailid", response?.data?.emailid);
        setUsername(response?.data?.firstname);

        await fetchJobs(searchText);
      } else {
        window.location.replace(process.env.REACT_APP_API_LOGIN_URL);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchJobs = async (searchText = "", currentPage = 1) => {
    try {
      const response = await GetProcessedJobs({
        pageno: currentPage,
        pagesize: pageSize,
        search: searchText,
        isactivecandidate: isActiveCand,
        isactivejob: isActiveJobs,
        startdate: isDate ? formatDate(dateRange[0]?.startDate) : "",
        enddate: isDate ? formatDate(dateRange[0]?.endDate) : "",
      });

      if (response?.data == (undefined || null)) {
        setData([]);
        setTotalCounts(0);
        setNoDataAvailable(true);
      } else {
        setData(
          response?.data?.map((x) => ({
            ...x,
            candidatesdata: JSON.parse(x.candidatesdata),
          }))
        );
        response?.data[0]?.processedjobscount &&
          setTotalCounts(response?.data[0]?.processedjobscount);
        setNoDataAvailable(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const initialize = async () => {
      const token = retrieveToken();
      if (token) {
        // setUserToken(token);
        setUsername(localStorage.getItem("username"));
        await fetchJobs(searchText, currentPage);
      } else {
        window.location.replace(process.env.REACT_APP_API_LOGIN_URL);
      }
    };

    if (token) {
      handleVerifyToken(token);
    } else {
      initialize();
    }
  }, []);

  useEffect(() => {
    const initialize = async () => {
      if (currentPage > 0) {
        setUsername(localStorage.getItem("username"));
        await fetchJobs(searchText, currentPage);
      }
    };

    initialize();
  }, [currentPage]);

  useEffect(() => {
    btnSearch();
  }, [isActiveCand, isActiveJobs, isDate]);

  useEffect(() => {
    isDate && btnSearch();
  }, [dateRange]);

  const activetab = "processedjobs";
  // const redirectToDetail = (analysisid) => {
  //   navigate(`/candidateresult/${analysisid}`);
  // };

  const redirectToAts = (params) => {
    const { atsjobcode, atsid } = params;
    if (atsid) {
      GetAtsUrlByAtsJobcode({
        atsjobcode: atsjobcode,
      })
        .then((response) => {
          window.open(response?.data[0]?.atsurl, "_blank");
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
    }
  };

  const [openDrawer, setOpenDrawer] = useState(false);
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const btnViewMore = (params) => {
    navigate("/processedcandidate", { state: params });
  };

  const btnSearch = async () => {
    setCurrentPage(1);
    await fetchJobs(searchText);
  };

  const clearSearch = async () => {
    setCurrentPage(1);
    setSearchText("");
    await fetchJobs();
  };

  const formatName = (name) => {
    if (!name) return "";
    return name
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.white,
      fontFamily: "Rubik, Medium",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontFamily: "Rubik, Regular",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#EDF4FF",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#ffffff",
    },
  }));

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    lineHeight: "24px",
    color: theme.palette.text.secondary,
  }));

  return (
    <>
      {isMobile ? (
        <>
          <Helmet>
            <style>{"body { background-color: #F3F5F9;}"}</style>
            <title>Processed Jobs</title>
          </Helmet>
          <React.Fragment>
            {/* <div style={{ position: 'sticky', top: '0', bottom: '0', background: '#F3F5F9' }}>
              <div className='col-mobile'>
                <p className='pro-mobile' onClick={toggleDrawer}>
                  &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                  Processed Jobs
                </p> */}
            {/* 
              <Badge color="error" variant="standard" onClick={toggleDrawer} sx={{
                '& .MuiBadge-dot': {
                  backgroundColor: '#f00',
                  border: '2px solid #fff',
                  position: 'absolute',
                  top: '3px',
                  right: '8px',
                  transform: 'translate(50%, -50%)',
                },
              }}> */}
            {/* <NotificationsIcon className='noti' /> */}
            {/* </Badge> */}
            {/* </div> */}

            <Navbar data={activetab} />
            <div
              style={{
                margin: "18px 10px 8px 10px",
              }}
            >
              <div class="search-containerss">
                <input
                  type="text"
                  id="search"
                  placeholder="Search Job ID, Job Title & Candidate"
                  value={searchText}
                  onChange={searchTextChange}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") btnSearch();
                  }}
                />

                {searchText && (
                  <button
                    style={{
                      position: "absolute",
                      right: "50px",
                      border: "none",
                      outline: "none",
                      background: "transparent",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={clearSearch}
                  >
                    <img
                      src="/images/greenCross1.svg"
                      style={{ width: "10px", height: "10px" }}
                      alt="Clear"
                    />
                  </button>
                )}
                <button type="submit" onClick={() => btnSearch()}>
                  <img
                    style={{ background: "inherit" }}
                    src="/images/Search Icon.svg"
                  />
                </button>
              </div>
            </div>
            {/* </div> */}
          </React.Fragment>
          {/* {toggleView ? (<> </>) :
          ( */}
          <>
            {noDataAvailable ? (
              <div className="flex justify-center w-full h-[70vh] flex-col gap-2 items-center">
                <div className=" rounded-full border-gray-500  border-2 flex w-12 h-12 justify-center items-center">
                  <p className="text-[30px] text-gray-500 font-bold">!</p>
                </div>
                <p className="text-[20px] text-gray-500 font-bold">
                  Data not available
                </p>
              </div>
            ) : (
              <Grid
                container
                spacing={2}
                columns={isMobile ? 1 : { xs: 4, sm: 8, md: 12 }}
              >
                {data &&
                  data?.map((data, i) => (
                    <Grid item xs={2} sm={4} md={4}>
                      <Item
                        style={{
                          marginTop: "15px",
                          marginRight: "10px",
                          marginLeft: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <p
                              style={{
                                color: "#21D6AA",
                                fontWeight: "600",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                redirectToAts({
                                  atsjobcode: data?.atsjobcode,
                                  atsid: data?.atsid,
                                })
                              }
                            >
                              <Tooltip
                                title={
                                  data?.atsjobcode?.length > 12
                                    ? data?.atsjobcode
                                    : ""
                                }
                              >
                                {data?.atsjobcode?.length > 12
                                  ? `${data?.atsjobcode?.substring(0, 12)}...`
                                  : data?.atsjobcode}
                              </Tooltip>
                            </p>

                            <p class="gray">
                              {data?.opendays > 0
                                ? data?.opendays > 1
                                  ? "Opened " + data?.opendays + " Days Ago"
                                  : "Opened " + data?.opendays + " Day Ago"
                                : "Opened Today"}
                            </p>
                          </div>
                          <div>
                            <p
                              style={{
                                color: "#1F2E39",
                                fontWeight: "600",
                                cursor: "pointer",
                              }}
                            >
                              <Tooltip
                                title={
                                  data?.jobtitle?.length > 25
                                    ? data?.jobtitle
                                    : ""
                                }
                              >
                                {data?.jobtitle?.length > 25
                                  ? `${data?.jobtitle?.substring(0, 25)}...`
                                  : data?.jobtitle}
                              </Tooltip>
                            </p>
                          </div>
                        </div>
                        <div class="grid-details">
                          <div class="under-de">
                            {data?.candidatesdata[0]?.candidatename ? (
                              <p
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  // redirectToDetail(
                                  //   data?.candidatesdata[0]?.analysisid
                                  // )
                                  navigate("/candidateresult", {
                                    state: data?.candidatesdata[0]?.analysisid,
                                  })
                                }
                              >
                                {data?.candidatesdata[0]?.candidatename}
                              </p>
                            ) : (
                              <p></p>
                            )}
                            {data?.candidatesdata[1]?.candidatename ? (
                              <p
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  // redirectToDetail(
                                  //   data?.candidatesdata[1]?.analysisid
                                  // )
                                  navigate("/candidateresult", {
                                    state: data?.candidatesdata[1]?.analysisid,
                                  })
                                }
                              >
                                {data?.candidatesdata[1]?.candidatename}
                              </p>
                            ) : (
                              <p></p>
                            )}
                            {data?.candidatesdata[2]?.candidatename ? (
                              <p
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  // redirectToDetail(
                                  //   data?.candidatesdata[2]?.analysisid
                                  // )
                                  navigate("/candidateresult", {
                                    state: data?.candidatesdata[2]?.analysisid,
                                  })
                                }
                              >
                                {data?.candidatesdata[2]?.candidatename}
                              </p>
                            ) : (
                              <p></p>
                            )}
                            {data?.candidatesdata[3]?.candidatename ? (
                              <p
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  // redirectToDetail(
                                  //   data?.candidatesdata[3]?.analysisid
                                  // )
                                  navigate("/candidateresult", {
                                    state: data?.candidatesdata[3]?.analysisid,
                                  })
                                }
                              >
                                {data?.candidatesdata[3]?.candidatename}
                              </p>
                            ) : (
                              <p></p>
                            )}
                            <p
                              onClick={() => btnViewMore(data)}
                              style={{ color: "#21D6AA", cursor: "pointer" }}
                            >
                              MORE..
                            </p>
                          </div>
                          <div
                            class="under-details"
                            style={{ textAlign: "center" }}
                          >
                            {data?.candidatesdata[0]?.candidatename ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  gap: "7px",
                                }}
                              >
                                <p
                                  style={{ color: "#708EA4", fontSize: "14px" }}
                                >
                                  {data?.candidatesdata[0]?.match_percentage}%
                                </p>
                                <img
                                  style={{ width: "20px", cursor: "pointer" }}
                                  onClick={() =>
                                    // redirectToDetail(
                                    //   data?.candidatesdata[0]?.analysisid
                                    // )
                                    navigate("/candidateresult", {
                                      state:
                                        data?.candidatesdata[0]?.analysisid,
                                    })
                                  }
                                  src="/images/arrow.svg"
                                />
                              </div>
                            ) : (
                              <div></div>
                            )}
                            {data?.candidatesdata[1]?.candidatename ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  gap: "7px",
                                }}
                              >
                                <p
                                  style={{ color: "#708EA4", fontSize: "14px" }}
                                >
                                  {data?.candidatesdata[1]?.match_percentage}%
                                </p>
                                <img
                                  style={{ width: "20px", cursor: "pointer" }}
                                  onClick={() =>
                                    // redirectToDetail(
                                    //   data?.candidatesdata[1]?.analysisid
                                    // )
                                    navigate("/candidateresult", {
                                      state:
                                        data?.candidatesdata[1]?.analysisid,
                                    })
                                  }
                                  src="/images/arrow.svg"
                                />
                              </div>
                            ) : (
                              <div></div>
                            )}
                            {data?.candidatesdata[2]?.candidatename ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  gap: "7px",
                                }}
                              >
                                <p
                                  style={{ color: "#708EA4", fontSize: "14px" }}
                                >
                                  {data?.candidatesdata[2]?.match_percentage}%
                                </p>
                                <img
                                  style={{ width: "20px", cursor: "pointer" }}
                                  onClick={() =>
                                    // redirectToDetail(
                                    //   data?.candidatesdata[2]?.analysisid
                                    // )
                                    navigate("/candidateresult", {
                                      state:
                                        data?.candidatesdata[2]?.analysisid,
                                    })
                                  }
                                  src="/images/arrow.svg"
                                />
                              </div>
                            ) : (
                              <div></div>
                            )}
                            {data?.candidatesdata[3]?.candidatename ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  gap: "7px",
                                }}
                              >
                                <p
                                  style={{ color: "#708EA4", fontSize: "14px" }}
                                >
                                  {data?.candidatesdata[3]?.match_percentage}%
                                </p>
                                <img
                                  style={{ width: "20px", cursor: "pointer" }}
                                  onClick={() =>
                                    // redirectToDetail(
                                    //   data?.candidatesdata[3]?.analysisid
                                    // )
                                    navigate("/candidateresult", {
                                      state:
                                        data?.candidatesdata[3]?.analysisid,
                                    })
                                  }
                                  src="/images/arrow.svg"
                                />
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        </div>
                      </Item>
                    </Grid>
                  ))}
              </Grid>
            )}
          </>
          {/* )} */}
          <div class="pagination-mainstick">
            <div class="sort-filter-mobile" onClick={togglePhoneDrawer}>
              <img src="/images/filter-mobile.svg" />
              <p>FILTER</p>
            </div>
          </div>
          {noDataAvailable ? (
            ""
          ) : (
            <Pagination
              canNextPage={currentPage < totalPages}
              canPrevPage={currentPage > 1}
              totalPages={totalPages}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
            />
          )}
          <React.Fragment>
            <Drawer
              anchor="bottom"
              open={openPhoneDrawer}
              onClose={togglePhoneDrawer}
              transitionDuration={500}
            >
              <Box
                sx={{ width: "100%", height: "100vh" }}
                role="presentation"
                onClick={togglePhoneDrawer}
              >
                <Box sx={{ p: 1 }}>
                  <IconButton onClick={togglePhoneDrawer}>
                    <CloseIcon sx={{ color: "#22D6AA" }} />
                  </IconButton>
                </Box>

                <List class="lsittt">
                  <div class="filtersss">
                    <text class="filterText">Filters</text>
                    <button
                      className="text-[#22D6AA] pr-4"
                      onClick={toggleSecondDrawer}
                      style={{
                        background: "transparent",
                        color: "#22D6AA",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        className="w-5 h-5"
                        src="/clear.svg"
                        alt="Clear Dates"
                      />
                    </button>
                  </div>
                  <div class="labelll">
                    <label class="checkbox-container">
                      Only Active Candidates
                      <input
                        type="checkbox"
                        checked={isActiveCand}
                        onChange={() => setIsActiveCand(!isActiveCand)}
                      />
                      <span class="checkmark"></span>
                    </label>

                    <label class="checkbox-container">
                      Only Active Jobs
                      <input
                        type="checkbox"
                        checked={isActiveJobs}
                        onChange={() => setIsActiveJobs(!isActiveJobs)}
                      />
                      <span class="checkmark"></span>
                    </label>

                    <label class="checkbox-container">
                      Date Range
                      <input
                        type="checkbox"
                        checked={isDate}
                        onChange={() => setIsDate(!isDate)}
                      />
                      <span class="checkmark"></span>
                    </label>

                    <div
                      onClick={handleDrawerClick}
                      onKeyDown={handleDrawerClick}
                    >
                      <div style={{ position: "relative" }}>
                        {isCalendarOpen && (
                          <div className="calendar-container">
                            <div
                              className="mt-3 w-auto"
                              style={{
                                position: "relative",
                                display: "inline-block",
                              }}
                            >
                              <div
                                className="border-2 w-[95%] rounded-md border-[#707070] px-3 flex justify-between"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginBottom: 10,
                                }}
                              >
                                <span>
                                  {startDate
                                    ? startDate?.toLocaleDateString()
                                    : "From Date"}
                                </span>
                                <span className="text-xl text-[#22D6AA]">
                                  {" "}
                                  {">"}{" "}
                                </span>
                                <span>
                                  {endDate
                                    ? endDate?.toLocaleDateString()
                                    : "To Date"}
                                </span>
                                {startDate && endDate && (
                                  <div className="w-4 h-4 items-center flex justify-center">
                                    <button
                                      className="text-[#22D6AA]"
                                      onClick={clearDates}
                                      style={{
                                        background: "transparent",
                                        color: "#22D6AA",
                                        border: "none",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <img
                                        src="/Layer2.svg"
                                        alt="Clear Dates"
                                      />
                                    </button>
                                  </div>
                                )}
                              </div>

                              <DateRange
                                showDateDisplay={false}
                                startDatePlaceholder="From Date"
                                endDatePlaceholder="To Date"
                                editableDateInputs={true}
                                onChange={handleSelect}
                                rangeColors={
                                  isDateSelected1 || isDateSelected2 === null
                                    ? "#22D6AA"
                                    : isDateSelected1 || isDateSelected2
                                    ? ["#22D6AA"]
                                    : "lightgray"
                                }
                                color={
                                  isDateSelected1 || isDateSelected2 === null
                                    ? "#22D6AA"
                                    : isDateSelected1 || isDateSelected2
                                    ? "#22D6AA"
                                    : "lightgray"
                                }
                                moveRangeOnFirstSelection={false}
                                ranges={dateRange}
                                maxDate={currentDate}
                                monthDisplayFormat={false}
                                showPreview={false}
                              />
                            </div>
                          </div>
                        )}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          color: "#21D6AA",
                          fontSize: "14px",
                          fontWeight: "500",
                          marginTop: "15px",
                        }}
                      >
                        <p
                          style={{ cursor: "pointer" }}
                          onClick={() => clearFilter()}
                        >
                          CLEAR ALL FILTERS
                        </p>
                        <p
                          style={{ marginRight: "20px", cursor: "pointer" }}
                          onClick={() => applyAllFilter()}
                        >
                          APPLY ALL
                        </p>
                      </div>
                    </div>
                  </div>
                </List>
              </Box>
            </Drawer>
          </React.Fragment>
        </>
      ) : (
        <>
          <Helmet>
            <style>{"body { background-color: #F3F5F9;}"}</style>
            <title>Processed Jobs</title>
          </Helmet>

          <Box
            className="flex w-full"
            sx={{ display: "flex", height: "100vh" }}
          >
            <Navbar data={activetab} />
            <Box
              className=" w-full px-6 pt-6"
              sx={{ flexGrow: 1, width: "80%" }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                {/* <div className="col">
                  <div>
                    <p className="pro">Processed Jobs</p>
                  </div>
                  <div className="flex flex-row gap-2">
                    <NotificationsIcon
                      className="noti mr-2`"
                      onClick={toggleDrawer}
                    />
                    {<img src="/jobLogo.svg"></img>}
                    {username && <p>{username}</p>}
                  </div>

                 
                </div> */}

                <HeaderBar
                  title={"Processed Jobs"}
                  toggleDrawer={toggleDrawer}
                />

                <div class="search-bar">
                  <div
                    class="left-side"
                    style={{
                      marginLeft: "2px",
                      marginTop: "-12px",
                      marginBottom: "5px",
                    }}
                  >
                    <div
                      className="search-container"
                      style={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        width: "500px",
                      }}
                    >
                      <input
                        type="text"
                        id="search"
                        placeholder="Search Job ID, Position & Resumes"
                        style={{
                          border: "none",
                          outline: "none",
                          height: "40px",
                          paddingLeft: "10px",
                          width: "200px",
                          flex: 1,
                        }}
                        value={searchText}
                        onChange={searchTextChange}
                        onKeyDown={(event) => {
                          if (event.key === "Enter") btnSearch();
                        }}
                      />

                      {searchText && (
                        <button
                          style={{
                            position: "absolute",
                            right: "40px",
                            border: "none",
                            outline: "none",
                            background: "transparent",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                          }}
                          onClick={clearSearch}
                        >
                          <img
                            src="/images/greenCross1.svg"
                            style={{ width: "10px", height: "10px" }}
                            alt="Clear"
                          />
                        </button>
                      )}

                      <button
                        style={{
                          border: "none",
                          outline: "none",
                          height: "40px",
                          paddingTop: "9px",
                        }}
                        type="submit"
                        onClick={() => btnSearch()}
                      >
                        <img
                          style={{ background: "inherit" }}
                          src="/images/Search Icon.svg"
                        />
                      </button>
                    </div>
                  </div>
                  <div class="right-side">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        class="filter-text"
                        onClick={() => toggleSecondDrawer()}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          style={{ width: "20px" }}
                          src="/images/Path 1665.svg"
                        ></img>
                        <p
                          style={{
                            color: "#21D6AA",
                            fontSize: "15px",
                            fontWeight: "500",
                            marginLeft: "10px",
                          }}
                        >
                          FILTERS
                        </p>
                      </div>
                      <div class="button-container">
                        <button
                          class="button1"
                          onClick={() => setToggleView(!toggleView)}
                          style={{
                            cursor: "pointer",
                            backgroundColor: toggleView ? "#21D6AA" : "white",
                          }}
                        >
                          {toggleView ? (
                            <img src="/images/3-dots.svg" alt="Button 1" />
                          ) : (
                            <img src="/images/green-list.svg" alt="Button 1" />
                          )}
                        </button>

                        <button
                          class="button2"
                          onClick={() => setToggleView(!toggleView)}
                          style={{
                            cursor: "pointer",
                            backgroundColor: !toggleView ? "#21D6AA" : "white",
                          }}
                        >
                          {!toggleView ? (
                            <img src="/images/white-grid.svg" alt="Button 2" />
                          ) : (
                            <img src="/images/4-box.svg" alt="Button 2" />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {toggleView ? (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        marginTop: "12px",
                        marginLeft: "6px",
                      }}
                    >
                      <Box
                        sx={{
                          flexGrow: 1,
                          pl: 1,
                          pr: 2,
                          width: "100%",
                          overflow: "auto",
                          WebkitOverflowScrolling: "touch",
                          "&::-webkit-scrollbar": {
                            display: "none",
                          },
                        }}
                      >
                        {noDataAvailable ? (
                          <div className="flex justify-center w-full h-[70vh] flex-col gap-2 items-center">
                            <div className=" rounded-full border-gray-500  border-2 flex w-12 h-12 justify-center items-center">
                              <p className="text-[30px] text-gray-500 font-bold">
                                !
                              </p>
                            </div>
                            <p className="text-[20px] text-gray-500 font-bold">
                              Data not available
                            </p>
                          </div>
                        ) : (
                          <>
                            <div
                              className=" w-full 2xl:block xl:block md:block sm:hidden pb-[2px]"
                              style={{
                                boxShadow:
                                  "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)",
                              }}
                            >
                              <TableContainer
                                component={Paper}
                                sx={{ borderRadius: "0px" }}
                              >
                                <div
                                  style={{
                                    position: "sticky",
                                    top: 0,
                                    zIndex: 1,
                                    backgroundColor: "white",
                                  }}
                                >
                                  <Table
                                    sx={{ minWidth: 700 }}
                                    aria-label="customized table"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <StyledTableCell
                                          className="!w-[17%]"
                                          style={{
                                            backgroundColor: "white",
                                            color: "#5A93ED",
                                            fontSize: "18px",
                                            fontFamily: "Rubik, Medium",
                                          }}
                                        >
                                          ATS JOB ID
                                        </StyledTableCell>
                                        <StyledTableCell
                                          className="!w-[17%]"
                                          style={{
                                            backgroundColor: "white",
                                            color: "#5A93ED",
                                            fontSize: "18px",
                                            fontFamily: "Rubik, Medium",
                                          }}
                                        >
                                          JOB TITLE
                                        </StyledTableCell>
                                        <StyledTableCell
                                          style={{
                                            backgroundColor: "white",
                                            color: "#5A93ED",
                                            fontSize: "18px",
                                            width: "240px",
                                            fontFamily: "Rubik, Medium",
                                            minWidth: "100%",
                                          }}
                                        >
                                          CANDIDATE RESULT
                                        </StyledTableCell>
                                      </TableRow>
                                    </TableHead>
                                  </Table>
                                </div>
                                <div
                                  className="max-h-[calc(100vh-328px)]"
                                  style={{
                                    overflow: "auto",
                                    WebkitOverflowScrolling: "touch",
                                    scrollbarWidth: "none",
                                    msOverflowStyle: "none",
                                    "&::-webkit-scrollbar": {
                                      display: "none",
                                    },
                                  }}
                                >
                                  <Table
                                    sx={{ minWidth: 700 }}
                                    aria-label="customized table"
                                  >
                                    <TableBody>
                                      {data &&
                                        data?.map((data, i) => (
                                          <StyledTableRow
                                            key={i}
                                            style={{ border: "noneb" }}
                                          >
                                            <StyledTableCell
                                              style={{
                                                borderBottom: "none",
                                              }}
                                              className="w-[17%]"
                                            >
                                              <p
                                                class="number"
                                                style={{
                                                  fontFamily: "Rubik, SemiBold",
                                                  maxWidth: "120px",
                                                  overflow: "hidden",
                                                  textOverflow: "ellipsis",
                                                  whiteSpace: "nowrap",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  redirectToAts({
                                                    atsjobcode:
                                                      data?.atsjobcode,
                                                    atsid: data?.atsid,
                                                  })
                                                }
                                              >
                                                <Tooltip
                                                  title={
                                                    data?.atsjobcode?.length >
                                                    12
                                                      ? data?.atsjobcode
                                                      : ""
                                                  }
                                                >
                                                  {data?.atsjobcode?.length > 12
                                                    ? `${data?.atsjobcode?.substring(
                                                        0,
                                                        12
                                                      )}...`
                                                    : data?.atsjobcode}
                                                </Tooltip>
                                              </p>
                                              <p
                                                class="open"
                                                style={{
                                                  fontFamily: "Rubik, Regular",
                                                  maxWidth: "120px",
                                                }}
                                              >
                                                {/* {data?.opendays > 0
                                                  ? data?.opendays > 1
                                                    ? `Opened ${data?.opendays} Days Ago`
                                                    : `Opened ${data?.opendays} Day Ago`
                                                  : "Opened Today"} */}
                                                {getOpenDayLabel(
                                                  data?.opendays
                                                )}
                                              </p>
                                            </StyledTableCell>

                                            <StyledTableCell
                                              style={{
                                                borderBottom: "none",
                                              }}
                                              className="w-[18%] "
                                            >
                                              <p
                                                style={{
                                                  fontFamily: "Rubik, Regular",
                                                }}
                                                className="text-[15px] lg:text-[13px] lg:max-w-[120px] mt-3 mb-3"
                                              >
                                                <Tooltip title={data?.jobtitle}>
                                                  {truncateString(
                                                    data?.jobtitle,
                                                    17
                                                  )}
                                                </Tooltip>
                                                {/* {data?.jobtitle.length > 44
                                                  ? `${data?.jobtitle.substring(
                                                      0,
                                                      44
                                                    )}...`
                                                  : data?.jobtitle} */}
                                              </p>
                                              {/* <p className='italic' >
                                                {
                                              formatName(
                                                                data
                                                                  ?.candidatesdata[0]
                                                                  ?.customername.substring(
                                                                    0,
                                                                    44
                                                                  )
                                                              )}
                                                              </p> */}
                                              <p className="italic">
                                                {formatName(
                                                  data?.candidatesdata[0]
                                                    ?.customername?.length > 15
                                                    ? data?.candidatesdata[0]?.customername?.substring(
                                                        0,
                                                        15
                                                      ) + "..."
                                                    : data?.candidatesdata[0]
                                                        ?.customername
                                                )}
                                              </p>

                                              {/* <p class="open">Job Title</p> */}
                                            </StyledTableCell>

                                            <StyledTableCell
                                              style={{
                                                borderBottom: "none",
                                                minWidth: "100%",
                                              }}
                                            >
                                              <div className="flex justify-between items-center">
                                                <div className="w-[42%] flex items-center justify-between">
                                                  <div className="raw w-[200px]">
                                                    <div className="colum">
                                                      {data?.candidatesdata[0]
                                                        ?.candidatename ? (
                                                        <p
                                                          style={{
                                                            cursor: "pointer",
                                                            fontFamily:
                                                              "Rubik, Medium",
                                                            fontSize: "15px",

                                                            border: "none",
                                                          }}
                                                          onClick={() =>
                                                            // redirectToDetail(
                                                            //   data
                                                            //     ?.candidatesdata[0]
                                                            //     ?.analysisid
                                                            // )
                                                            navigate(
                                                              "/candidateresult",
                                                              {
                                                                state:
                                                                  data
                                                                    ?.candidatesdata[0]
                                                                    ?.analysisid,
                                                              }
                                                            )
                                                          }
                                                        >
                                                          {data
                                                            ?.candidatesdata[0]
                                                            ?.candidatename
                                                            .length > 22
                                                            ? `${formatName(
                                                                data?.candidatesdata[0]?.candidatename.substring(
                                                                  0,
                                                                  22
                                                                )
                                                              )}...`
                                                            : formatName(
                                                                data
                                                                  ?.candidatesdata[0]
                                                                  ?.candidatename
                                                              )}
                                                        </p>
                                                      ) : (
                                                        <p></p>
                                                      )}
                                                      {data?.candidatesdata[1]
                                                        ?.candidatename ? (
                                                        <p
                                                          style={{
                                                            cursor: "pointer",
                                                            fontFamily:
                                                              "Rubik, Medium",
                                                            fontSize: "15px",
                                                          }}
                                                          onClick={() =>
                                                            // redirectToDetail(
                                                            //   data
                                                            //     ?.candidatesdata[1]
                                                            //     ?.analysisid
                                                            // )
                                                            navigate(
                                                              "/candidateresult",
                                                              {
                                                                state:
                                                                  data
                                                                    ?.candidatesdata[1]
                                                                    ?.analysisid,
                                                              }
                                                            )
                                                          }
                                                        >
                                                          {data
                                                            ?.candidatesdata[1]
                                                            ?.candidatename
                                                            ?.length > 22
                                                            ? `${formatName(
                                                                data?.candidatesdata[1]?.candidatename.substring(
                                                                  0,
                                                                  22
                                                                )
                                                              )}...`
                                                            : formatName(
                                                                data
                                                                  ?.candidatesdata[1]
                                                                  ?.candidatename
                                                              )}
                                                        </p>
                                                      ) : (
                                                        <p></p>
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div>
                                                    {data?.candidatesdata[0]
                                                      ?.candidatename ? (
                                                      <p
                                                        class="align-items-center"
                                                        style={{
                                                          cursor: "paste",
                                                        }}
                                                      >
                                                        {" "}
                                                        <span
                                                          class="per"
                                                          style={{
                                                            fontFamily:
                                                              "Rubik, Regular",
                                                          }}
                                                        >
                                                          {
                                                            data
                                                              ?.candidatesdata[0]
                                                              ?.match_percentage
                                                          }
                                                          %
                                                        </span>
                                                        &nbsp;
                                                        <img
                                                          class="arrow cursor-pointer"
                                                          src="/images/arrow.svg"
                                                          style={{
                                                            width: "19px",
                                                            height: "19px",
                                                          }}
                                                          onClick={() =>
                                                            // redirectToDetail(
                                                            //   data
                                                            //     ?.candidatesdata[0]
                                                            //     ?.analysisid
                                                            // )
                                                            navigate(
                                                              "/candidateresult",
                                                              {
                                                                state:
                                                                  data
                                                                    ?.candidatesdata[0]
                                                                    ?.analysisid,
                                                              }
                                                            )
                                                          }
                                                        />
                                                      </p>
                                                    ) : (
                                                      <p></p>
                                                    )}
                                                    {data?.candidatesdata[1]
                                                      ?.candidatename ? (
                                                      <p
                                                        class="align-items-center"
                                                        style={{
                                                          cursor: "paste",
                                                        }}
                                                      >
                                                        {" "}
                                                        <span
                                                          class="per"
                                                          style={{
                                                            fontFamily:
                                                              "Rubik, Regular",
                                                          }}
                                                        >
                                                          {
                                                            data
                                                              ?.candidatesdata[1]
                                                              ?.match_percentage
                                                          }
                                                          %
                                                        </span>
                                                        &nbsp;
                                                        <img
                                                          class="arrow cursor-pointer"
                                                          src="/images/arrow.svg"
                                                          style={{
                                                            width: "19px",
                                                            height: "19px",
                                                          }}
                                                          onClick={() =>
                                                            // redirectToDetail(
                                                            //   data
                                                            //     ?.candidatesdata[1]
                                                            //     ?.analysisid
                                                            // )
                                                            navigate(
                                                              "/candidateresult",
                                                              {
                                                                state:
                                                                  data
                                                                    ?.candidatesdata[1]
                                                                    ?.analysisid,
                                                              }
                                                            )
                                                          }
                                                        />
                                                      </p>
                                                    ) : (
                                                      <p></p>
                                                    )}
                                                  </div>
                                                </div>

                                                <div className="w-[42%] flex items-center justify-between">
                                                  <div className="colum w-[200px]">
                                                    {data?.candidatesdata[2]
                                                      ?.candidatename ? (
                                                      <p
                                                        style={{
                                                          cursor: "pointer",
                                                          fontFamily:
                                                            "Rubik, Medium",
                                                          fontSize: "15px",
                                                        }}
                                                        onClick={() =>
                                                          // redirectToDetail(
                                                          //   data
                                                          //     ?.candidatesdata[2]
                                                          //     ?.analysisid
                                                          // )
                                                          navigate(
                                                            "/candidateresult",
                                                            {
                                                              state:
                                                                data
                                                                  ?.candidatesdata[2]
                                                                  ?.analysisid,
                                                            }
                                                          )
                                                        }
                                                      >
                                                        {formatName(
                                                          data
                                                            ?.candidatesdata[2]
                                                            ?.candidatename
                                                        )}
                                                      </p>
                                                    ) : (
                                                      <p className="w-[200px]"></p>
                                                    )}
                                                    {data?.candidatesdata[3]
                                                      ?.candidatename ? (
                                                      <p
                                                        style={{
                                                          cursor: "pointer",
                                                          fontFamily:
                                                            "Rubik, Medium",
                                                          fontSize: "15px",
                                                        }}
                                                        onClick={() =>
                                                          // redirectToDetail(
                                                          //   data
                                                          //     ?.candidatesdata[3]
                                                          //     ?.analysisid
                                                          // )
                                                          navigate(
                                                            "/candidateresult",
                                                            {
                                                              state:
                                                                data
                                                                  ?.candidatesdata[3]
                                                                  ?.analysisid,
                                                            }
                                                          )
                                                        }
                                                      >
                                                        {formatName(
                                                          data
                                                            ?.candidatesdata[3]
                                                            ?.candidatename
                                                        )}
                                                      </p>
                                                    ) : (
                                                      <p className="w-[200px]">
                                                        {""}
                                                      </p>
                                                    )}
                                                  </div>

                                                  <div className="raw relative top-auto -left-5 2xl:left-0">
                                                    <div>
                                                      {data?.candidatesdata[2]
                                                        ?.candidatename ? (
                                                        <p
                                                          class="align-items-center"
                                                          style={{
                                                            cursor: "paste",
                                                          }}
                                                        >
                                                          {" "}
                                                          <span
                                                            class="per"
                                                            style={{
                                                              fontFamily:
                                                                "Rubik, Regular",
                                                            }}
                                                          >
                                                            {
                                                              data
                                                                ?.candidatesdata[2]
                                                                ?.match_percentage
                                                            }
                                                            %
                                                          </span>
                                                          &nbsp;
                                                          <img
                                                            class="arrow cursor-pointer"
                                                            src="/images/arrow.svg"
                                                            style={{
                                                              width: "19px",
                                                              height: "19px",
                                                            }}
                                                            onClick={() =>
                                                              // redirectToDetail(
                                                              //   data
                                                              //     ?.candidatesdata[2]
                                                              //     ?.analysisid
                                                              // )
                                                              navigate(
                                                                "/candidateresult",
                                                                {
                                                                  state:
                                                                    data
                                                                      ?.candidatesdata[2]
                                                                      ?.analysisid,
                                                                }
                                                              )
                                                            }
                                                          />
                                                        </p>
                                                      ) : (
                                                        <p></p>
                                                      )}
                                                      {data?.candidatesdata[3]
                                                        ?.candidatename ? (
                                                        <p
                                                          class="align-items-center"
                                                          style={{
                                                            cursor: "paste",
                                                          }}
                                                        >
                                                          {" "}
                                                          <span
                                                            class="per"
                                                            style={{
                                                              fontFamily:
                                                                "Rubik, Regular",
                                                            }}
                                                          >
                                                            {
                                                              data
                                                                ?.candidatesdata[3]
                                                                ?.match_percentage
                                                            }
                                                            %
                                                          </span>
                                                          &nbsp;
                                                          <img
                                                            class="arrow cursor-pointer"
                                                            src="/images/arrow.svg"
                                                            style={{
                                                              width: "19px",
                                                              height: "19px",
                                                            }}
                                                            onClick={() =>
                                                              // redirectToDetail(
                                                              //   data
                                                              //     ?.candidatesdata[3]
                                                              //     ?.analysisid
                                                              // )
                                                              navigate(
                                                                "/candidateresult",
                                                                {
                                                                  state:
                                                                    data
                                                                      ?.candidatesdata[3]
                                                                      ?.analysisid,
                                                                }
                                                              )
                                                            }
                                                          />
                                                        </p>
                                                      ) : (
                                                        <p></p>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>

                                                <div
                                                  class="box"
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() =>
                                                    btnViewMore(data)
                                                  }
                                                >
                                                  <span class="text">MORE</span>
                                                  &nbsp;
                                                  <img
                                                    class="icon"
                                                    style={{
                                                      width: "20px",
                                                      height: "20px",
                                                    }}
                                                    src="/images/black-arrow.svg"
                                                  />
                                                </div>
                                              </div>
                                            </StyledTableCell>
                                          </StyledTableRow>
                                        ))}
                                    </TableBody>
                                  </Table>
                                </div>
                              </TableContainer>
                            </div>
                          </>
                        )}
                      </Box>
                    </Box>
                  </>
                ) : (
                  <>
                    {noDataAvailable ? (
                      <div className="flex justify-center w-full h-[70vh] flex-col gap-2 items-center">
                        <div className=" rounded-full border-gray-500  border-2 flex w-12 h-12 justify-center items-center">
                          <p className="text-[30px] text-gray-500 font-bold">
                            !
                          </p>
                        </div>
                        <p className="text-[20px] text-gray-500 font-bold">
                          Data not available
                        </p>
                      </div>
                    ) : (
                      <div
                        className="max-h-[calc(110vh-328px)]"
                        style={{
                          overflow: "auto",
                          WebkitOverflowScrolling: "touch",
                          scrollbarWidth: "none",
                          msOverflowStyle: "none",
                          "&::-webkit-scrollbar": {
                            display: "none",
                          },
                        }}
                      >
                        <Box sx={{ display: "flex" }}>
                          <Box
                            component="main"
                            sx={{ flexGrow: 1, pt: 3, pl: 3, pr: 3 }}
                          >
                            <div
                              style={{
                                maxHeight: "400px",
                                overflow: "auto",
                                padding: "10px",
                                WebkitOverflowScrolling: "touch",
                                scrollbarWidth: "none",
                                msOverflowStyle: "none",
                                "&::-webkit-scrollbar": {
                                  display: "none",
                                },
                              }}
                            >
                              <div class="grid">
                                <Box sx={{ flexGrow: 1 }}>
                                  <Grid
                                    container
                                    spacing={{ xs: 2, md: 3 }}
                                    columns={{ xs: 4, sm: 8, md: 12 }}
                                  >
                                    <>
                                      {data &&
                                        data?.map((data, i) => (
                                          <Grid item xs={2} sm={4} md={4}>
                                            <div
                                              class="job-container"
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                redirectToAts({
                                                  atsjobcode: data?.atsjobcode,
                                                  atsid: data?.atsid,
                                                })
                                              }
                                            >
                                              <Tooltip
                                                title={
                                                  data?.atsjobcode?.length > 10
                                                    ? data?.atsjobcode
                                                    : ""
                                                }
                                              >
                                                <p className="job-id">
                                                  JOB ID:{" "}
                                                  {data?.atsjobcode?.length > 10
                                                    ? `${data?.atsjobcode.substring(
                                                        0,
                                                        10
                                                      )}...`
                                                    : data?.atsjobcode}
                                                </p>
                                              </Tooltip>
                                            </div>
                                            <Item>
                                              <p class="blue cursor-default">
                                                <Tooltip
                                                  title={
                                                    data?.jobtitle?.length > 35
                                                      ? data?.jobtitle
                                                      : ""
                                                  }
                                                >
                                                  {data?.jobtitle?.length > 35
                                                    ? `${data?.jobtitle.substring(
                                                        0,
                                                        35
                                                      )}...`
                                                    : data?.jobtitle}
                                                </Tooltip>
                                              </p>
                                              {/* <p class="gray">{data?.jobtitle}</p> */}
                                              <div class="grid-details">
                                                <div class="under-de">
                                                  {data?.candidatesdata[0]
                                                    ?.candidatename ? (
                                                    <p
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        // redirectToDetail(
                                                        //   data
                                                        //     ?.candidatesdata[0]
                                                        //     ?.analysisid
                                                        // )
                                                        navigate(
                                                          "/candidateresult",
                                                          {
                                                            state:
                                                              data
                                                                ?.candidatesdata[0]
                                                                ?.analysisid,
                                                          }
                                                        )
                                                      }
                                                    >
                                                      {data?.candidatesdata[0]
                                                        ?.candidatename
                                                        ?.length > 22
                                                        ? `${data?.candidatesdata[0]?.candidatename?.substring(
                                                            0,
                                                            22
                                                          )}...`
                                                        : data
                                                            ?.candidatesdata[0]
                                                            ?.candidatename}
                                                    </p>
                                                  ) : (
                                                    <p></p>
                                                  )}
                                                  {data?.candidatesdata[1]
                                                    ?.candidatename ? (
                                                    <p
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        // redirectToDetail(
                                                        //   data
                                                        //     ?.candidatesdata[1]
                                                        //     ?.analysisid
                                                        // )
                                                        navigate(
                                                          "/candidateresult",
                                                          {
                                                            state:
                                                              data
                                                                ?.candidatesdata[1]
                                                                ?.analysisid,
                                                          }
                                                        )
                                                      }
                                                    >
                                                      {data?.candidatesdata[1]
                                                        ?.candidatename
                                                        ?.length > 22
                                                        ? `${data?.candidatesdata[1]?.candidatename?.substring(
                                                            0,
                                                            22
                                                          )}...`
                                                        : data
                                                            ?.candidatesdata[1]
                                                            ?.candidatename}
                                                    </p>
                                                  ) : (
                                                    <p></p>
                                                  )}
                                                  {data?.candidatesdata[2]
                                                    ?.candidatename ? (
                                                    <p
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        // redirectToDetail(
                                                        //   data
                                                        //     ?.candidatesdata[2]
                                                        //     ?.analysisid
                                                        // )
                                                        navigate(
                                                          "/candidateresult",
                                                          {
                                                            state:
                                                              data
                                                                ?.candidatesdata[2]
                                                                ?.analysisid,
                                                          }
                                                        )
                                                      }
                                                    >
                                                      {data?.candidatesdata[2]
                                                        ?.candidatename
                                                        ?.length > 22
                                                        ? `${data?.candidatesdata[2]?.candidatename?.substring(
                                                            0,
                                                            22
                                                          )}...`
                                                        : data
                                                            ?.candidatesdata[2]
                                                            ?.candidatename}
                                                    </p>
                                                  ) : (
                                                    <p></p>
                                                  )}
                                                  {data?.candidatesdata[3]
                                                    ?.candidatename ? (
                                                    <p
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        // redirectToDetail(
                                                        //   data
                                                        //     ?.candidatesdata[3]
                                                        //     ?.analysisid
                                                        // )
                                                        navigate(
                                                          "/candidateresult",
                                                          {
                                                            state:
                                                              data
                                                                ?.candidatesdata[3]
                                                                ?.analysisid,
                                                          }
                                                        )
                                                      }
                                                    >
                                                      {data?.candidatesdata[3]
                                                        ?.candidatename
                                                        ?.length > 22
                                                        ? `${data?.candidatesdata[3]?.candidatename?.substring(
                                                            0,
                                                            22
                                                          )}...`
                                                        : data
                                                            ?.candidatesdata[3]
                                                            ?.candidatename}
                                                    </p>
                                                  ) : (
                                                    <p></p>
                                                  )}
                                                  <p
                                                    style={{
                                                      color: "#21D6AA",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      btnViewMore(data)
                                                    }
                                                  >
                                                    MORE..
                                                  </p>
                                                </div>

                                                <div
                                                  class="under-details"
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {data?.candidatesdata[0]
                                                    ?.candidatename ? (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "end",
                                                        gap: "7px",
                                                      }}
                                                    >
                                                      <p
                                                        style={{
                                                          cursor: "paste",
                                                          color: "#708EA4",
                                                          fontSize: "14px",
                                                        }}
                                                      >
                                                        {
                                                          data
                                                            ?.candidatesdata[0]
                                                            ?.match_percentage
                                                        }
                                                        %
                                                      </p>
                                                      <img
                                                        style={{
                                                          width: "20px",
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                          // redirectToDetail(
                                                          //   data
                                                          //     ?.candidatesdata[0]
                                                          //     ?.analysisid
                                                          // )
                                                          navigate(
                                                            "/candidateresult",
                                                            {
                                                              state:
                                                                data
                                                                  ?.candidatesdata[0]
                                                                  ?.analysisid,
                                                            }
                                                          )
                                                        }
                                                        src="/images/arrow.svg"
                                                      />
                                                    </div>
                                                  ) : (
                                                    <div></div>
                                                  )}
                                                  {data?.candidatesdata[1]
                                                    ?.candidatename ? (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "end",
                                                        gap: "7px",
                                                      }}
                                                    >
                                                      <p
                                                        style={{
                                                          color: "#708EA4",
                                                          fontSize: "14px",
                                                        }}
                                                      >
                                                        {
                                                          data
                                                            ?.candidatesdata[1]
                                                            ?.match_percentage
                                                        }
                                                        %
                                                      </p>
                                                      <img
                                                        style={{
                                                          width: "20px",
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                          // redirectToDetail(
                                                          //   data
                                                          //     ?.candidatesdata[1]
                                                          //     ?.analysisid
                                                          // )
                                                          navigate(
                                                            "/candidateresult",
                                                            {
                                                              state:
                                                                data
                                                                  ?.candidatesdata[1]
                                                                  ?.analysisid,
                                                            }
                                                          )
                                                        }
                                                        src="/images/arrow.svg"
                                                      />
                                                    </div>
                                                  ) : (
                                                    <div></div>
                                                  )}
                                                  {data?.candidatesdata[2]
                                                    ?.candidatename ? (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "end",
                                                        gap: "7px",
                                                      }}
                                                    >
                                                      <p
                                                        style={{
                                                          color: "#708EA4",
                                                          fontSize: "14px",
                                                        }}
                                                      >
                                                        {
                                                          data
                                                            ?.candidatesdata[2]
                                                            ?.match_percentage
                                                        }
                                                        %
                                                      </p>
                                                      <img
                                                        style={{
                                                          width: "20px",
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                          // redirectToDetail(
                                                          //   data
                                                          //     ?.candidatesdata[2]
                                                          //     ?.analysisid
                                                          // )
                                                          navigate(
                                                            "/candidateresult",
                                                            {
                                                              state:
                                                                data
                                                                  ?.candidatesdata[2]
                                                                  ?.analysisid,
                                                            }
                                                          )
                                                        }
                                                        src="/images/arrow.svg"
                                                      />
                                                    </div>
                                                  ) : (
                                                    <div></div>
                                                  )}
                                                  {data?.candidatesdata[3]
                                                    ?.candidatename ? (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "end",
                                                        gap: "7px",
                                                      }}
                                                    >
                                                      <p
                                                        style={{
                                                          color: "#708EA4",
                                                          fontSize: "14px",
                                                        }}
                                                      >
                                                        {
                                                          data
                                                            ?.candidatesdata[3]
                                                            ?.match_percentage
                                                        }
                                                        %
                                                      </p>
                                                      <img
                                                        style={{
                                                          width: "20px",
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                          // redirectToDetail(
                                                          //   data
                                                          //     ?.candidatesdata[3]
                                                          //     ?.analysisid
                                                          // )
                                                          navigate(
                                                            "/candidateresult",
                                                            {
                                                              state:
                                                                data
                                                                  ?.candidatesdata[3]
                                                                  ?.analysisid,
                                                            }
                                                          )
                                                        }
                                                        src="/images/arrow.svg"
                                                      />
                                                    </div>
                                                  ) : (
                                                    <div></div>
                                                  )}
                                                  <p
                                                    style={{
                                                      color: "#708EA4",
                                                      fontSize: "11px",
                                                    }}
                                                  >
                                                    {data?.opendays > 0
                                                      ? data?.opendays > 1
                                                        ? "Opened " +
                                                          data?.opendays +
                                                          " Days Ago"
                                                        : "Opened " +
                                                          data?.opendays +
                                                          " Day Ago"
                                                      : "Opened Today"}
                                                  </p>
                                                </div>
                                              </div>
                                            </Item>
                                          </Grid>
                                        ))}
                                    </>
                                  </Grid>
                                </Box>
                              </div>
                            </div>
                          </Box>
                        </Box>
                      </div>
                    )}
                  </>
                )}
                {noDataAvailable ? (
                  " "
                ) : (
                  <Pagination
                    canNextPage={currentPage < totalPages}
                    canPrevPage={currentPage > 1}
                    totalPages={totalPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  />
                )}
              </div>
            </Box>
          </Box>
          <React.Fragment>
            <Drawer
              className="w-[24%]"
              anchor="right"
              open={openSecondDrawer}
              onClose={toggleSecondDrawer}
              transitionDuration={500}
            >
              <Box
                sx={{ width: 360 }}
                role="presentation"
                onClick={toggleSecondDrawer}
                className="h-full overflow-y-auto"
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    p: 1,
                    position: "absolute",
                    top: 12,
                    right: 8,
                    p: 1,
                  }}
                >
                  <List className="lsittt">
                    <div className="filtersss">
                      <text className="filterText">Filters</text>
                      <button
                        className="text-[#22D6AA] pr-4"
                        onClick={toggleSecondDrawer}
                        style={{
                          background: "transparent",
                          color: "#22D6AA",
                          border: "none",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          className="w-4 h-"
                          src="/clear.svg"
                          alt="Clear Dates"
                        />
                      </button>
                    </div>
                    <div className="labelll">
                      <label className="checkbox-container">
                        Only Active Candidates
                        <input
                          type="checkbox"
                          checked={isActiveCand}
                          onChange={() => setIsActiveCand(!isActiveCand)}
                        />
                        <span className="checkmark"></span>
                      </label>

                      <label className="checkbox-container">
                        Only Active Jobs
                        <input
                          type="checkbox"
                          checked={isActiveJobs}
                          onChange={() => setIsActiveJobs(!isActiveJobs)}
                        />
                        <span className="checkmark"></span>
                      </label>

                      <label className="checkbox-container">
                        Date Range
                        <input
                          type="checkbox"
                          checked={isDate}
                          onChange={() => setIsDate(!isDate)}
                        />
                        <span className="checkmark"></span>
                      </label>

                      <div
                        onClick={handleDrawerClick}
                        onKeyDown={handleDrawerClick}
                      >
                        <div style={{ position: "relative" }}>
                          {isCalendarOpen && (
                            <div className="calendar-container">
                              <div
                                className="mt-3 w-auto"
                                style={{
                                  position: "relative",
                                  display: "inline-block",
                                }}
                              >
                                <div
                                  className="border-2 w-[95%] rounded-md border-[#707070] px-3 flex justify-between"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: 10,
                                  }}
                                >
                                  <span>
                                    {startDate
                                      ? startDate?.toLocaleDateString()
                                      : "From Date"}
                                  </span>
                                  <span className="text-xl text-[#22D6AA]">
                                    {" "}
                                    {">"}{" "}
                                  </span>
                                  <span>
                                    {endDate
                                      ? endDate?.toLocaleDateString()
                                      : "To Date"}
                                  </span>
                                  {startDate && endDate && (
                                    <div className="w-4 h-4 items-center flex justify-center">
                                      <button
                                        className="text-[#22D6AA]"
                                        onClick={clearDates}
                                        style={{
                                          background: "transparent",
                                          color: "#22D6AA",
                                          border: "none",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <img
                                          src="/Layer2.svg"
                                          alt="Clear Dates"
                                        />
                                      </button>
                                    </div>
                                  )}
                                </div>

                                <DateRange
                                  showDateDisplay={false}
                                  startDatePlaceholder="From Date"
                                  endDatePlaceholder="To Date"
                                  editableDateInputs={true}
                                  onChange={handleSelect}
                                  rangeColors={
                                    isDateSelected1 || isDateSelected2 === null
                                      ? "#22D6AA"
                                      : isDateSelected1 || isDateSelected2
                                      ? ["#22D6AA"]
                                      : "lightgray"
                                  }
                                  color={
                                    isDateSelected1 || isDateSelected2 === null
                                      ? "#22D6AA"
                                      : isDateSelected1 || isDateSelected2
                                      ? "#22D6AA"
                                      : "lightgray"
                                  }
                                  moveRangeOnFirstSelection={false}
                                  ranges={dateRange}
                                  maxDate={currentDate}
                                  monthDisplayFormat={false}
                                  showPreview={false}
                                />
                              </div>
                            </div>
                          )}
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            color: "#21D6AA",
                            fontSize: "14px",
                            fontWeight: "500",
                            marginTop: "15px",
                          }}
                        >
                          <p
                            style={{ cursor: "pointer" }}
                            onClick={() => clearFilter()}
                          >
                            CLEAR ALL FILTERS
                          </p>
                          <p
                            style={{ marginRight: "20px", cursor: "pointer" }}
                            onClick={() => applyAllFilter()}
                          >
                            APPLY ALL
                          </p>
                        </div>
                      </div>
                    </div>
                  </List>
                </Box>
              </Box>
            </Drawer>
          </React.Fragment>
          <React.Fragment>
            <Drawer
              anchor="right"
              open={openDrawer}
              onClose={toggleDrawer}
              transitionDuration={500}
            >
              <Box
                sx={{ width: 350 }}
                role="presentation"
                onClick={toggleDrawer}
              >
                <IconButton
                  onClick={toggleDrawer}
                  sx={{ position: "absolute", top: 12, right: 8, p: 1 }}
                >
                  <CloseIcon sx={{ color: "#22D6AA" }} />
                </IconButton>
                {/* List to display notifications */}
                <List sx={{ position: "absolute" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      paddingRight: "20px",
                    }}
                  >
                    <ListItem button>
                      <ListItemText
                        primary="Notifications"
                        primaryTypographyProps={{
                          style: {
                            width: "130px",
                            height: "24px",
                            color: theme.palette.text.primary, // Use your theme's text color
                            textAlign: "left",
                            fontFamily: "Rubik", // Assuming Rubik is included in your project
                            fontWeight: "bold",
                            fontSize: "20px",
                            lineHeight: "23px",
                            letterSpacing: "0px",
                            textTransform: "capitalize",
                            opacity: 1,
                          },
                        }}
                      />
                    </ListItem>
                    {/* <Badge badgeContent={<span style={{ color: '#fff',marginTop:"48px",paddingRight: "20px"}}>{badgeContent}</span>} sx={{
                    '& .MuiBadge-dot': {
                      backgroundColor: '#f00',
                      border: '2px solid #fff',
                      position: 'absolute',
                      top: '3px',
                      right: '4px',
                      transform: 'translate(50%, -50%)',
                    },
                  }}>
                    <img src="/images/Ellipse_1.svg" alt="Notifications" />
                  </Badge> */}
                  </div>
                  {/* <ListItem button>
                  <ListItemIcon
                    sx={{
                      top: "109px",
                      left: "1070px",
                      minWidth: 21,
                      minHeight: 20,
                      mr: "auto",
                      justifyContent: "center",
                      paddingBottom: "10px"
                    }}
                  > <img src="/images/Announcement Icon.svg" alt="Notifications" />

                  </ListItemIcon>
                  <ListItemText
                    primary="New announcement text similique deserunt consequuntur."
                    primaryTypographyProps={{
                      style: {
                        width: '299px',
                        height: '41px',
                        color: '#1F2E39',
                        textAlign: 'left',
                        fontFamily: 'Rubik',
                        fontSize: '15px',
                        lineHeight: '23px',
                        letterSpacing: '0px',
                        opacity: '1',
                        paddingLeft: "10px"
                      },
                    }}
                  />

                </ListItem>

                <ListItem button>
                  <ListItemIcon
                    sx={{
                      top: "109px",
                      left: "1070px",
                      minWidth: 21,
                      minHeight: 20,
                      mr: "auto",
                      justifyContent: "center",
                      paddingBottom: "10px"
                    }}
                  >
                    <img src="/images/Announcement Icon.svg" alt="Notifications" />
                  </ListItemIcon>

                  <ListItemText
                    primary="Consequatur rerum dolor quas quidem similique deserunt consequuntur."
                    primaryTypographyProps={{
                      style: {
                        width: '299px',
                        height: '41px',
                        color: '#1F2E39',
                        textAlign: 'left',
                        fontFamily: 'Rubik',
                        fontSize: '15px',
                        lineHeight: '23px',
                        letterSpacing: '0px',
                        opacity: '1',
                        paddingLeft: "10px"
                      },
                    }}
                  />

                </ListItem>

                <ListItem button>
                  <ListItemIcon
                    sx={{
                      top: "109px",
                      left: "1070px",
                      minWidth: 21,
                      minHeight: 20,
                      mr: "auto",
                      justifyContent: "center",
                      paddingBottom: "10px"
                    }}
                  >
                    <img src="/images/Attention Icon.svg" alt="Notifications" />
                  </ListItemIcon>

                  <ListItemText
                    primary="Warning or attention text quas quidem similique deserunt consequuntur."
                    primaryTypographyProps={{
                      style: {
                        width: '299px',
                        height: '41px',
                        color: '#1F2E39',
                        textAlign: 'left',
                        fontFamily: 'Rubik',
                        fontSize: '15px',
                        lineHeight: '23px',
                        letterSpacing: '0px',
                        opacity: '1',
                        paddingLeft: "10px"
                      },
                    }}
                  />

                </ListItem>
                <ListItem button>
                  <ListItemIcon
                    sx={{
                      // top: "109px",
                      // left: "1070px",
                      minWidth: 21,
                      minHeight: 20,
                      mr: "auto",
                      justifyContent: "center",
                      paddingBottom: "2px"
                    }}
                  >
                    <img src="/images/Resume Icon.svg" alt="Notifications" />
                  </ListItemIcon>

                  <ListItemText
                    primary="10 new match results added."
                    primaryTypographyProps={{
                      style: {
                        width: '299px',
                        height: '5px',
                        color: '#1F2E39',
                        textAlign: 'left',
                        fontFamily: 'Rubik',
                        fontSize: '15px',
                        lineHeight: '10px',
                        letterSpacing: '0px',
                        opacity: '1',
                        paddingLeft: "10px"
                      },
                    }}
                  />

                </ListItem>
                <ListItem button>
                  <ListItemIcon
                    sx={{
                      top: "109px",
                      left: "1070px",
                      minWidth: 21,
                      minHeight: 20,
                      mr: "auto",
                      justifyContent: "center",
                      paddingBottom: "10px"
                    }}
                  >
                    <img src="/images/Announcement Icon.svg" alt="Notifications" />
                  </ListItemIcon>

                  <ListItemText
                    primary="Consequatur rerum dolor quas quidem similique deserunt consequuntur."
                    primaryTypographyProps={{
                      style: {
                        width: '299px',
                        height: '41px',
                        color: '#1F2E39',
                        textAlign: 'left',
                        fontFamily: 'Rubik',
                        fontSize: '15px',
                        lineHeight: '23px',
                        letterSpacing: '0px',
                        opacity: '1',
                        paddingLeft: "10px"
                      },
                    }}
                  />

                </ListItem>
                <ListItem button>
                  <ListItemIcon
                    sx={{
                      top: "109px",
                      left: "1070px",
                      minWidth: 21,
                      minHeight: 20,
                      mr: "auto",
                      justifyContent: "center",
                      paddingBottom: "10px"
                    }}
                  >
                    <img src="/images/Announcement Icon.svg" alt="Notifications" />
                  </ListItemIcon>

                  <ListItemText
                    primary="Consequatur rerum dolor quas quidem similique deserunt consequuntur."
                    primaryTypographyProps={{
                      style: {
                        width: '299px',
                        height: '41px',
                        color: '#1F2E39',
                        textAlign: 'left',
                        fontFamily: 'Rubik',
                        fontSize: '15px',
                        lineHeight: '23px',
                        letterSpacing: '0px',
                        opacity: '1',
                        paddingLeft: "10px"
                      },
                    }}
                  />

                </ListItem>
                <ListItem button>
                  <ListItemIcon
                    sx={{
                      top: "109px",
                      left: "1070px",
                      minWidth: 21,
                      minHeight: 20,
                      mr: "auto",
                      justifyContent: "center",
                      paddingBottom: "10px"
                    }}
                  >
                    <img src="/images/Attention Icon.svg" alt="Notifications" />
                  </ListItemIcon>

                  <ListItemText
                    primary="Consequatur rerum dolor quas quidem similique deserunt consequuntur."
                    primaryTypographyProps={{
                      style: {
                        width: '299px',
                        height: '41px',
                        color: '#1F2E39',
                        textAlign: 'left',
                        fontFamily: 'Rubik',
                        fontSize: '15px',
                        lineHeight: '23px',
                        letterSpacing: '0px',
                        opacity: '1',
                        paddingLeft: "12px"
                      },
                    }}
                  />

                </ListItem>
                <ListItem button>
                  <ListItemIcon
                    sx={{
                      top: "109px",
                      left: "1070px",
                      minWidth: 21,
                      minHeight: 20,
                      mr: "auto",
                      justifyContent: "center",
                      paddingBottom: "10px"
                    }}
                  >
                    <img src="/images/Announcement Icon.svg" alt="Notifications" />
                  </ListItemIcon>

                  <ListItemText
                    primary="Consequatur rerum dolor quas quidem similique deserunt consequuntur."
                    primaryTypographyProps={{
                      style: {
                        width: '299px',
                        height: '41px',
                        color: '#1F2E39',
                        textAlign: 'left',
                        fontFamily: 'Rubik',
                        fontSize: '15px',
                        lineHeight: '23px',
                        letterSpacing: '0px',
                        opacity: '1',
                        paddingLeft: "10px"
                      },
                    }}
                  />

                </ListItem>
                <ListItem button>
                  <ListItemIcon
                    sx={{
                      top: "109px",
                      left: "1070px",
                      minWidth: 21,
                      minHeight: 20,
                      mr: "auto",
                      justifyContent: "center",
                      paddingBottom: "10px"
                    }}
                  >
                    <img src="/images/Announcement Icon.svg" alt="Notifications" />
                  </ListItemIcon>

                  <ListItemText
                    primary="Consequatur rerum dolor quas quidem similique deserunt consequuntur."
                    primaryTypographyProps={{
                      style: {
                        width: '299px',
                        height: '41px',]
                        color: '#1F2E39',
                        textAlign: 'left',
                        fontFamily: 'Rubik',
                        fontSize: '15px',
                        lineHeight: '23px',
                        letterSpacing: '0px',
                        opacity: '1',
                        paddingLeft: "10px"
                      },
                    }}
                  />

                </ListItem> */}
                </List>
              </Box>
            </Drawer>
          </React.Fragment>
        </>
      )}
    </>
  );
}
