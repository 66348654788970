import axios from "../libs/axios";
import { storage } from "../libs/localStorage";

const { retrieveToken } = storage();
const token = retrieveToken();
const GetCandidateResult = async (analysisid) => {
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getcandidateanalysislist",
      analysisid: analysisid,
    },
  });
  return data;
};

const GetProcessedCandidateByJob = async (body) => {
  const { jobid, search, sortby } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getprocessedcandidates",
      jobid: jobid,
      search: search,
      sortby: sortby,
    },
  });
  return data;
};

const MarkAnalysisFavorite = async (body) => {
  const { analysisid, isfavorite } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "updatemarkanalysisfavorite",
      analysisid: analysisid,
      isfavorite: isfavorite,
    },
  });
  return data;
};

const MarkCandidateFavorite = async (body) => {
  const { candidateid, isfavorite } = body;
  const { data } = await axios.request({
    url: "/v1/markcandidatefavorite",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      candidateid: candidateid,
      isfavorite: isfavorite,
    },
  });
  return data;
};

const GetCustomerById = async () => {
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getcustomerbyid",
    },
  });
  return data;
};

const GetProcessedJobsCandidates = async (body) => {
  const { pageno, pagesize, search, isfavorite, customerid } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getprocessedjobscandidates",
      pageno: pageno,
      pagesize: pagesize,
      customerid: customerid,
      search: search,
      isfavorite: isfavorite,
    },
  });
  return data;
};

const GetProcessedJobsCandidatesCount = async (body) => {
  const { search, isfavorite } = body;
  const { data } = await axios.request({
    url: "/v1/getprocessedjobscandidatescount",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      search: search,
      isfavorite: isfavorite,
    },
  });
  return data;
};

const GetLatestResumes = async (body) => {
  const { customer, filterdate } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getprocessedresumes",
      customer: customer,
      filterdate: filterdate,
    },
  });
  return data;
};

const sendEmailPdf = async (formData) => {
  const { data } = await axios.request({
    url: "/v1/sendemail",
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: formData,
  });
  return data;
};

export const useCandidateData = () => ({
  GetCandidateResult,
  GetProcessedCandidateByJob,
  MarkAnalysisFavorite,
  MarkCandidateFavorite,
  GetCustomerById,
  GetProcessedJobsCandidates,
  GetProcessedJobsCandidatesCount,
  GetLatestResumes,
  sendEmailPdf,
});
