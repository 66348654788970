import React, { useState, useEffect, useRef } from "react";
import "./ProcessedCandidateByJob.css";
import Box from "@mui/material/Box";
import Navbar from "../../components/Navbar/Navbar";
import { useNavigate, useLocation } from "react-router-dom";
import { useCandidateData } from "../../data/candidatedata";
import MarkFavorite from "../../components/MarkFavorite/MarkFavorite";
import { Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { storage } from "../../libs/localStorage";
import { Helmet } from "react-helmet";
import { useJobsData } from "../../data/jobsdata";
import { commonData } from "../../data/commondata";

const SortData = [
  {
    id: "latest",
    name: "Newest First",
  },
  {
    id: "atoz",
    name: "A to Z",
  },
  {
    id: "percentage",
    name: "Match % - Higher To Lower",
  },
  {
    id: "favorite",
    name: "Favorites First",
  },
];
const capitalizeWords = (str) => {
  return str?.replace(/\b\w/g, (char) => char?.toUpperCase());
};
function ProcessedCandidateByJob() {
  const [toggleView, setToggleView] = useState(false);
  const [detailsView, setDetailsView] = useState(false);
  const [searchText, setSearchText] = useState("");
  const location = useLocation();
  const { GetCustomerById } = useCandidateData();
  const params = location.state;
  const { retrieveToken } = storage();
  const [data, setData] = useState({});
  const { GetProcessedCandidateByJob, MarkAnalysisFavorite } =
    useCandidateData();
  const { GetDownloadUrl } = commonData();
  const navigate = useNavigate();
  const activetab = "";
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("820"));
  const token = retrieveToken();
  const [isSecondOpen, setSecondIsOpen] = useState(false);
  const [filterdata, setFilterdata] = useState("Sort By");
  const [customer, setcustomer] = useState(null);
  const [selectedFilterdata, setSelectedFilterdata] = useState("");

  const searchTextChange = (event) => {
    setSearchText(event.target.value);
  };
  const [notificationActive, setNotificationActive] = useState(false);
  const [noDataAvailable, setNoDataAvailable] = useState(false);

  const handleClick = () => {
    setNotificationActive(!notificationActive);
  };
  const { GetAtsUrlByAtsJobcode } = useJobsData();

  const toggleSecondDropdown = () => {
    setSecondIsOpen((prev) => !prev);
  };

  const handleSecondOptionClick = (Secondoption) => {
    setSelectedFilterdata(Secondoption.id);
    setFilterdata(Secondoption.name);
    toggleSecondDropdown();
  };
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const downloadResume = (url) => {
    url &&
      GetDownloadUrl({
        url: url,
      })
        .then((response) => {
          window.open(response?.data?.url, "_blank");
        })
        .catch((error) => {
          console.error(error);
        });
  };

  useEffect(() => {
    if (token) {
      GetCustomerById()
        .then((response) => {
          setcustomer(response.data[0]);
        })
        .catch((error) => {
          console.error(error);
        });
      GetProcessedCandidateByJob({
        jobid: params?.jobid,
        search: searchText,
        sortby: selectedFilterdata || "",
      })
        .then((response) => {
          if (response?.data == (undefined || null)) {
            setData([]);
            setNoDataAvailable(true);
          } else {
            setData(
              response?.data?.map((x) => ({
                ...x,
                candidatesdata: JSON.parse(x?.candidatesdata),
              }))
            );
            setNoDataAvailable(false);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      window.location.replace(process.env.REACT_APP_API_LOGIN_URL);
    }
  }, []);

  useEffect(() => {
    selectedFilterdata &&
      GetProcessedCandidateByJob({
        jobid: params?.jobid,
        search: searchText,
        sortby: selectedFilterdata || "",
      })
        .then((response) => {
          if (response?.data == (undefined || null)) {
            setData([]);
            setNoDataAvailable(true);
          } else {
            setData(
              response?.data?.map((x) => ({
                ...x,
                candidatesdata: JSON.parse(x?.candidatesdata),
              }))
            );
            setNoDataAvailable(false);
          }
        })
        .catch((error) => {
          console.error(error);
        });
  }, [selectedFilterdata]);

  const btnMarkFavorite = (cand) => {
    MarkAnalysisFavorite({
      analysisid: cand?.analysisid,
      isfavorite: !cand?.isfavorite,
    })
      .then((response) => {
        const itemIndex = data[0]?.candidatesdata.findIndex(
          (item) => item.analysisid === cand?.analysisid
        );

        if (itemIndex !== -1) {
          const updatedCandidatesData = [...data[0]?.candidatesdata];
          updatedCandidatesData[itemIndex] = {
            ...updatedCandidatesData[itemIndex],
            isfavorite: !cand?.isfavorite,
          };

          // Sort the updatedCandidatesData based on isfavorite flag (favorites first)
          // updatedCandidatesData.sort((a, b) => {
          //   if (a.isfavorite && !b.isfavorite) return -1;
          //   if (!a.isfavorite && b.isfavorite) return 1;
          //   return 0;
          // });
          setData([{ ...data[0], candidatesdata: updatedCandidatesData }]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const buttonHandle = () => {
    setToggleView(false);
    setDetailsView(!detailsView);
  };
  const buttonHandle2 = () => {
    setToggleView(!toggleView);
    setDetailsView(false);
  };

  const clearSearch = () => {
    setSearchText("");
    GetProcessedCandidateByJob({
      jobid: params?.jobid,
      search: "",
      sortby: selectedFilterdata || "",
    })
      .then((response) => {
        if (response?.data == (undefined || null)) {
          setData([]);
          setNoDataAvailable(true);
        } else {
          setData(
            response?.data?.map((x) => ({
              ...x,
              candidatesdata: JSON.parse(x?.candidatesdata),
            }))
          );
          setNoDataAvailable(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const btnSearch = () => {
    GetProcessedCandidateByJob({
      jobid: params?.jobid,
      search: searchText,
      sortby: selectedFilterdata || "",
    })
      .then((response) => {
        if (response?.data == (undefined || null)) {
          setData([]);
          setNoDataAvailable(true);
        } else {
          setData(
            response?.data?.map((x) => ({
              ...x,
              candidatesdata: JSON.parse(x.candidatesdata),
            }))
          );
          setNoDataAvailable(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const redirectToAts = (params) => {
    const { atsjobcode, atsid } = params;
    if (atsid) {
      GetAtsUrlByAtsJobcode({
        atsjobcode: atsjobcode,
      })
        .then((response) => {
          window.open(response?.data[0]?.atsurl, "_blank");
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
    }
  };
  const secondDropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        secondDropdownRef.current &&
        !secondDropdownRef.current.contains(event.target)
      ) {
        setSecondIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // For Date and time function
  function formatDateTime(dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    });

    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    return `${formattedDate} ${formattedTime}`;
  }

  return (
    <>
      {isMobile ? (
        <>
          <Helmet>
            <style>{"body { background-color: #FFFFFF;}"}</style>
            <title>Processed Job</title>
            {/* <meta name="description" content="App Description" /> */}
            {/* <meta name="theme-color" content="#008f68" /> */}
          </Helmet>
          <React.Fragment>
            <div
              style={{
                position: "sticky",
                top: "0",
                background: "white",
                paddingBottom: "7px",
              }}
            >
              <div class="stopper">
                <div class="stopper-child">
                  <img
                    onClick={() => window.history.back()}
                    src="/images/Back.svg"
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <div class="stopper-child">
                  <p style={{ color: "#21D6AA", fontWeight: "500" }}>
                    NOTIFY ME &nbsp;&nbsp;
                    <label class="switch">
                      <input type="checkbox" />
                      <span class="slider round"></span>
                    </label>
                  </p>
                </div>
              </div>

              <div
                className="search-container"
                style={{ margin: "18px 17px 8px 17px" }}
              >
                <input
                  type="text"
                  id="search"
                  placeholder="Search Candidate"
                  style={{
                    border: "none",
                    outline: "none",
                    height: "40px",
                    paddingLeft: "10px",
                    width: "200px",
                    flex: 1,
                  }}
                  value={searchText}
                  onChange={searchTextChange}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") btnSearch();
                  }}
                />

                {searchText && (
                  <button
                    style={{
                      position: "absolute",
                      right: "60px",
                      border: "none",
                      outline: "none",
                      background: "transparent",
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={clearSearch}
                  >
                    <img
                      src="/images/greenCross1.svg"
                      style={{ width: "10px", height: "10px" }}
                      alt="Clear"
                    />
                  </button>
                )}

                <button
                  style={{
                    border: "none",
                    outline: "none",
                    height: "40px",
                    paddingTop: "9px",
                  }}
                  type="submit"
                  onClick={() => btnSearch()}
                >
                  <img
                    style={{ background: "inherit" }}
                    src="/images/Search Icon.svg"
                  />
                </button>
              </div>

              <div
                className="dropdown_sort_by"
                style={{ padding: "15px 15px 5px 15px" }}
              >
                <button
                  onClick={toggleSecondDropdown}
                  className={`dropdown_sort ${
                    isSecondOpen ? "secondopen" : ""
                  }`}
                >
                  {filterdata || "Sort By"}
                  {!isSecondOpen ? (
                    <img src="images/custom-arrow.svg" />
                  ) : (
                    <img
                      style={{ transform: "rotate(180deg)" }}
                      src="images/custom-arrow.svg"
                    />
                  )}
                </button>

                {isSecondOpen && (
                  <div className="dropdown_sort_by_list_mobile">
                    <div>
                      {SortData &&
                        SortData.map((data, i) => (
                          <p
                            key={i}
                            onClick={() => handleSecondOptionClick(data)}
                          >
                            {data?.name}
                          </p>
                        ))}
                    </div>
                  </div>
                )}
              </div>

              <div class="jobid-more-mobile">
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    redirectToAts({
                      atsjobcode: data[0]?.atsjobcode,
                      atsid: data[0]?.atsid,
                    })
                  }
                >
                  <Tooltip
                    title={
                      data[0]?.atsjobcode.length > 20 ? data[0]?.atsjobcode : ""
                    }
                  >
                    JOB ID:{" "}
                    {data[0]?.atsjobcode.length > 20
                      ? `${data[0]?.atsjobcode.substring(0, 20)}...`
                      : data[0]?.atsjobcode}
                  </Tooltip>
                </p>
                <p style={{ fontWeight: "600" }}>{data[0]?.jobtitle}</p>
                <p style={{ fontSize: "10px" }}>
                  {data[0]?.opendays > 0
                    ? data[0]?.opendays > 1
                      ? "Opened " + data[0]?.opendays + " Days Ago"
                      : "Opened " + data[0]?.opendays + " Day Ago"
                    : "Opened Today"}
                </p>
              </div>
            </div>

            <div class="complete-list">
              <div class="full-list">
                {/* <div style={{ display: 'flex', flexWrap: 'wrap' }}> */}
                {data &&
                  data[0]?.candidatesdata?.map((candidate, i) => (
                    <div class="details-listing" key={candidate?.analysisid}>
                      <div
                        class="under-listing"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={(e) =>
                          navigate("/candidateresult", {
                            state: candidate?.analysisid,
                          })
                        }
                      >
                        <p>{candidate?.candidatename}</p>
                      </div>
                      <div
                        class="under-listing"
                        style={{ textAlign: "center" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <p
                            style={{
                              color: "#708EA4",
                              fontSize: "14px",
                              fontWeight: "400",
                              cursor: "pointer",
                            }}
                            onClick={(e) =>
                              navigate("/candidateresult", {
                                state: candidate?.analysisid,
                              })
                            }
                          >
                            {candidate?.match_percentage}%
                          </p>
                          <img
                            style={{ width: "20px", cursor: "pointer" }}
                            src="/images/arrow.svg"
                            onClick={(e) =>
                              navigate("/candidateresult", {
                                state: candidate?.analysisid,
                              })
                            }
                          />
                          <MarkFavorite
                            onClick={(e) => btnMarkFavorite(candidate)}
                            imgUrl={
                              candidate?.isfavorite
                                ? "/images/filled-bookmark.svg"
                                : "/images/bookmark.svg"
                            }
                            style={{
                              width: "70%",
                              marginLeft: "3px",
                              marginTop: "4px",
                              cursor: "pointer",
                            }}
                          />
                          {/* <ImageToggle style={{width:'70%',marginLeft:'3px',marginTop:'4px'}} initialSrc="/images/bookmark.svg"/> */}
                        </div>
                      </div>
                    </div>
                  ))}
                {/* </div> */}
              </div>
            </div>
          </React.Fragment>
        </>
      ) : (
        <>
          <Helmet>
            <style>{"body { background-color: #FFFFFF;}"}</style>
            <title>Processed Job</title>
            {/* <meta name="description" content="App Description" /> */}
            {/* <meta name="theme-color" content="#008f68" /> */}
          </Helmet>
          <Box sx={{ display: "flex", height: "100vh", overflow: "hidden" }}>
            <Navbar data={activetab} />
            <Box component="main" sx={{ flexGrow: 1, p: 3, width: "80%" }}>
              <div class="analyst" className="flex ">
                <div className="w-[28%] ms-5">
                  <button onClick={() => navigate(-1)} className="p-match">
                    <img
                      style={{ width: "16px" }}
                      src="/images/arrow-left.svg"
                    />
                    Back
                  </button>
                </div>
              </div>
              <div className="w-full flex flex-wrap justify-between items-center ">
                <div class="analyst-child">
                  <div class="notify-parent">
                    <div class="notify-child">
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          redirectToAts({
                            atsjobcode: data[0]?.atsjobcode,
                            atsid: data[0]?.atsid,
                          })
                        }
                      >
                        JOB ID: {data[0]?.atsjobcode}
                      </p>
                    </div>
                    <Tooltip
                      title={
                        notificationActive
                          ? ""
                          : "Notify me when new candidates arrive"
                      }
                    >
                      <div
                        class="notify-child"
                        className="flex mt-2.5"
                        onClick={handleClick}
                      >
                        <img
                          src={
                            notificationActive
                              ? "/images/Notify Me Icondark.svg"
                              : "/images/Notify Me Icon.svg"
                          }
                          className="w-6 h-5 cursor-pointer"
                        />
                        <p className="ml-2 cursor-pointer">
                          {notificationActive
                            ? "NOTIFICATION ACTIVE "
                            : "NOTIFY ME"}
                        </p>
                      </div>
                    </Tooltip>
                  </div>
                </div>
                <div class="button-parent">
                  <div class="button-child">
                    <p>
                      {data[0]?.opendays > 0
                        ? data[0]?.opendays > 1
                          ? "Opened " + data[0]?.opendays + " Days Ago"
                          : "Opened " + data[0]?.opendays + " Day Ago"
                        : "Opened Today"}
                    </p>
                  </div>

                  {/* <div
                    class="analist-cjild"
                    onClick={(e) => btnMarkFavorite(data[0])}
                  >
                    <MarkFavorite
                      imgUrl={
                        data[0]?.isfavorite
                          ? "/images/filled-bookmark.svg"
                          : "/images/bookmark.svg"
                      }
                      style={{
                        width: "60%",
                        marginRight: "5px",
                        marginTop: "2px",
                        cursor: "pointer",
                        marginTop: "5px",
                      }}
                    />
                    <p style={{ cursor: "pointer", marginTop: "5px" }}>
                      {data[0]?.isfavorite
                        ? "ADDED TO FAVORITES"
                        : "ADD TO FAVORITES"}
                    </p>
                  </div> */}

                  {/* <div class="analist-cjild">
                                                <MarkFavorite imgUrl="/images/bookmark.svg" style={{ width: '60%', marginRight: '5px', marginTop: '2px' }} />
                                                <ImageToggle style={{ width: '60%', marginRight: '5px', marginTop: '2px' }} initialSrc="/images/bookmark.svg" />
                                                <p>ADD TO FAVORITES</p>
                                            </div> */}
                </div>
              </div>
              <div className="analyst-datails  ">
                <div class="analyst-cjild">
                  <p>{data[0]?.jobtitle}</p>
                </div>
                <div class=""></div>
              </div>
              {/* <div class="analyst-chhild">
                                <p>Job Title</p>
                            </div> */}

              <div className="flex flex-row justify-between">
                <div
                  className="search-container"
                  style={{
                    marginLeft: "18px",
                    marginTop: "10px",
                    marginBottom: "22px",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    width: "500px",
                  }}
                >
                  <input
                    type="text"
                    id="search"
                    placeholder="Search Candidate"
                    style={{
                      border: "none",
                      outline: "none",
                      height: "40px",
                      paddingLeft: "10px",
                      width: "200px",
                      flex: 1,
                    }}
                    value={searchText}
                    onChange={searchTextChange}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") btnSearch();
                    }}
                  />

                  {searchText && (
                    <button
                      style={{
                        position: "absolute",
                        right: "40px",
                        border: "none",
                        outline: "none",
                        background: "transparent",
                        height: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={clearSearch}
                    >
                      <img
                        src="/images/greenCross1.svg"
                        style={{ width: "10px", height: "10px" }}
                        alt="Clear"
                      />
                    </button>
                  )}

                  <button
                    style={{
                      border: "none",
                      outline: "none",
                      height: "40px",
                      paddingTop: "9px",
                    }}
                    type="submit"
                    onClick={() => btnSearch()}
                  >
                    <img
                      style={{ background: "inherit" }}
                      src="/images/Search Icon.svg"
                    />
                  </button>
                </div>
                <div className="flex flex-row justify-between mt-3">
                  <div
                    className="dropdown_sort_by"
                    style={{ width: "250px", zIndex: "99" }}
                    ref={secondDropdownRef}
                  >
                    <button
                      onClick={toggleSecondDropdown}
                      className={`dropdown_sort ${
                        isSecondOpen ? "secondopen" : ""
                      }`}
                    >
                      {filterdata || "Sort By"}
                      {!isSecondOpen ? (
                        <img src="images/custom-arrow.svg" />
                      ) : (
                        <img
                          style={{ transform: "rotate(180deg)" }}
                          src="images/custom-arrow.svg"
                        />
                      )}
                    </button>

                    {isSecondOpen && (
                      <div className="dropdown_sort_by_list">
                        <div>
                          {SortData &&
                            SortData.map((data, i) => (
                              <p
                                key={i}
                                onClick={() => handleSecondOptionClick(data)}
                              >
                                {data?.name}
                              </p>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    <div
                      class="button-container justify-center w-34 h-10  flex gap-2"
                      style={{ boxShadow: "0px 0px 20px #5B93ED33" }}
                    >
                     {customer?.isdetailview === true && (
                      <Tooltip title={"Detailed View"}>
                        <button
                          className="w-[42px] flex items-center justify-center"
                          style={{
                            cursor: "pointer",
                            backgroundColor:
                              detailsView === true ? "#21D6AA" : "white",
                          }}
                          onClick={() => buttonHandle()}
                        >
                          {detailsView ? (
                            <img
                              className="w-12 "
                              src="/images/viewdetails2.svg"
                              alt="Button 1"
                            />
                          ) : (
                            <img
                              className="w-12"
                              src="/images/viewdetails.svg"
                              alt="Button 1"
                            />
                          )}
                        </button>
                      </Tooltip>
                      )}
                      <Tooltip title={" List View"}>
                        <button
                          className="w-[42px] flex items-center justify-center"
                          onClick={() => buttonHandle2()}
                          style={{
                            cursor: "pointer",
                            backgroundColor: toggleView ? "#21D6AA" : "white",
                          }}
                        >
                          {toggleView ? (
                            <img
                              className="w-5 "
                              src="/images/3-dots.svg"
                              alt="Button 1"
                            />
                          ) : (
                            <img
                              className="w-5 "
                              src="/images/green-list.svg"
                              alt="Button 1"
                            />
                          )}
                        </button>
                      </Tooltip>
                      <Tooltip title={" Tile View"}>
                        <button
                          className="w-[42px] flex items-center justify-center"
                          onClick={() => buttonHandle2()}
                          style={{
                            cursor: "pointer",
                            backgroundColor:
                              !toggleView && !detailsView ? "#21D6AA" : "white",
                          }}
                        >
                          {!toggleView && !detailsView ? (
                            <img
                              className="w-5 "
                              src="/images/white-grid.svg"
                              alt="Button 2"
                            />
                          ) : (
                            <img
                              className="w-5 "
                              src="/images/4-box.svg"
                              alt="Button 2"
                            />
                          )}
                        </button>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>

              {/*listing */}
              {detailsView ? (
                noDataAvailable ? (
                  <div className="flex justify-center w-full h-[70vh] flex-col gap-2 items-center">
                    <div className=" rounded-full border-gray-500  border-2 flex w-12 h-12 justify-center items-center">
                      <p className="text-[30px] text-gray-500 font-bold">!</p>
                    </div>
                    <p className="text-[20px] text-gray-500 font-bold">
                      Data not available
                    </p>
                  </div>
                ) : (
                  <>
                    <div
                      className="2xl:min-w-[100%] w-full overflow-x-auto overflow-y-hidden"
                      style={{
                        scrollbarWidth: "thin",
                        scrollbarColor: "auto transparent",
                      }}
                    >
                      <div
                        className="mx-[15px]  w-[2000px] shadow-md   "
                        style={{ boxShadow: "0px 0px 20px #5B93ED33" }}
                      >
                        {/* <div className="sticky z-10 top-0 bg-white border-b border-[#E0E0E0] w-auto  ">
                          <table className="w-full  overflow-auto divide-y divide-gray-200 ">
                            <thead className=" w-full ">
                              <tr className=" w-full">
                                <th className="2xl:w-[2.5%] lg:w-[2.5%] 2xl:text-[16px] lg:text-[13px]  xl:text-[15px] px-2  py-5 text-left font-medium text-blue text-[18px] uppercase tracking-wider"></th>
                                <th className="  xl:w-[7%] 2xl:w-[5.6%]  lg:w-[8%] 2xl:text-[16px] lg:text-[13px]  xl:text-[15px] px-2  py-5 text-left font-medium text-blue text-[18px] uppercase tracking-wider">
                                  Candidate
                                </th>
                                <th className=" xl:w-[5.2%] lg:w-[5.3%] 2xl:w-[4.2%]  2xl:text-[16px] lg:text-[13px]  xl:text-[15px] px-2  py-5 text-left font-medium text-blue text-[18px] uppercase tracking-wider">
                                  MATCH %
                                </th>
                                <th className="xl:w-[5.2%] 2xl:w-[4.4%] lg:w-[5.5%]  2xl:text-[16px] lg:text-[13px]  xl:text-[15px] px-2  py-5 text-left font-medium text-blue text-[18px] uppercase tracking-wider">
                                  RESULT
                                </th>
                                {customer?.show_deni && (
                                  <th className=" 2xl:w-[6.2%] xl:w-[6.5%] lg:w-[7%] 2xl:text-[16px] lg:text-[13px]  xl:text-[15px] px-4 py-5 text-left font-medium text-blue text-[18px] uppercase tracking-wider">
                                    DE&I
                                  </th>
                                )}
                                <th className="2xl:w-[6.2%] xl:w-[7%] lg:w-[7.5%] 2xl:text-[16px] lg:text-[13px]  xl:text-[15px] px-2  py-5 text-left font-medium text-blue text-[18px] uppercase tracking-wider">
                                  NATIONAL  
                                </th>
                               

                                <th className="2xl:w-[5%]  xl:w-[5.4%] lg:w-[5.3%] 2xl:text-[16px] lg:text-[13px]  xl:text-[15px] px-2  py-5 text-left font-medium text-blue text-[18px] uppercase tracking-wider">
                                  GENDER
                                </th>

                                <th className="2xl:w-[5%] xl:w-[6.2%] lg:w-[5.8%] 2xl:text-[16px] lg:text-[13px]  xl:text-[15px] px-2  py-5 text-left font-medium text-blue text-[18px] uppercase tracking-wider">
                                  TOTAL EXP
                                </th>
                                
                                <th className="2xl:w-[6.5%] xl:w-[6.6%] lg:w-[7.2%] 2xl:text-[16px] lg:text-[13px]  xl:text-[15px] px-2  py-5 text-left font-medium text-blue text-[18px] uppercase tracking-wider">
                                <span className="flex w-full justify-center pr-2">   OVER QUALI.  </span>
                                </th>
                                
                                <th className=" 2xl:w-[6.5%] xl:w-[6.6%] lg:w-[7.1%] 2xl:text-[16px] lg:text-[13px]  xl:text-[15px] px-2  py-5 text-left font-medium text-blue text-[18px] uppercase tracking-wider">
                                <span className="flex w-full justify-center pr-2">  OVER EXP. </span>
                                </th>
                                <th className=" 2xl:w-[7.7%] xl:w-[8%] lg:w-[9.3%] 2xl:text-[16px] lg:text-[13px]  xl:text-[15px]   py-5 text-left font-medium text-blue text-[18px] uppercase tracking-wider">
                                  CONTACT
                                </th>
                                <th className=" 2xl:w-[9.2%] xl:w-[10%] lg:w-[10.8%] 2xl:text-[16px] lg:text-[13px]  xl:text-[15px]     py-5 text-left font-medium text-blue text-[18px] uppercase tracking-wider">
                                  EMAIL
                                </th>
                                <th className="2xl:w-[10%] xl:w-[10%] lg:w-[10.7%] 2xl:text-[16px] lg:text-[13px]  xl:text-[15px]     py-5 text- font-medium text-blue text-[18px] uppercase tracking-wider">
                                  LINKEDIN PROF.
                                </th>
                                <th className="xl:w-[7.5%] lg:w-[8%] 2xl:w-[8%] 2xl:text-[16px] lg:text-[13px]  xl:text-[15px]     py-5 text-left font-medium text-blue text-[18px] uppercase tracking-wider">
                                  SPEAKING LANG.
                                </th>
                                
                              </tr>
                            </thead>
                          </table>
                        </div> */}
                                           <div className="sticky z-10 top-0 bg-white border-b border-[#E0E0E0]">
    <table className="w-full overflow-auto divide-y divide-gray-200">
      <thead className="w-full">
        <tr className="w-full">
          <th className="w-[40px] px-2 py-4 text-left font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase"></th>
          <th className="w-[120px] lg:w-[125px] px-2 py-4 text-left font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
            Candidate
          </th>
          <th className="w-[80px] px-2 py-4 text-center font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
            MATCH %
          </th>
          <th className="w-[84px] px-3 py-4 text-start font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
            RESULT
          </th>
          {customer?.show_deni && (
            <th className="w-[112px] px-2 py-4 text-left font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
              DE&I
            </th>
          )}
          <th className="w-[110px] px-2 py-4 text-left font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
            NATIONAL
          </th>
          <th className="w-[80px] px-2 py-4 text-left font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
            GENDER
          </th>
          <th className="w-[80px] px-2 py-4 text-center font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
            TOTAL EXP
          </th>
          <th className="w-[100px] px-2 py-4 text-center font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
            OVER QUALI.
          </th>
          <th className="w-[100px] px-2 py-4 text-center font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
            OVER EXP.
          </th>
          <th className="w-[132px] px-2 py-4 text-left font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
            CONTACT
          </th>
          <th className="w-[170px] px-2 py-4 text-left font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
            EMAIL
          </th>
          <th className="w-[180px] px-2 py-4 text-left font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
            LINKEDIN PROF.
          </th>
          <th className="w-[120px] px-2 py-4 text-center font-medium text-blue   2xl:text-[16px] lg:text-[13px]  xl:text-[15px] uppercase">
            SPEAKS ARABIC
          </th>
        </tr>
      </thead>
    </table>
  </div>
                      </div>

                      <div
                        className={`mx-[15px]  w-[2000px] ${
                          noDataAvailable
                            ? "max-h-[calc(100vh-250px)]"
                            : "max-h-[calc(100vh-305px)]"
                        }  overflow-auto shadow-md `}
                        style={{ scrollbarWidth: "none" }}
                      >
                        <div className="w-auto">
                          <table className="w-full divide-y divide-gray-200">
                            <tbody className="bg-white divide-y divide-gray-200">
                              {data &&
                                data[0]?.candidatesdata?.map((candidate, i) => (
                                  <tr
                                    key={i}
                                    className={`${
                                      i % 2 === 0
                                        ? "bg-[#EDF4FF] border-none"
                                        : "bg-white border-none"
                                    }`}
                                  >
                                    <td className=" w-[40px] text-center pl-3 py-4  text-sm text-gray-700 font-medium text-[15px]">
                                      <Tooltip
                                        title={
                                          candidate?.isfavorite
                                            ? "Favorite"
                                            : "Mark as Favorite"
                                        }
                                      >
                                        <div>
                                          <MarkFavorite
                                            onClick={(e) =>
                                              btnMarkFavorite(candidate)
                                            }
                                            imgUrl={
                                              candidate?.isfavorite
                                                ? "/images/filled-bookmark.svg"
                                                : "/images/bookmark.svg"
                                            }
                                            style={{ cursor: "pointer" }}
                                          />
                                        </div>
                                      </Tooltip>
                                    </td>
                                    <td className=" w-[120px]  text-left px-2 py-4  text-sm text-gray-700 font-medium text-[15px]">
                                      <Tooltip
                                        title={
                                          candidate?.candidatename?.length > 13
                                            ? candidate.candidatename
                                            : ""
                                        }
                                      >
                                        {candidate?.candidatename.length > 13
                                          ? `${candidate?.candidatename.substring(
                                              0,
                                              13
                                            )}...`
                                          : candidate?.candidatename}
                                      </Tooltip>
                                    </td>
                                    <td className=" w-[90px]  px-2 py-4 text-center text-sm text-gray-700 font-medium text-[15px]">
                                    <span className="flex w-full items-center justify-center"> {candidate?.match_percentage}% </span>
                                    </td>
                                    <td
                                      className="  w-[80px]  px-2 py-4 text-start text-sm text-gray-700 font-medium text-[15px]"
                                      onClick={(e) =>
                                        navigate("/candidateresult", {
                                          state: candidate?.analysisid,
                                        })
                                      }
                                    >
                                      <div className="flex w-full justify-start items-start flex-row gap-1 cursor-pointer">
                                        <p>VIEW</p>
                                        <img src="/images/arrow.svg" />
                                      </div>
                                    </td>
                                    {customer?.show_deni && (
                                      <td className=" w-[110px] text-left px-2 py-4  text-sm text-gray-700 font-medium text-[15px]">
                                        <Tooltip
                                          title={
                                            candidate?.ethnicity?.length > 14
                                              ? capitalizeWords(
                                                  candidate?.ethnicity
                                                )
                                              : ""
                                          }
                                        >
                                          {candidate?.ethnicity?.length > 14
                                            ? `${capitalizeWords(
                                                candidate?.ethnicity.substring(
                                                  0,
                                                  14
                                                )
                                              )}...`
                                            : capitalizeWords(
                                                candidate?.ethnicity
                                              )}
                                        </Tooltip>
                                      </td>
                                    )}

                                    <td
                                      className={` w-[113px] text-left px-2 py-4  font-medium text-[15px] text-gray-700`}
                                    >
                                      {customer?.ismiddleeast ? (
                                        <Tooltip
                                          title={
                                            candidate?.nationality?.length > 15
                                              ? candidate?.nationality
                                              : ""
                                          }
                                        >
                                          {" "}
                                          {candidate?.nationality?.length > 15
                                            ? `${candidate?.nationality.substring(
                                                0,
                                                15
                                              )}...`
                                            : candidate?.nationality}
                                        </Tooltip>
                                      ) : (
                                        <text className="flex justify-center w-[110px] items-center  font-bold">
                                          {" "}
                                          -{" "}
                                        </text>
                                      )}
                                    </td>

                                 

                                    <td className="w-[80px] text-left   cursor-paste px-2 py-4  text-sm text-gray-700 font-medium text-[15px]">
                                      {candidate?.gender === "M"
                                        ? "Male"
                                        : "Female"}
                                    </td>

                                    <td className=" w-[80px] text-center   cursor-paste px-2 py-4  text-sm text-gray-700 font-medium text-[15px]">
                                      {candidate?.totalexp === 0
                                        ?  <text className="flex justify-center w-full items-center  font-bold">
                                        {" "}
                                        -{" "}
                                      </text>
                                        : candidate?.totalexp + "y"}
                                    </td>

                                    {/* { customer?.ismiddleeast && ( */}
                                    <td
                                      className={` w-[100px]  items-center cursor-paste px-2 py-4  text-sm text-gray-700 font-medium text-[15px]`}
                                    >
                                      <span className="flex w-full justify-center">
                                        {" "}
                                        {candidate?.isoverqualified === true
                                          ? "Yes"
                                          : "No"}
                                      </span>
                                    </td>
                                    {/* )} */}

                                    <td className=" w-[100px] items-center cursor-paste px-2 py-4  text-sm text-gray-700 font-medium text-[15px]">
                                      <span className="flex w-full justify-center">
                                        {" "}
                                        {candidate?.isoverexperienced === true
                                          ? "Yes"
                                          : "No"}{" "}
                                      </span>
                                    </td>
                                    <td className="w-[110px] text-left cursor-paste px-2  py-4  text-sm text-gray-700 font-medium text-[15px]">
                                      <Tooltip
                                        title={
                                          candidate?.phone?.length > 15
                                            ? candidate?.phone
                                            : ""
                                        }
                                      >
                                        {candidate?.phone?.length > 15
                                          ? `${candidate?.phone.substring(
                                              0,
                                              15
                                            )}...`
                                          : candidate?.phone ? candidate?.phone  : <text className="flex justify-center w-[115px] items-center  font-bold">
                                          {" "}
                                          {" "}
                                        </text>}
                                      </Tooltip>
                                    </td>
                                    <td className="w-[167px] text-left px-2 cursor-paste py-4  text-sm text-gray-700 font-medium text-[15px]">
                                      <Tooltip
                                        title={
                                          candidate?.email?.length > 18
                                            ? candidate?.email
                                            : ""
                                        }
                                      >
                                        {candidate?.email?.length > 18
                                          ? `${candidate?.email.substring(
                                              0,
                                              18
                                            )}...`
                                          : candidate?.email}
                                      </Tooltip>
                                    </td>
                                    <td className="w-[182px] text-left items-center cursor-paste px-2 py-4 text-sm text-gray-700 font-medium text-[15px]">
                                      <div className="flex w-full justify-start">
                                        {candidate?.linkedinurl ? (
                                          <Tooltip
                                            title={
                                              candidate?.linkedinurl?.length >
                                              20
                                                ? candidate?.linkedinurl
                                                : ""
                                            }
                                          >
                                          <a
                                            href={
                                                    candidate?.linkedinurl?.startsWith("http")
                                                    ? candidate.linkedinurl
                                                    : `https://${candidate?.linkedinurl}`
                                                  }
                                                   target="_blank"
                                                   rel="noopener noreferrer"
                                                   className="text-blue"
                                          >
                                              {candidate.linkedinurl?.length >
                                              20
                                                ? `${candidate?.linkedinurl?.substring(
                                                    0,
                                                    20
                                                  )}...`
                                                : candidate?.linkedinurl}
                                            </a>
                                          </Tooltip>
                                        ) : (
                                          <text className="flex w-full items-center  2xl:pr-28 xl:pr-24 lg:pr-20  pr-30 justify-center">
                                            {" "}
                                            -{" "}
                                          </text>
                                        )}
                                      </div>
                                    </td>
                                    <td className="w-[120px] text-center items-center cursor-paste px-2 py-4  text-sm text-gray-700 font-medium text-[15px]">
                                      <span className="flex w-full items-center justify-center">
                                        {candidate?.isarabicspeaking === true
                                          ? "Yes"
                                          : "No"}
                                      </span>
                                    </td>

                                    {/* <td className="w-[3.8%] lg:w-[4.2%] xl:w-[3.4%]  px-2 py-4 text-[15px] font-medium text-gray-700 cursor-pointer">
                                      {candidate?.age === 0
                                        ? "-"
                                        : candidate?.age}
                                    </td> */}

                                    {/* <td className={`w-[80px] px-2 py-2 text-[15px] font-medium ${candidate?.profileurl
                                      ? "text-[#21D6AA]" : "text-gray-400"} `}>
                              <Tooltip title="ATS Profile">
                                <div
                                  className={`flex justify-center gap-1 ${
                                    candidate?.profileurl
                                      ? "cursor-pointer"
                                      : "cursor-not-allowed"
                                  }`}
                                  onClick={() =>
                                    candidate?.profileurl &&
                                    window.open(
                                      candidate?.profileurl,
                                      "_blank"
                                    )
                                  }
                                >
                                  <p>ATS</p>
                                  {candidate?.profileurl ?
                                  <img src="/images/profile-icon.svg" /> :  <img  src="/images/profile-icon2.svg" />}
                                </div>
                              </Tooltip>
                            </td> */}
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </>
                )
              ) : !toggleView ? (
                <>
                  {noDataAvailable ? (
                    <div className="flex justify-center w-full h-[70vh] flex-col gap-2 items-center">
                      <div className=" rounded-full border-gray-500  border-2 flex w-12 h-12 justify-center items-center">
                        <p className="text-[30px] text-gray-500 font-bold">!</p>
                      </div>
                      <p className="text-[20px] text-gray-500 font-bold">
                        Data not available
                      </p>
                    </div>
                  ) : (
                    <div
                      className="mx-[15px] max-h-[calc(100vh-240px)] overflow-auto "
                      style={{ scrollbarWidth: "none" }}
                    >
                      <div class="full-list">
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {data &&
                            data[0]?.candidatesdata?.map((candidate, i) => (
                              <div
                                class="details-listing"
                                key={candidate?.analysisid}
                                style={{
                                  width: "32%",
                                  marginBottom: "10px",
                                  marginRight: "15px",
                                }}
                              >
                                <div
                                  class="under-listing "
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) =>
                                    navigate("/candidateresult", {
                                      state: candidate?.analysisid,
                                    })
                                  }
                                >
                                  <p>{candidate?.candidatename}</p>
                                </div>
                                <div
                                  class="under-listing"
                                  style={{ textAlign: "center" }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <p
                                      style={{
                                        color: "#708EA4",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "paste",
                                      }}
                                      onClick={(e) =>
                                        navigate("/candidateresult", {
                                          state: candidate?.analysisid,
                                        })
                                      }
                                    >
                                      {candidate?.match_percentage}%
                                    </p>
                                    <img
                                      style={{
                                        width: "20px",
                                        cursor: "pointer",
                                      }}
                                      src="/images/arrow.svg"
                                      onClick={(e) =>
                                        navigate("/candidateresult", {
                                          state: candidate?.analysisid,
                                        })
                                      }
                                    />
                                    {/* <MarkFavorite
                                      onClick={(e) =>
                                        btnMarkFavorite(candidate)
                                      }
                                      imgUrl={
                                        candidate?.isfavorite
                                          ? "/images/filled-bookmark.svg"
                                          : "/images/bookmark.svg"
                                      }
                                      style={{
                                        width: "70%",
                                        marginLeft: "3px",
                                        marginTop: "4px",
                                        cursor: "pointer",
                                      }}
                                    /> */}
                                    <Tooltip
                                      title={
                                        candidate?.isfavorite
                                          ? "Favorite"
                                          : "Mark as Favorite"
                                      }
                                    >
                                      <div>
                                        <MarkFavorite
                                          onClick={(e) =>
                                            btnMarkFavorite(candidate)
                                          }
                                          imgUrl={
                                            candidate?.isfavorite
                                              ? "/images/filled-bookmark.svg"
                                              : "/images/bookmark.svg"
                                          }
                                          style={{
                                            width: "70%",
                                            marginLeft: "3px",
                                            marginTop: "4px",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </div>
                                    </Tooltip>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {noDataAvailable ? (
                    <div className="flex justify-center w-full h-[70vh] flex-col gap-2 items-center">
                      <div className=" rounded-full border-gray-500  border-2 flex w-12 h-12 justify-center items-center">
                        <p className="text-[30px] text-gray-500 font-bold">!</p>
                      </div>
                      <p className="text-[20px] text-gray-500 font-bold">
                        Data not available
                      </p>
                    </div>
                  ) : (
                    <div
                      className="mx-[15px] shadow-md "
                      style={{ boxShadow: "0px 0px 20px #5B93ED33" }}
                    >
                      <div className="sticky z-10 top-0 bg-white border-b border-[#E0E0E0]">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th className="w-[65px] xl:w-[45px] lg:w-[45px]"></th>
                              <th className=" max-w-[7px] 2xl:max-w-[6.5px] lg:max-w-[6px] xl:max-w-[5.6px] 2xl:text-[18px] lg:text-[15px]  xl:text-[18px] py-5 text-left font-medium text-blue text-[18px] uppercase tracking-wider">
                                CANDIDATE
                              </th>
                              <th className="max-w-[7.5px] 2xl:max-w-[4.8px] xl:max-w-[4.5px] lg:max-w-[5px] 2xl:text-[18px] lg:text-[15px]  xl:text-[18px]   py-5 text-left font-medium text-blue text-[18px] uppercase tracking-wider">
                                MATCH&nbsp;%
                              </th>
                              <th className="max-w-[7.5px] xl:max-w-[4.5px] lg:max-w-[5.5px]  2xl:max-w-[5px] py-5 2xl:text-[18px] lg:text-[15px]  xl:text-[18px]  text-left font-medium text-blue text-[18px] uppercase tracking-wider">
                                RESULT
                              </th>
                              {customer?.show_deni && (
                                <th className="max-w-[6.5px] xl:max-w-[5px] lg:max-w-[5px]  2xl:max-w-[5.5px] 2xl:text-[18px] lg:text-[15px]  xl:text-[18px]   py-5 text-left font-medium text-blue text-[18px] uppercase tracking-wider">
                                  DE&I
                                </th>
                              )}
                              {/* { customer?.ismiddleeast && ( */}
                              <th className="max-w-[7.5px] xl:max-w-[7px] lg:max-w-[7.5px]  2xl:max-w-[7px] 2xl:text-[18px] lg:text-[15px]  xl:text-[18px]   py-5 text-left font-medium text-blue text-[18px] uppercase tracking-wider">
                                NATIONALITY
                              </th>
                              {/* )} */}
                              <th className="max-w-[5.5px] xl:max-w-[3.5px] lg:max-w-[5px]  2xl:max-w-[3.5px] 2xl:text-[18px] lg:text-[15px]  xl:text-[18px]   py-5 text-left font-medium text-blue text-[18px] uppercase tracking-wider">
                                AGE
                              </th>
                              <th className="max-w-[18px] 2xl:max-w-[18px] 2xl:text-[16px] lg:text-[15px]  xl:text-[18px]  lg:max-w-[16px]   py-5 text-left font-medium text-blue text-[18px] uppercase tracking-wider">
                                EMAIL
                              </th>
                              <th className="max-w-[150px] 2xl:text-[18px] lg:text-[15px]  xl:text-[18px]    py-5 text-left font-medium text-blue text-[18px] uppercase tracking-wider"></th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  )}
                  <div
                    className={`mx-[15px] ${
                      noDataAvailable
                        ? "max-h-[calc(100vh-250px)]"
                        : "max-h-[calc(100vh-305px)]"
                    }  overflow-auto shadow-md `}
                    style={{ scrollbarWidth: "none" }}
                  >
                    <div className="">
                      <table className="min-w-full divide-y divide-gray-200">
                        <tbody className="bg-white divide-y divide-gray-200">
                          {data &&
                            data[0]?.candidatesdata?.map((candidate, i) => (
                              <tr
                                key={i}
                                className={`${
                                  i % 2 === 0
                                    ? "bg-[#EDF4FF] border-none"
                                    : "bg-white border-none"
                                }`}
                              >
                                <td className="w-[17%] xl:w-[17.5%] lg:w-[16%]  px-2 py-4  text-sm text-gray-700 font-medium text-[15px]">
                                  <div className=" flex flex-row gap-8 cu">
                                    <div className="w-[30px] xl:w-[14px] lg:w-[12px]">
                                      <Tooltip
                                        title={
                                          candidate?.isfavorite
                                            ? "Favorite"
                                            : "Mark as Favorite"
                                        }
                                      >
                                        <div>
                                          <MarkFavorite
                                            onClick={(e) =>
                                              btnMarkFavorite(candidate)
                                            }
                                            imgUrl={
                                              candidate?.isfavorite
                                                ? "/images/filled-bookmark.svg"
                                                : "/images/bookmark.svg"
                                            }
                                            style={{ cursor: "pointer" }}
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                    <div className="flex flex-col gap-3">
                                      <Tooltip
                                        title={
                                          candidate?.candidatename?.length > 13
                                            ? candidate?.candidatename
                                            : ""
                                        }
                                      >
                                        {candidate?.candidatename?.length > 13
                                          ? `${candidate?.candidatename.substring(
                                              0,
                                              13
                                            )}...`
                                          : candidate?.candidatename}
                                      </Tooltip>
                                      <Tooltip
                                        title={
                                          candidate?.phone.length > 20
                                            ? candidate.phone
                                            : ""
                                        }
                                      >
                                        {candidate?.phone.length > 20
                                          ? `${candidate.phone.substring(
                                              0,
                                              20
                                            )}...`
                                          : candidate?.phone}
                                      </Tooltip>
                                    </div>
                                  </div>
                                </td>

                                <td
                                  className={`${
                                    customer?.show_deni
                                      ? "w-[7%] xl:w-[5%] lg:w-[8%]"
                                      : "w-[10%] ml-6"
                                  } px-2 py-4 text-[15px] text-[#708EA4]`}
                                >
                                  {candidate?.match_percentage}%
                                </td>

                                <td
                                  className="w-[10.5%]  xl:w-[12%] px-2 py-4 text-[15px] font-medium text-[#21D6AA] cursor-pointer"
                                  onClick={(e) =>
                                    navigate("/candidateresult", {
                                      state: candidate?.analysisid,
                                    })
                                  }
                                >
                                  <div className="flex justify-center items-center flex-row gap-1">
                                    <p>VIEW</p>
                                    <img src="/images/arrow.svg" />
                                  </div>
                                </td>
                                {customer?.show_deni && (
                                  <td className="w-[11%] cursor-pointer px-2 py-4 text-sm text-gray-700 font-medium text-[15px]">
                                    <Tooltip
                                      title={
                                        candidate?.ethnicity?.length > 14
                                          ? capitalizeWords(
                                              candidate?.ethnicity
                                            )
                                          : ""
                                      }
                                    >
                                      {candidate?.ethnicity?.length > 14
                                        ? `${capitalizeWords(
                                            candidate?.ethnicity.substring(
                                              0,
                                              14
                                            )
                                          )}...`
                                        : capitalizeWords(candidate?.ethnicity)}
                                    </Tooltip>
                                  </td>
                                )}

                                {/* { customer?.ismiddleeast && ( */}
                                <td
                                  className={` ${
                                    customer?.show_deni
                                      ? "w-[14%] xl:w-[14%] lg:w-[13%]"
                                      : "w-[16.5%]"
                                  } cursor-paste px-2 py-4  text-sm text-gray-700 font-medium text-[15px]`}
                                >
                                  {customer?.ismiddleeast ? (
                                    <Tooltip
                                      title={
                                        candidate?.nationality?.length > 15
                                          ? candidate?.nationality
                                          : ""
                                      }
                                    >
                                      {" "}
                                      {candidate?.nationality?.length > 15
                                        ? `${candidate?.nationality.substring(
                                            0,
                                            15
                                          )}...`
                                        : candidate?.nationality}
                                    </Tooltip>
                                  ) : (
                                    <text className="flex justify-center items-center mr-6 font-bold">
                                      {" "}
                                      -{" "}
                                    </text>
                                  )}
                                </td>
                                {/* )} */}

                                <td className="2xl:w-[6%] xl:w-[6.5%] cursor-paste px-2 py-4  text-sm text-gray-700 font-medium text-[15px]">
                                  {candidate?.age}
                                </td>
                                <td className="w-[100px] px-2 cursor-paste py-4  text-sm text-gray-700 font-medium text-[15px]">
                                  <Tooltip
                                    title={
                                      candidate?.email?.length > 20
                                        ? candidate?.email
                                        : ""
                                    }
                                  >
                                    {candidate?.email?.length > 20
                                      ? `${candidate?.email.substring(
                                          0,
                                          20
                                        )}...`
                                      : candidate?.email}
                                  </Tooltip>
                                </td>

                                <td
                                  className={`w-[80px] px-2 py-2 text-[15px] font-medium ${
                                    candidate?.profileurl
                                      ? "text-[#21D6AA]"
                                      : "text-gray-400"
                                  } `}
                                >
                                  <Tooltip title="ATS Profile">
                                    <div
                                      className={`flex justify-center gap-1 ${
                                        candidate?.profileurl
                                          ? "cursor-pointer"
                                          : "cursor-not-allowed"
                                      }`}
                                      onClick={() =>
                                        candidate?.profileurl &&
                                        window.open(
                                          candidate?.profileurl,
                                          "_blank"
                                        )
                                      }
                                    >
                                      <p
                                        style={{
                                          color: candidate?.profileurl
                                            ? "#21D6AA"
                                            : "#708ea4",
                                        }}
                                      >
                                        ATS
                                      </p>
                                      <img
                                        src={
                                          candidate?.profileurl
                                            ? "/images/profile-icon.svg"
                                            : "/images/profile-icon-disable.svg"
                                        }
                                      />
                                    </div>
                                  </Tooltip>
                                </td>

                                <td className="w-[50px] px-2 py-2 text-[15px] font-medium">
                                  <Tooltip title="External Link">
                                    <img
                                      className={` ${
                                        candidate?.externallink
                                          ? "cursor-pointer"
                                          : "cursor-not-allowed"
                                      }`}
                                      src={
                                        candidate?.externallink
                                          ? "/images/external-link.svg"
                                          : "/images/Disabled External Link.svg"
                                      }
                                      onClick={() =>
                                        candidate?.externallink &&
                                        window.open(
                                          candidate?.externallink,
                                          "_blank"
                                        )
                                      }
                                    />
                                  </Tooltip>
                                </td>

                                <td className="w-[50px]  py-2 text-[15px] font-medium text-[#21D6AA]">
                                  <Tooltip title="Download Resume">
                                    <img
                                      className=" cursor-pointer"
                                      src="/images/resumee.svg"
                                      onClick={() =>
                                        downloadResume(candidate?.resumeurl)
                                      }
                                    />
                                  </Tooltip>
                                </td>
                                <td className="text-[#708EA4] text-sm font-normal">
                                  {formatDateTime(candidate?.createddate)}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}
            </Box>
          </Box>
        </>
      )}
    </>
  );
}

export default ProcessedCandidateByJob;
