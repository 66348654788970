import axios from "../libs/axios";
import { storage } from "../libs/localStorage";

const { retrieveToken } = storage();
const token = retrieveToken();

export const AddNewClientInsert = async (body) => {
  const {
    customername,
    industryid,
    customerpanelurl,
    baseprompt,
    additionalbasepromptindusty,
    understandingjdprompt,
    understandingcvprompt,
    isdetailview,
    calculationprompt,
    resultformatprompt,
    versionremark,
    show_deni,
    ismiddleeast,
    atssync,
    isstandardization,
    isactive,
    atsid,
    dailylimit,
    totallimit,
  } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "insertcustomer",
      customername: customername,
      industryid: industryid,
      customerpanelurl: customerpanelurl,
      baseprompt: baseprompt,
      additionalbasepromptindusty: additionalbasepromptindusty,
      understandingjdprompt: understandingjdprompt,
      isdetailview:isdetailview,
      understandingcvprompt: understandingcvprompt,
      calculationprompt: calculationprompt,
      resultformatprompt: resultformatprompt,
      versionremark: versionremark,
      show_deni: show_deni,
      ismiddleeast: ismiddleeast ,
      atssync: atssync,
      isstandardization: isstandardization,
      isactive: isactive,
      atsid: atsid,
      dailylimit: dailylimit,
      totallimit: totallimit,
    },
  });
  return data;
};

export const updateClientDetails = async (body) => {
  const {
    customer,
    customername,
    industryid,
    customerpanelurl,
    baseprompt,
    additionalbasepromptindusty,
    understandingjdprompt,
    understandingcvprompt,
    calculationprompt,
    resultformatprompt,
    versionremark,
    isdetailview,
    show_deni,
    ismiddleeast,
    atssync,
    isstandardization,
    isactive,
    atsid,
    dailylimit,
    totallimit,
  } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "updatecustomer",
      customername: customername,
      customer: customer,
      industryid: industryid,
      customerpanelurl: customerpanelurl,
      baseprompt: baseprompt,
      isdetailview:isdetailview,
      additionalbasepromptindusty: additionalbasepromptindusty,
      understandingjdprompt: understandingjdprompt,
      understandingcvprompt: understandingcvprompt,
      calculationprompt: calculationprompt,
      resultformatprompt: resultformatprompt,
      versionremark: versionremark,
      show_deni: show_deni,
      ismiddleeast:ismiddleeast,
      atssync: atssync,
      isstandardization: isstandardization,
      isactive: isactive,
      atsid: atsid,
      dailylimit: dailylimit,
      totallimit: totallimit,
    },
  });
  return data;
};
export const updateClientSetting = async (body) => {
  const { customer, show_deni, atssync, isstandardization } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "updateclientsetting",
      customer: customer,
      show_deni: show_deni,
      atssync: atssync,
      isstandardization: isstandardization,
    },
  });
  return data;
};

export const AtsList = async () => {
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getatslist",
    },
  });
  return data;
};

export const IndustryList = async () => {
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getindustrylist",
    },
  });
  return data;
};

export const checkAtsCredentials = async (custid) => {
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "checkatscredentials",
      customer: custid,
    },
  });
  return data;
};
