import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { storage } from "../../libs/localStorage";
import { Drawer, IconButton, Tooltip } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";

export default function Navbar(props) {
  const navigate = useNavigate();
  const { clearToken, retrieveToken } = storage();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("820"));
  const [state, setState] = useState({ left: false });
  const location = useLocation();
  const params = location.state;
  const token = retrieveToken();
  const Hedercustomername = (
    <Tooltip
      title={params?.customername?.length > 20 ? params?.customername : ""}
    >
      <p className="cursor-pointer">
        {params?.userid ? "Edit" : "Add"} User For{" "}
        {params?.customername?.length > 20
          ? `${params?.customername.substring(0, 20)}...`
          : params?.customername}
      </p>
    </Tooltip>
  );
  let expanded = localStorage.getItem("isOpen");
  const [isOpen, setIsOpen] = useState(
    expanded && expanded === "false" ? false : true
  );
  const closeNavbar = () => {
    localStorage.setItem("isOpen", "false");
    setIsOpen(false);
  };

  const openNavbar = () => {
    localStorage.setItem("isOpen", "true");
    setIsOpen(true);
  };

  const toggleDrawers = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const [activeTab, setActiveTab] = useState(props?.data);
  const [title, setTitle] = useState("");
  // Get the usertype from localStorage
  const userType = localStorage.getItem("usertype");
  const customerPanelUrl = localStorage.getItem("customerpanelurl");

  useEffect(() => {
    const updateTitle = () => {
      switch (activeTab) {
        case "processedjobs":
          setTitle("Processed Jobs");
          break;
        case "favorites":
          setTitle("Favorite Candidates");
          break;
        case "feedback":
          setTitle("Feedbacks");
          break;
        case "setting":
          setTitle("Client Settings");
          break;
        case "overview":
          setTitle("Overview");
          break;
        case "addnewclient":
          setTitle("Add New Client");
          break;
        case "Prompt":
          setTitle("Edit Client Settings");
          break;
        case "editclientsettings":
          setTitle("Edit Cleint Settings");
          break;
        case "users":
          setTitle(`User List For ${params?.customername}`);
          break;
        case "prompt":
          setTitle("Prompt History");
          break;
        case "standardrization":
          setTitle("Standardrization Prompts");
          break;
        case "adduser":
          setTitle(Hedercustomername);
          break;
          case "View Std Data":
            setTitle("");
            break;
          
        default:
          setTitle("Processed Jobs");
      }
    };
    updateTitle();
  }, [activeTab]);

  useEffect(() => {
    setActiveTab(props?.data);
  }, [props?.data]);

  const toggleDrawer = () => {
    setIsOpen((prevOpen) => !prevOpen);
  };

  function refreshPage() {
    window.location.reload();
  }
  if (userType === "A") {
    return (
      <>
        {isMobile ? (
          <>
            <div
              className="sticky z-10 p-3 top-0 w-full bg-no-repeat items-center flex justify-between bg-cover"
              style={{
                backgroundImage: "url('/images/Green-Box.svg')",
              }}
            >
              <div className="flex w-full flex-wrap">
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={toggleDrawers("left", true)}
                >
                  <img src="/images/Menu-Icon-mobile.svg" />
                </IconButton>{" "}
                <p className="flex flex-wrap text-[18px] font-bold text-white mt-1">
                  {title}
                </p>
              </div>
            </div>
            <div>
              <Drawer
                anchor={"left"}
                open={state["left"]}
                onClose={toggleDrawers("left", false)}
              >
                <div className="p-3">
                  <div className="py-4">
                    <img
                      className="max-w-28 cursor-pointer  "
                      src="/images/Full Logo.svg"
                      alt="Logo"
                    />
                  </div>
                  <div
                    className="flex flex-wrap gap-2 py-4"
                    onClick={() => {
                      navigate("/overview");
                      refreshPage();
                    }}
                  >
                    <img
                      src={
                        activeTab === "overview"
                          ? "/images/overview-active.svg"
                          : "/images/overview.svg"
                      }
                      alt="Overview"
                    />
                    <p
                      className={`text-[15px] ${
                        activeTab === "overview"
                          ? "text-primary"
                          : "text-lightblue"
                      }  font-semibold font-Rubik uppercase`}
                    >
                      Overview
                    </p>
                    {activeTab === "overview" ? (
                      <span className=" w-1 bg-primary relative top-auto left-auto -right-[70px]"></span>
                    ) : null}
                  </div>
                  <div
                    className="flex flex-wrap gap-2  py-4"
                    onClick={() => {
                      navigate("/processedjobs");
                      refreshPage();
                    }}
                  >
                    <img
                      src={
                        activeTab === "processedjobs"
                          ? "/images/bag.svg"
                          : "/images/black-briefcase.svg"
                      }
                      alt="Processed Jobs"
                    />
                    <p
                      className={`text-[15px] ${
                        activeTab === "processedjobs"
                          ? "text-primary"
                          : "text-lightblue"
                      }  font-semibold font-Rubik uppercase`}
                    >
                      Processed Jobs
                    </p>
                    {activeTab === "processedjobs" ? (
                      <span className=" w-1 bg-primary relative top-auto left-auto -right-3"></span>
                    ) : null}
                  </div>
                  <div
                    className="flex flex-wrap gap-2 py-4"
                    onClick={() => {
                      navigate("/favorites");
                      refreshPage();
                    }}
                  >
                    <img
                      src={
                        activeTab === "favorites"
                          ? "/images/green-favorite.svg"
                          : "/images/Favorite Icon.svg"
                      }
                      alt="favorites"
                    />
                    <p
                      className={`text-[15px] ${
                        activeTab === "favorites"
                          ? "text-primary"
                          : "text-lightblue"
                      }  font-semibold font-Rubik uppercase`}
                    >
                      favorites
                    </p>
                    {activeTab === "favorites" ? (
                      <span className=" w-1 bg-primary relative top-auto left-auto -right-16"></span>
                    ) : null}
                  </div>
                  <div
                    className="flex flex-wrap gap-2 py-4"
                    onClick={() => {
                      userType == "A"
                        ? window.open(
                            customerPanelUrl + "/?token=" + token,
                            "_blank"
                          )
                        : window.open(customerPanelUrl, "_blank");
                    }}
                  >
                    <img src={"/images/Resume-Icon.svg"} alt="UPLOAD RESUMES" />
                    <p className=" text-[15px]  text-lightblue font-semibold font-Rubik uppercase">
                      UPLOAD RESUMES
                    </p>
                  </div>
                  <div
                    className="flex flex-wrap gap-2 py-4"
                    onClick={() => {
                      navigate("/feedback");
                      refreshPage();
                    }}
                  >
                    <img
                      src={
                        activeTab === "feedback"
                          ? "/images/feedbacks-icon.svg"
                          : "/images/feedbacks-icon-white.svg"
                      }
                      alt="Feedback"
                    />
                    <p
                      className={`text-[15px] ${
                        activeTab === "feedback"
                          ? "text-primary"
                          : "text-lightblue"
                      }  font-semibold font-Rubik uppercase`}
                    >
                      FEEDBACK
                    </p>
                    {activeTab === "feedback" ? (
                      <span className=" w-1 bg-primary relative top-auto left-auto -right-[66px]"></span>
                    ) : null}
                  </div>
                  <div
                    className="flex flex-wrap gap-2 py-4"
                    onClick={() => {
                      navigate("/setting");
                      refreshPage();
                    }}
                  >
                    <img
                      src={
                        activeTab === "setting"
                          ? "/images/settingcolor.svg"
                          : "/images/settingwhite.svg"
                      }
                      alt="Setting"
                    />
                    <p
                      className={`text-[15px] ${
                        activeTab === "setting"
                          ? "text-primary"
                          : "text-lightblue"
                      }  font-semibold font-Rubik uppercase`}
                    >
                      client Settings
                    </p>
                    {activeTab === "setting" ? (
                      <span className=" w-1 bg-primary relative top-auto left-auto -right-4"></span>
                    ) : null}
                  </div>
                  <div
                    className="flex flex-wrap gap-2 py-4"
                    onClick={() => {
                      navigate("/standardization");
                      refreshPage();
                    }}
                  >
                    <img
                      src={
                        activeTab === "standardrization"
                          ? "/images/Standardization1.svg"
                          : "/images/Standardization.svg"
                      }
                      alt="standardrization"
                    />
                    <p
                      className={`text-[15px] ${
                        activeTab === "standardrization"
                          ? "text-primary"
                          : "text-lightblue"
                      }  font-semibold font-Rubik uppercase`}
                    >
                      Standardization 
                    </p>
                    {activeTab === "standardrization" ? (
                      <span className=" w-1 bg-primary relative top-auto left-auto -right-4"></span>
                    ) : null}
                  </div>
                </div>
                <div className="absolute top-auto bottom-0 px-3 pt-3 pb-1">
                  <div
                    className="flex flex-wrap gap-2 py-4"
                    onClick={() => {
                      clearToken();
                      window.location.replace(customerPanelUrl + "/logout");
                    }}
                  >
                    <img src="/images/download.svg" alt="logout" />
                    <p className=" text-[15px]   text-red-400 font-medium font-Rubik uppercase">
                      Logout
                    </p>
                  </div>
                </div>
              </Drawer>
            </div>
          </>
        ) : (
          <div className="flex max-w-full max-h-[calc(100vh-0px)]">
            <div className="">
              <img
                src="/images/Menu-Icon-mobile.svg"
                className={`block md:hidden absolute top-5 left-5 z-10 cursor-pointer`}
                alt="toggle"
                onClick={toggleDrawer}
              />
            </div>
            <div
              style={{ boxShadow: "8px 8px 15px rgba(91, 147, 237, 0.2)" }}
              className={` ${
                isOpen ? "w-60" : "w-16 max-h-[calc(100vh-0px)] hidden "
              } bg-white ps-5 pt-5 relative duration-300 ${
                toggleDrawer ? "block" : "hidden"
              } md:block`}
            >
              <div class="flex justify-between items-center">
                {isOpen ? (
                  <img
                    src="/images/Collapse Panel Icon.svg"
                    className="absolute hidden md:block cursor-pointer right-5  w-5"
                    alt="collapse"
                    onClick={closeNavbar}
                  />
                ) : null}
                {isOpen ? (
                  <img
                    className="max-w-28 cursor-pointer  "
                    src="/images/Full Logo.svg"
                    alt="Logo"
                  />
                ) : (
                  <img
                    className="max-w-28 cursor-pointer "
                    src="/images/Short Logo.svg"
                    alt="Logo"
                  />
                )}
              </div>
              <div
                className={`flex justify-between flex-col min-h-[calc(100vh-50px)]`}
              >
                <div className="flex justify-start ">
                  <div className="flex-grow">
                    <div
                      className="pt-8 "
                      onClick={() => {
                        navigate("/overview");
                      }}
                    >
                      <div
                        style={{
                          color:
                            activeTab === "overview" ? "#21D6AA" : "#708EA4",
                        }}
                        className={`min-h-12 flex justify-between cursor-pointer  items-center p-1 me-[86px] ${
                          isOpen ? "" : "absolute top-16"
                        }`}
                      >
                        <div className={` ${isOpen ? "" : "min-w-7 "}`}>
                          <img
                            src={
                              activeTab === "overview"
                                ? "/images/overview-active.svg"
                                : "/images/overview.svg"
                            }
                            alt="Overview"
                          />
                        </div>
                        {isOpen ? (
                          <div className=" text-{15px} font-bold font-Rubik">
                            OVERVIEW
                          </div>
                        ) : null}
                      </div>
                      {activeTab === "overview" && (
                        <div
                          className={` ${
                            isOpen ? "top-[80px] " : "top-[70px]"
                          } absolute cursor-pointer bottom-0 right-0 max-h-9 w-1.5 bg-teal-400`}
                        ></div>
                      )}
                    </div>
                    <div
                      className="pt-2 "
                      onClick={() => {
                        navigate("/processedjobs");
                        refreshPage();
                      }}
                    >
                      <div
                        style={{
                          color:
                            activeTab === "processedjobs"
                              ? "#21D6AA"
                              : "#708EA4",
                        }}
                        className={`min-h-12 flex justify-between cursor-pointer  items-center p-1 me-[24px] ${
                          isOpen ? "" : "pt-10"
                        }`}
                      >
                        <div className={` ${isOpen ? "" : "min-w-7 "}`}>
                          <img
                            src={
                              activeTab === "processedjobs"
                                ? "/images/bag.svg"
                                : "/images/black-briefcase.svg"
                            }
                            alt="Processed Jobs"
                          />
                        </div>
                        {isOpen ? (
                          <div className="text-{15px} font-bold font-Rubik">
                            PROCESSED JOBS
                          </div>
                        ) : null}
                      </div>
                      {activeTab === "processedjobs" && (
                        <div
                          className={` ${
                            isOpen ? "top-[134px] " : "top-[122px]"
                          } absolute cursor-pointer bottom-0 right-0 max-h-9 w-1.5 bg-teal-400`}
                        ></div>
                      )}
                    </div>
                    <div
                      className="pt-2"
                      onClick={() => {
                        navigate("/favorites");
                        refreshPage();
                      }}
                    >
                      <div
                        style={{
                          color:
                            activeTab === "favorites" ? "#21D6AA" : "#708EA4",
                        }}
                        className={`min-h-12 cursor-pointer  flex justify-between items-center p-1 me-20 ${
                          isOpen ? "" : "pt-6"
                        }`}
                      >
                        <div className={` ${isOpen ? "" : "min-w-7"}`}>
                          <img
                            src={
                              activeTab === "favorites"
                                ? "/images/green-favorite.svg"
                                : "/images/Favorite Icon.svg"
                            }
                            alt="favorites"
                          />
                        </div>
                        {isOpen ? (
                          <div className=" text-{15px} font-bold font-Rubik">
                            FAVORITES
                          </div>
                        ) : null}
                      </div>
                      {activeTab === "favorites" && (
                        <div className="absolute  cursor-pointer top-[186px] bottom-0 right-0 max-h-9 w-1.5 bg-teal-400"></div>
                      )}
                    </div>
                    <div
                      onClick={() => {
                        userType == "A"
                          ? window.open(
                              customerPanelUrl + "/?token=" + token,
                              "_blank"
                            )
                          : window.open(customerPanelUrl, "_blank");
                      }}
                    >
                      <div
                        style={{
                          color:
                            activeTab === "uploadresumes"
                              ? "#21D6AA"
                              : "#708EA4",
                        }}
                        className={`min-h-12  cursor-pointer flex justify-between items-center p-1 mr-[24px] ${
                          isOpen ? "pt-6" : "pt-6"
                        }`}
                      >
                        <div className={` ${isOpen ? "w-8" : "min-w-7"}`}>
                          <img
                            src={
                              activeTab === "uploadresumes"
                                ? "/images/Resume-Icon.svg"
                                : "/images/Resume-Icon.svg"
                            }
                            alt="UPLOAD RESUMES"
                          />
                        </div>
                        {isOpen ? (
                          <div className="cursor-pointer  text-{15px} font-bold font-Rubik">
                            UPLOAD RESUMES
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div
                      className={` ${isOpen ? "pt-5" : "pt-3 "}`}
                      onClick={() => {
                        navigate("/feedback");
                        refreshPage();
                      }}
                    >
                      <div
                        style={{
                          color:
                            activeTab === "feedback" ? "#21D6AA" : "#708EA4",
                        }}
                        className={`min-h-12 cursor-pointer  flex justify-between items-center p-1 me-20`}
                      >
                        <div className={` ${isOpen ? "" : "min-w-7 "}`}>
                          <img
                            src={
                              activeTab === "feedback"
                                ? "/images/feedbacks-icon.svg"
                                : "/images/feedbacks-icon-white.svg"
                            }
                            alt="Feedback"
                          />
                        </div>
                        {isOpen ? (
                          <div className="text-{15px} font-bold font-Rubik">
                            FEEDBACK
                          </div>
                        ) : null}
                      </div>
                      {activeTab === "feedback" && (
                        <div
                          className={`absolute cursor-pointer ${
                            isOpen ? "top-[312px]" : "top-[292px]"
                          } bottom-0 right-0 max-h-9 w-1.5 bg-teal-400`}
                        ></div>
                      )}
                    </div>
                    <div
                      className={` ${isOpen ? "pt-1" : "pt-1 "}`}
                      onClick={() => {
                        navigate("/setting");
                        refreshPage();
                      }}
                    >
                      <div
                        style={{
                          color:
                            activeTab === "setting" ? "#21D6AA" : "#708EA4",
                        }}
                        className={`min-h-12 cursor-pointer  flex justify-between items-center p-1 me-8`}
                      >
                        <div className={` ${isOpen ? "" : "min-w-7 "}`}>
                          <img
                            src={
                              activeTab === "setting"
                                ? "/images/settingcolor.svg"
                                : "/images/settingwhite.svg"
                            }
                            alt="setting"
                          />
                        </div>
                        {isOpen ? (
                          <div className="text-{15px} font-bold font-Rubik">
                            CLIENT SETTINGS
                          </div>
                        ) : null}
                      </div>
                      {activeTab === "setting" && (
                        <div
                          className={`absolute cursor-pointer ${
                            isOpen ? "top-[364px]" : "top-[342px]"
                          } bottom-0 right-0 max-h-9 w-1.5 bg-teal-400`}
                        ></div>
                      )}
                    </div>
                    <div
                      className={` ${isOpen ? "pt-1" : "pt-1 "}`}
                      onClick={() => {
                        navigate("/standardization");
                        refreshPage();
                      }}
                    >
                      <div
                        style={{
                          color:
                            activeTab === "standardrization"
                              ? "#21D6AA"
                              : "#708EA4",
                        }}
                        className={`min-h-12 cursor-pointer  flex justify-between items-center p-1 me-5`}
                      >
                        <div className={` ${isOpen ? "" : "min-w-7 "}`}>
                          <img
                            src={
                              activeTab === "standardrization"
                                ? "/images/Standardization1.svg"
                                : "/images/Standardization.svg"
                            }
                            alt="standardrization"
                          />
                        </div>
                        {isOpen ? (
                          <div className="text-{15px} font-bold font-Rubik uppercase">
                            Standardization
                          </div>
                        ) : null}
                      </div>
                      {activeTab === "standardrization" && (
                        <div
                          className={`absolute cursor-pointer ${
                            isOpen ? "top-[414px]" : "top-[391px]"
                          } bottom-0 right-0 max-h-9 w-1.5 bg-teal-400`}
                        ></div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="justify-end flex flex-col h-[100px]">
                  <div className="pb-8 ">
                    {!isOpen ? (
                      <img
                        src="/images/Collapse Panel Icon.svg"
                        alt="collapse"
                        className="absolute cursor-pointer  w-5 rotate-180 "
                        onClick={openNavbar}
                      />
                    ) : null}
                  </div>
                  <div>
                    {/* <div
                      className={`min-h-12 cursor-pointer  flex justify-between items-center   ${
                        isOpen ? "" : "pt-0"
                      }  ${
                        activeTab === "settings"
                          ? "text-teal-400 opacity-100"
                          : "text-gray-500"
                      }`}
                    >
                      <div className={` ${isOpen ? "" : "min-w-7"}`}>
                        <img src="/images/setting.svg" alt="setting" />
                      </div>
                      {isOpen ? (
                        <div className="me-24 text-{15px} font-bold font-Rubik">
                          SETTINGS
                        </div>
                      ) : null}
                    </div> */}
                  </div>
                  <div
                    className=""
                    onClick={() => {
                      clearToken();
                      //   window.open("https://bmc.talentin.ai", "_self");
                      window.location.replace(customerPanelUrl + "/logout");
                    }}
                  >
                    <div
                      className={`min-h-12 cursor-pointer  flex justify-between items-center  ${
                        isOpen ? "ps-1" : "pt-0 ps-1"
                      } text-gray-500`}
                    >
                      <div className={` ${isOpen ? "" : "min-w-7"}`}>
                        <img src="/images/download.svg" alt="logout" />
                      </div>
                      {isOpen ? (
                        <div className="me-28 text-red-400 text-{15px} font-bold font-Rubik">
                          LOGOUT
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  } else {
    return (
      <>
        {isMobile ? (
          <>
            <div
              className="sticky z-[999] p-3 top-0 w-full bg-no-repeat items-center flex justify-between bg-cover"
              style={{
                backgroundImage: "url('/images/Green-Box.svg')",
              }}
            >
              <div className="flex w-full flex-wrap">
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={toggleDrawers("left", true)}
                >
                  <img src="/images/Menu-Icon-mobile.svg" />
                </IconButton>{" "}
                <p className="flex flex-wrap text-[18px] font-bold text-white">
                  {title}
                </p>
              </div>
            </div>
            <div>
              <Drawer
                anchor={"left"}
                open={state["left"]}
                onClose={toggleDrawers("left", false)}
              >
                <div className="p-3">
                  <div className="py-4">
                    <img
                      className="max-w-28 cursor-pointer  "
                      src="/images/Full Logo.svg"
                      alt="Logo"
                    />
                  </div>

                  <div
                    className="flex flex-wrap gap-2  py-4"
                    onClick={() => {
                      navigate("/processedjobs");
                      refreshPage();
                    }}
                  >
                    <img
                      src={
                        activeTab === "processedjobs"
                          ? "/images/bag.svg"
                          : "/images/black-briefcase.svg"
                      }
                      alt="Processed Jobs"
                    />
                    <p
                      className={`text-[15px] ${
                        activeTab === "processedjobs"
                          ? "text-primary"
                          : "text-lightblue"
                      }  font-semibold font-Rubik uppercase`}
                    >
                      Processed Jobs
                    </p>
                    {activeTab === "processedjobs" ? (
                      <span className=" w-1 bg-primary relative top-auto left-auto -right-3"></span>
                    ) : null}
                  </div>
                  <div
                    className="flex flex-wrap gap-2 py-4"
                    onClick={() => {
                      navigate("/favorites");
                      refreshPage();
                    }}
                  >
                    <img
                      src={
                        activeTab === "favorites"
                          ? "/images/green-favorite.svg"
                          : "/images/Favorite Icon.svg"
                      }
                      alt="favorites"
                    />
                    <p
                      className={`text-[15px] ${
                        activeTab === "favorites"
                          ? "text-primary"
                          : "text-lightblue"
                      }  font-semibold font-Rubik uppercase`}
                    >
                      favorites
                    </p>
                    {activeTab === "favorites" ? (
                      <span className=" w-1 bg-primary relative top-auto left-auto -right-16"></span>
                    ) : null}
                  </div>
                  <div
                    className="flex flex-wrap gap-2 py-4"
                    onClick={() => {
                      window.open(customerPanelUrl, "_blank");
                    }}
                  >
                    <img src={"/images/Resume-Icon.svg"} alt="UPLOAD RESUMES" />
                    <p className=" text-[15px]  text-lightblue font-semibold font-Rubik uppercase">
                      UPLOAD RESUMES
                    </p>
                  </div>

                  <div
                    className="flex flex-wrap gap-2 py-4"
                    onClick={() => {
                      navigate("/feedback");
                      refreshPage();
                    }}
                  >
                    <img
                      src={
                        activeTab === "feedback"
                          ? "/images/feedbacks-icon.svg"
                          : "/images/feedbacks-icon-white.svg"
                      }
                      alt="Feedback"
                    />
                    <p
                      className={`text-[15px] ${
                        activeTab === "feedback"
                          ? "text-primary"
                          : "text-lightblue"
                      }  font-semibold font-Rubik uppercase`}
                    >
                      FEEDBACK
                    </p>
                    {activeTab === "feedback" ? (
                      <span className=" w-1 bg-primary relative top-auto left-auto -right-16"></span>
                    ) : null}
                  </div>
                </div>
                <div className="absolute top-auto bottom-0 px-3 pt-3 pb-1">
                  <div
                    className="flex flex-wrap gap-2 py-4"
                    onClick={() => {
                      clearToken();
                      // window.open("https://bmc.talentin.ai", "_self");
                      window.location.replace(customerPanelUrl + "/logout");
                    }}
                  >
                    <img src="/images/download.svg" alt="logout" />
                    <p className=" text-[15px]  text-red-400 font-medium font-Rubik uppercase">
                      Logout
                    </p>
                  </div>
                </div>
              </Drawer>
            </div>
          </>
        ) : (
          // <div className="flex max-w-full max-h-[calc(100vh-0px)] ">
          //   <div className="">
          //     <img
          //       src="/images/Menu-Icon-mobile.svg"
          //       className={`block md:hidden absolute top-5 left-5 z-10 cursor-pointer`}
          //       alt="toggle"
          //       onClick={toggleDrawer}
          //     />
          //   </div>

          //   <div
          //     style={{ boxShadow: "8px 8px 15px rgba(91, 147, 237, 0.2)" }}
          //     className={`${
          //       isOpen ? "w-60" : "w-16 hidden"
          //     } bg-white ps-5 pt-5 relative duration-300 ${
          //       toggleDrawer ? "block" : "hidden"
          //     } md:block`}
          //   >
          //     <div class="flex justify-between items-center">
          //       {isOpen ? (
          //         <img
          //           src="/images/Collapse Panel Icon.svg"
          //           className="absolute hidden md:block cursor-pointer right-5  w-5"
          //           alt="collapse"
          //           onClick={() => setIsOpen(!isOpen)}
          //         />
          //       ) : null}
          //       {isOpen ? (
          //         <img
          //           className="max-w-28 cursor-pointer  "
          //           src="/images/Full Logo.svg"
          //           alt="Logo"
          //         />
          //       ) : (
          //         <img
          //           className="max-w-28 cursor-pointer "
          //           src="/images/Short Logo.svg"
          //           alt="Logo"
          //         />
          //       )}
          //     </div>

          //     <div className="flex justify-between flex-col">
          //       <div className="flex justify-start">
          //         <div className="flex-grow">
          //           <div
          //             className="pt-8"
          //             onClick={() => {
          //               navigate("/processedjobs");
          //               refreshPage();
          //             }}
          //           >
          //             <div
          //               style={{
          //                 color:
          //                   activeTab === "processedjobs"
          //                     ? "#21D6AA"
          //                     : "#708EA4",
          //               }}
          //               className={`min-h-12 flex justify-between cursor-pointer items-center p-1 me-[24px] ${
          //                 isOpen ? "" : "ababsolute top-16"
          //               }`}
          //             >
          //               <div className={`${isOpen ? "" : "min-w-7"}`}>
          //                 <img
          //                   src={
          //                     activeTab === "processedjobs"
          //                       ? "/images/bag.svg"
          //                       : "/images/black-briefcase.svg"
          //                   }
          //                   alt="Processed Jobs"
          //                 />
          //               </div>
          //               {isOpen ? (
          //                 <div className="text-{15px} font-bold font-Rubik">
          //                   PROCESSED JOBS
          //                 </div>
          //               ) : null}
          //             </div>
          //             {activeTab === "processedjobs" && (
          //               <div
          //                 className={`${
          //                   isOpen ? "top-[80px]" : "top-[90px]"
          //                 } absolute cursor-pointer bottom-0 right-0 max-h-9 w-1.5 bg-teal-400`}
          //               ></div>
          //             )}
          //           </div>

          //           <div
          //             className="pt-2"
          //             onClick={() => {
          //               navigate("/favorites");
          //               refreshPage();
          //             }}
          //           >
          //             <div
          //               style={{
          //                 color:
          //                   activeTab === "favorites" ? "#21D6AA" : "#708EA4",
          //               }}
          //               className={`min-h-12 cursor-pointer flex justify-between items-center p-1 me-20 ${
          //                 isOpen ? "" : "pt-6"
          //               }`}
          //             >
          //               <div className={`${isOpen ? "" : "min-w-7"}`}>
          //                 <img
          //                   src={
          //                     activeTab === "favorites"
          //                       ? "/images/green-favorite.svg"
          //                       : "/images/Favorite Icon.svg"
          //                   }
          //                   alt="Favorites"
          //                 />
          //               </div>
          //               {isOpen ? (
          //                 <div className=" text-{15px} font-bold font-Rubik">
          //                   FAVORITES
          //                 </div>
          //               ) : null}
          //             </div>
          //             {activeTab === "favorites" && (
          //               <div
          //                 className={`${
          //                   isOpen ? "top-[130px]" : "top-[158px]"
          //                 } absolute cursor-pointer bottom-0 right-0 max-h-9 w-1.5 bg-teal-400`}
          //               ></div>
          //             )}
          //           </div>

          //           {/* //addnewclient */}
          //           {/* <div
          //             className="pt-2"
          //             onClick={() => {
          //               navigate("/addnewclient");
          //               refreshPage();
          //             }}
          //           >
          //             <div
          //               style={{
          //                 color:
          //                   activeTab === "addnewclient" ? "#21D6AA" : "#708EA4",
          //               }}
          //               className={`min-h-12 cursor-pointer flex justify-between items-center p-1 mr-10 ${
          //                 isOpen ? "" : "pt-6"
          //               }`}
          //             >
          //               <div className={`${isOpen ? "" : "min-w-7"}`}>
          //                 <img
          //                   src={
          //                     activeTab === "addnewclient"
          //                       ? "/images/bag.svg"
          //                       : "/images/black-briefcase.svg"
          //                   }
          //                   alt="Add New Client"
          //                 />
          //               </div>
          //               {isOpen ? (
          //                 <div className="text-{15px} font-bold font-Rubik">
          //                 ADD NEW CLIENT
          //                 </div>
          //               ) : null}
          //             </div>
          //             {activeTab === "addnewclient" && (
          //               <div
          //                 className={`${
          //                   isOpen ? "top-[195px]" : "top-[210px]"
          //                 } absolute cursor-pointer bottom-0 right-0 max-h-9 w-1.5 bg-teal-400`}
          //               ></div>
          //             )}
          //           </div> */}

          //           <div
          //             onClick={() => {
          //               window.open(customerPanelUrl, "_blank");
          //             }}
          //           >
          //             <div
          //               style={{
          //                 color:
          //                   activeTab === "uploadresumes"
          //                     ? "#21D6AA"
          //                     : "#708EA4",
          //               }}
          //               className={`min-h-12 cursor-pointer flex justify-between items-center p-1 mr-[24px] ${
          //                 isOpen ? "pt-6" : "pt-6"
          //               }`}
          //             >
          //               <div className={`${isOpen ? "w-8" : "min-w-7"}`}>
          //                 <img
          //                   src={
          //                     activeTab === "uploadresumes"
          //                       ? "/images/Resume-Icon.svg"
          //                       : "/images/Resume-Icon.svg"
          //                   }
          //                   alt="UPLOAD RESUMES"
          //                 />
          //               </div>
          //               {isOpen ? (
          //                 <div className="cursor-pointer  text-{15px} font-bold font-Rubik">
          //                   UPLOAD RESUMES
          //                 </div>
          //               ) : null}
          //             </div>
          //           </div>

          //         </div>
          //       </div>
          //       <div
          //         className={`justify-end flex flex-col ${
          //           isOpen ? "max-h-[390px]" : "max-h-[360px]"
          //         } `}
          //       >
          //         <div className="pb-8">
          //           {!isOpen ? (
          //             <img
          //               src="/images/Collapse Panel Icon.svg"
          //               alt="collapse"
          //               className="absolute cursor-pointer w-5 rotate-180"
          //               onClick={() => setIsOpen(!isOpen)}
          //             />
          //           ) : null}
          //         </div>

          //           {/* Log out  */}
          //         <div
          //           className=""
          //           onClick={() => {
          //             clearToken();
          //           //   window.open("https://bmc.talentin.ai", "_self");
          //               window.location.replace(customerPanelUrl + "logout");
          //           }}
          //         >
          //           <div
          //             className={`min-h-12 cursor-pointer flex justify-between items-center ${
          //               isOpen ? "ps-1" : "pt-7 ps-1"
          //             } text-gray-500`}
          //           >
          //             <div className={`${isOpen ? "" : "min-w-7"}`}>
          //               <img src="/images/download.svg" alt="logout" />
          //             </div>
          //             {isOpen ? (
          //               <div className="me-28 text-red-400 text-{15px} font-bold font-Rubik">
          //                 LOGOUT
          //               </div>
          //             ) : null}
          //           </div>
          //         </div>
          //       </div>
          //     </div>
          //   </div>
          // </div>

          <div className="flex max-w-full max-h-[calc(100vh-0px)] ">
            <div>
              <img
                src="/images/Menu-Icon-mobile.svg"
                className="block md:hidden absolute top-5 left-5 z-10 cursor-pointer"
                alt="toggle"
                onClick={toggleDrawer}
              />
            </div>

            <div
              style={{ boxShadow: "8px 8px 15px rgba(91, 147, 237, 0.2)" }}
              className={`${
                isOpen ? "w-60   xl:w-60 lg:w-55" : "w-16 h-[100vh]  hidden"
              } bg-white ps-5 pt-5 relative duration-300 ${
                toggleDrawer ? "block" : "hidden"
              } md:block`}
            >
              <div className="flex justify-start gap-2 w-full  items-center">
                {isOpen ? (
                  <img
                    src="/images/Collapse Panel Icon.svg"
                    className="absolute hidden md:block cursor-pointer right-4 w-5"
                    alt="collapse"
                    onClick={closeNavbar}
                  />
                ) : null}
                {isOpen ? (
                  <img
                    className="max-w-28 cursor-pointer"
                    src="/images/Full Logo.svg"
                    alt="Logo"
                  />
                ) : (
                  <img
                    className="max-w-28 cursor-pointer"
                    src="/images/Short Logo.svg"
                    alt="Logo"
                  />
                )}
              </div>

              <div className="flex justify-between flex-col h-[95%] ">
                <div className="flex-grow">
                  <div
                    className="pt-9"
                    onClick={() => {
                      navigate("/processedjobs");
                      refreshPage();
                    }}
                  >
                    <div
                      style={{
                        color:
                          activeTab === "processedjobs" ? "#21D6AA" : "#708EA4",
                      }}
                      className={`min-h-12 flex justify-start gap-5  cursor-pointer items-center p-1 me-4 ${
                        isOpen ? "" : "absolute top-16"
                      }`}
                    >
                      <div className={`${isOpen ? "" : "min-w-7"}`}>
                        <img
                          src={
                            activeTab === "processedjobs"
                              ? "/images/bag.svg"
                              : "/images/black-briefcase.svg"
                          }
                          alt="Processed Jobs"
                        />
                      </div>
                      {isOpen ? (
                        <div className="text-[15px] font-bold font-Rubik">
                          PROCESSED JOBS
                        </div>
                      ) : null}
                    </div>
                    {activeTab === "processedjobs" && (
                      <div
                        className={`${
                          isOpen ? "top-[80px]" : "top-[70px]"
                        } absolute cursor-pointer bottom-0 right-0 max-h-9 w-1.5 bg-teal-400`}
                      ></div>
                    )}
                  </div>

                  <div
                    className="pt-2"
                    onClick={() => {
                      navigate("/favorites");
                      refreshPage();
                    }}
                  >
                    <div
                      style={{
                        color:
                          activeTab === "favorites" ? "#21D6AA" : "#708EA4",
                      }}
                      className={`min-h-12 cursor-pointer flex justify-start gap-5 items-center p-1 me-4 ${
                        isOpen ? "" : "pt-9"
                      }`}
                    >
                      <div className={`${isOpen ? "" : "min-w-7"}`}>
                        <img
                          src={
                            activeTab === "favorites"
                              ? "/images/green-favorite.svg"
                              : "/images/Favorite Icon.svg"
                          }
                          alt="Favorites"
                        />
                      </div>
                      {isOpen ? (
                        <div className="text-[15px] font-bold font-Rubik">
                          FAVORITES
                        </div>
                      ) : null}
                    </div>
                    {activeTab === "favorites" && (
                      <div
                        className={`${
                          isOpen ? "top-[130px]" : "top-[124px]"
                        } absolute cursor-pointer bottom-0 right-0 max-h-9 w-1.5 bg-teal-400`}
                      ></div>
                    )}
                  </div>

                  <div
                    onClick={() => {
                      window.open(customerPanelUrl, "_blank");
                    }}
                  >
                    <div
                      style={{
                        color:
                          activeTab === "uploadresumes" ? "#21D6AA" : "#708EA4",
                      }}
                      className={`min-h-12 cursor-pointer flex justify-start gap-5 items-center p-1 me-4 ${
                        isOpen ? "pt-3" : "pt-9"
                      }`}
                    >
                      <div className={`${isOpen ? "" : "min-w-7"}`}>
                        <img
                          src="/images/Resume-Icon.svg"
                          alt="UPLOAD RESUMES"
                        />
                      </div>
                      {isOpen ? (
                        <div className="text-[15px] font-bold font-Rubik">
                          UPLOAD RESUMES
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div
                    className="pt-2"
                    onClick={() => {
                      navigate("/feedback");
                      refreshPage();
                    }}
                  >
                    <div
                      style={{
                        color: activeTab === "feedback" ? "#21D6AA" : "#708EA4",
                      }}
                      className={`min-h-12 cursor-pointer flex justify-start gap-5 items-center p-1 me-4 ${
                        isOpen ? "" : "pt-9"
                      }`}
                    >
                      <div className={`${isOpen ? "" : "min-w-7"}`}>
                        <img
                          src={
                            activeTab === "feedback"
                              ? "/images/feedbacks-icon.svg"
                              : "/images/feedbacks-icon-white.svg"
                          }
                          alt="Feedback"
                        />
                      </div>
                      {isOpen ? (
                        <div className="text-[15px] font-bold font-Rubik">
                          FEEDBACK
                        </div>
                      ) : null}
                    </div>
                    {activeTab === "feedback" && (
                      <div
                        className={`${
                          isOpen ? "top-[240px]" : "top-[260px]"
                        } absolute cursor-pointer bottom-0 right-0 max-h-9 w-1.5 bg-teal-400`}
                      ></div>
                    )}
                  </div>
                </div>

                <div className="justify-end flex flex-col pb-3 ">
                  <div>
                    {!isOpen ? (
                      <img
                        src="/images/Collapse Panel Icon.svg"
                        alt="collapse"
                        className="absolute cursor-pointer w-5 rotate-180"
                        onClick={openNavbar}
                      />
                    ) : null}
                  </div>

                  <div
                    onClick={() => {
                      clearToken();
                      window.location.replace(customerPanelUrl + "/logout");
                    }}
                  >
                    <div
                      className={`min-h-12 cursor-pointer flex justify-start gap-5 items-center   ${
                        isOpen ? "ps-1" : "pt-12 ps-1"
                      } text-gray-500`}
                    >
                      <div className={`${isOpen ? "w-7" : "min-w-7"}`}>
                        <img src="/images/download.svg" alt="logout" />
                      </div>
                      {isOpen ? (
                        <div className=" text-red-400 text-{15px} font-bold font-Rubik">
                          LOGOUT
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
