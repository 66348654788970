import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar/Navbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Tooltip, useMediaQuery } from "@mui/material";

import {
  checkAtsCredentials,
  // updateClientDetails,
  updateClientSetting,
} from "../../data/addNewClient";
import { useNavigate } from "react-router-dom";
import { useClientSettingData } from "../../data/clientSettings";
import Pagination from "../../components/Pagination/Pagination";
import "./ClientSetting.css";
import HeaderBar from "../../components/HeaderBar/HeaderBar";



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.white,
    fontFamily: "Rubik, sans-serif",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Rubik, sans-serif",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#EDF4FF",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#ffffff",
  },
}));



export default function ClientSetting() {
  //mobile
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("820"));
  const activetab = "setting";
  const [data, setData] = useState(null);
  const { GetCustomers } = useClientSettingData();
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(0);
  // const totalPages = Math.ceil(totalCounts / pageSize);
  const [searchText, setSearchText] = useState("");
  const [totalCounts, setTotalCounts] = useState(null);
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  const totalPages = Math.ceil(totalCounts / pageSize);
  const navigate = useNavigate();

  const [openDrawer, setOpenDrawer] = useState(false);
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const btnEdit = (params) => {
    navigate("/editclientsetting", { state: params });
  };

  const btnAddClient = () => {
    navigate("/addnewclient");
  };

  const searchTextChange = (event) => {
    setSearchText(event.target.value);
  };
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
      window.scrollTo(0, 0);
    }
  };

  const [deAndI, setDeAndI] = useState();
  const [autoAtsSync, setAutoAtsSync] = useState();
  const [standardization, setStandardization] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function formatDate(dateStr) {
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  }
  function formatDate2(dateStr) {
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, "0");
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return ` ${day} ${month} ${year} `;
  }

  const clearSearch = () => {
    setSearchText("");
    setCurrentPage(1);
    GetCustomers({
      pageno: 1,
      pagesize: pageSize,
      search: "",
    })
      .then((response) => {
        if (response?.data == (undefined || null)) {
          setData([]);
          setNoDataAvailable(true);
        } else {
          setData(response.data);
          // setTotalCounts(response?.data[0]?.totalcount);

          response?.data?.[0]?.totalcount &&
            setTotalCounts(response?.data?.[0]?.totalcount);
          window.scrollTo(0, 0);
          setNoDataAvailable(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const btnSearch = () => {
    setCurrentPage(1);
    GetCustomers({
      pagesize: pageSize,
      pageno: 1,
      search: searchText,
    })
      .then((response) => {
        if (response?.data == (undefined || null)) {
          setData([]);
          setNoDataAvailable(true);
        } else {
          setData(response.data);
          // setTotalCounts(response?.data[0]?.totalcount);
          response?.data?.[0]?.totalcount &&
            setTotalCounts(response?.data?.[0]?.totalcount);
          window.scrollTo(0, 0);
          setNoDataAvailable(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    setCurrentPage(1);
  }, []);

  useEffect(() => {
    if (currentPage > 0) {
      GetCustomers({
        pageno: currentPage,
        pagesize: pageSize,
        search: searchText,
      })
        .then((response) => {
          if (response?.data == (undefined || null)) {
            setData([]);
            setNoDataAvailable(true);
          } else {
            setData(response.data);
            // setTotalCounts(response?.data[0]?.totalcount);
            response?.data?.[0]?.totalcount &&
              setTotalCounts(response?.data?.[0]?.totalcount);
            window.scrollTo(0, 0);
            setNoDataAvailable(false);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [currentPage, deAndI, standardization, autoAtsSync]);

  const initializeState = (data) => {
    return data?.reduce((acc, row) => {
      acc[row.customerid] = {
        deAndI: row.show_deni,
        autoAtsSync: row.atssync,
        standardization: row.isstandardization,
      };
      return acc;
    }, {});
  };

  const [rowData, setRowData] = useState(() => initializeState(data));

  useEffect(() => {
    setRowData(initializeState(data));
  }, [data]);

  const handleSave = (customerid, updatedRow) => {
    const rowIndex = data?.findIndex((row) => row?.customerid === customerid);
  
    if (rowIndex === -1) {
      console.error(`No data found for customerid ${customerid}`);
      return;
    }
  
    // Call the API to update client settings
    updateClientSetting({
      customer: customerid,
      show_deni: updatedRow?.deAndI,
      atssync: updatedRow?.autoAtsSync,
      isstandardization: updatedRow?.standardization,
    })
      .then(() => {
        // Update the local state with the new values
        setData(prevData => {
          const updatedData = [...prevData];
          updatedData[rowIndex] = {
            ...updatedData[rowIndex],
            show_deni: updatedRow?.deAndI,
            atssync: updatedRow?.autoAtsSync,
            isstandardization: updatedRow?.standardization,
          };
          return updatedData;
        });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        // Optionally handle any final logic here
      });
  };
  

  const handleCheckboxChange = async (customerid, key, value) => {
    let authCheckData;
    if (key == "autoAtsSync") {
      authCheckData = await checkAtsCredentials(customerid);
    }

    if (key == "autoAtsSync") {
      if (authCheckData.header.status == 200) {
        setRowData((prev) => {
          const newState = {
            ...prev,
            [customerid]: {
              ...prev[customerid],
              [key]: value,
            },
          };
          handleSave(customerid, newState[customerid]);
          return newState;
        });
      } else {
        alert("Credentials required to activate auto sync");

        // Reset checkbox state
        setRowData((prev) => {
          const newState = {
            ...prev,
            [customerid]: {
              ...prev[customerid],
              [key]: false, // Reset to unchecked
            },
          };
          return newState;
        });
      }
    } else {
      setRowData((prev) => {
        const newState = {
          ...prev,
          [customerid]: {
            ...prev[customerid],
            [key]: value,
          },
        };
        handleSave(customerid, newState[customerid]);
        return newState;
      });
    }
  };

  return (
    <>
      <Helmet>
        <style>{"body { background-color: #F3F5F9;}"}</style>
      </Helmet>

      {isMobile ? (
        <React.Fragment>
          <Navbar data={activetab} />

          <div
            style={{
              margin: "18px 10px 8px 10px",
            }}
          >
            <div class="search-containers">
              <input
                type="text"
                id="search"
                placeholder="Search Client Name & Industry"
                value={searchText}
                onChange={searchTextChange}
                onKeyDown={(event) => {
                  if (event.key === "Enter") btnSearch();
                }}
              />

              {searchText && (
                <button
                  style={{
                    position: "absolute",
                    right: "50px",
                    border: "none",
                    outline: "none",
                    background: "transparent",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={clearSearch}
                >
                  <img
                    src="/images/greenCross1.svg"
                    style={{ width: "10px", height: "10px" }}
                    alt="Clear"
                  />
                </button>
              )}
              <button type="submit" onClick={() => btnSearch()}>
                <img
                  style={{ background: "inherit" }}
                  src="/images/Search Icon.svg"
                />
              </button>
            </div>
          </div>
          {noDataAvailable ? (
            <div className="flex justify-center w-full h-[70vh] flex-col gap-2 items-center">
              <div className=" rounded-full border-gray-500  border-2 flex w-12 h-12 justify-center items-center">
                <p className="text-[30px] text-gray-500 font-bold">!</p>
              </div>
              <p className="text-[20px] text-gray-500 font-bold">
                Data not available
              </p>
            </div>
          ) : (
            data?.map((data, i) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  margin: "10px 10px",
                  background: "white",
                  padding: "10px",
                  alignItems: "end",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <p
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "10px",
                      fontSize: "15px",
                      color: "#1F2E39",
                      fontWeight: "600",
                    }}
                  >
                    <div className="green-dot"></div>
                    {data?.customername}
                  </p>
                  <div className="flex flex-raw justify-between gap-28">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <text
                        style={{
                          color: "#5A93ED",
                          fontWeight: "600",
                          fontSize: "15px",
                        }}
                      >
                        INDUSTRY:
                      </text>
                      <text style={{ color: "#1F2E39", fontSize: "15px" }}>
                        {" "}
                        {data?.industryname ? data?.industryname : ""}
                      </text>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                      }}
                    >
                      <text
                        style={{
                          color: "#5A93ED",
                          fontWeight: "600",
                          fontSize: "15px",
                        }}
                      >
                        DATE&nbsp;ADDED:
                      </text>
                      <text style={{ color: "#1F2E39", fontSize: "15px" }}>
                        {formatDate2(data?.createddate)}
                      </text>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <text
                      style={{
                        color: "#5A93ED",
                        fontWeight: "600",
                        fontSize: "15px",
                      }}
                    >
                      CLIENT URL:
                    </text>
                    <text style={{ color: "#1F2E39", fontSize: "15px" }}>
                      {data?.customerpanelurl ? data?.customerpanelurl : ""}
                    </text>
                  </div>

                  <p>
                    <text
                      style={{
                        color: "#5A93ED",
                        fontWeight: "600",
                        fontSize: "15px",
                        marginRight: "68px",
                      }}
                    >
                      ATS SYNC:&nbsp;&nbsp;
                    </text>
                    <label class="switch">
                      <input
                        type="checkbox"
                        id={`${data?.customerid}-autoAtsSync`}
                        onChange={(e) =>
                          handleCheckboxChange(
                            data?.customerid,
                            "autoAtsSync",
                            e.target.checked
                          )
                        }
                        checked={
                          rowData
                            ? rowData[data.customerid]?.autoAtsSync
                            : false
                        }
                      />
                      <span class="slider round"></span>
                    </label>
                  </p>

                  <p style={{ marginTop: "10px" }}>
                    <text
                      style={{
                        color: "#5A93ED",
                        fontWeight: "600",
                        fontSize: "15px",
                        marginRight: "90px",
                      }}
                    >
                      D, E & I:&nbsp;&nbsp;
                    </text>
                    <label class="switch">
                      <input
                        type="checkbox"
                        id={`${data?.customerid}-deAndI`}
                        onChange={(e) =>
                          handleCheckboxChange(
                            data?.customerid,
                            "deAndI",
                            e.target.checked
                          )
                        }
                        checked={
                          rowData ? rowData[data?.customerid]?.deAndI : false
                        }
                      />
                      <span class="slider round"></span>
                    </label>
                  </p>

                  <p style={{ marginTop: "10px" }}>
                    <text
                      style={{
                        color: "#5A93ED",
                        fontWeight: "600",
                        fontSize: "15px",
                      }}
                    >
                      STANDARDIZATION:&nbsp;&nbsp;
                    </text>
                    <label class="switch">
                      <input
                        type="checkbox"
                        id={`${data?.customerid}-standardization`}
                        onChange={(e) =>
                          handleCheckboxChange(
                            data?.customerid,
                            "standardization",
                            e.target.checked
                          )
                        }
                        checked={
                          rowData
                            ? rowData[data?.customerid]?.standardization
                            : false
                        }
                      />
                      <span class="slider round"></span>
                    </label>
                  </p>
                </div>

                <div id={data?.customerid}>
                  <p
                    style={{
                      color: "#21D6AA",
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                    onClick={() => btnEdit(data)}
                  >
                    EDIT
                  </p>
                </div>
              </div>
            ))
          )}
          {noDataAvailable ? (
            ""
          ) : (
            <Pagination
              canNextPage={currentPage < totalPages}
              canPrevPage={currentPage > 1}
              totalPages={totalPages}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
            />
          )}
          <div
            style={{
              textAlign: "center",
              position: "sticky",
              bottom: "0",
              paddingBottom: "20px",
            }}
          >
            <p
              class="Add-Client"
              onClick={btnAddClient}
              style={{
                color: "white",
                fontSize: "15px",
                fontWeight: "500",
                display: "inline-flex",
                padding: "12px 28px",
              }}
            >
              ADD CLIENT
            </p>
          </div>
        </React.Fragment>
      ) : (
        <Box sx={{ display: "flex" }}>
          <Navbar data={activetab} />
          <Box component="main" sx={{ flexGrow: 1, p: 3, height: "100vh", borderRadius:"0px" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {/* <div className="col">
                <p className="pro">Client Settings</p>
              </div> */}
               <HeaderBar title={"Client Settings"}  toggleDrawer={toggleDrawer}/>

              <div class="search-bar">
                <div
                  class="left-side"
                  style={{ marginLeft: "2px", marginTop: "-12px" }}
                >
                  <div
                    className="search-container"
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      width: "500px",
                    }}
                  >
                    <input
                      type="text"
                      id="search"
                      placeholder="Search Client Name & Industry"
                      style={{
                        border: "none",
                        outline: "none",
                        height: "40px",
                        paddingLeft: "10px",
                        width: "200px",
                        flex: 1,
                      }}
                      value={searchText}
                      onChange={searchTextChange}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") btnSearch();
                      }}
                    />

                    {searchText && (
                      <button
                        style={{
                          position: "absolute",
                          right: "40px",
                          border: "none",
                          outline: "none",
                          background: "transparent",
                          height: "40px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                        onClick={clearSearch}
                      >
                        <img
                          src="/images/greenCross1.svg"
                          style={{ width: "10px", height: "10px" }}
                          alt="Clear"
                        />
                      </button>
                    )}

                    <button
                      style={{
                        border: "none",
                        outline: "none",
                        height: "40px",
                        paddingTop: "9px",
                      }}
                      type="submit"
                      onClick={() => btnSearch()}
                    >
                      <img
                        style={{ background: "inherit" }}
                        src="/images/Search Icon.svg"
                      />
                    </button>
                  </div>
                </div>

                <div class="right-side">
                  <div
                    class="filter-text"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <p
                      class="Add-Client"
                      onClick={btnAddClient}
                      style={{
                        color: "white",
                        fontSize: "15px",
                        fontWeight: "500",
                        marginLeft: "10px",
                      }}
                    >
                      ADD CLIENT
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Table */}
            {noDataAvailable ? (
              <div className="flex justify-center w-full h-[70vh] flex-col gap-2 items-center">
                <div className=" rounded-full  flex w-12 h-12 justify-center items-center">
                  <p className="text-[30px] text-gray-500 font-bold">!</p>
                </div>
                <p className="text-[20px] text-gray-500 font-bold">
                  Data not available
                </p>
              </div>
            ) : (
              <div
                className="table"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "12px",
                  marginLeft: "12px",
                  marginTop: "20px",
                }}
              >
                <TableContainer component={Paper} sx={{borderRadius:"0px",boxShadow: 2}} 
                >
                  <Table sx={{ minWidth: 700 }} aria-label="customized table" >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          style={{
                            backgroundColor: "white",
                            color: "#5A93ED",
                            fontSize: "14px",
                            fontWeight: "600",
                            maxWidth: "93px",
                          }}
                        >
                          CLIENT NAME
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: "white",
                            color: "#5A93ED",
                            fontSize: "14px",
                            fontWeight: "600",
                            maxWidth: "58px",
                          }}
                        >
                          INDUSTRY
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: "white",
                            color: "#5A93ED",
                            fontSize: "14px",
                            fontWeight: "600",
                            maxWidth: "82px",
                          }}
                        >
                          DATE ADDED
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: "white",
                            color: "#5A93ED",
                            fontSize: "14px",
                            fontWeight: "600",
                            maxWidth: "190px",
                          }}
                        >
                          CLIENT URL
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: "white",
                            color: "#5A93ED",
                            fontSize: "14px",
                            fontWeight: "600",
                            maxWidth: "65px",
                          }}
                        >
                          ATS SYNC
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: "white",
                            color: "#5A93ED",
                            fontSize: "14px",
                            fontWeight: "600",
                            maxWidth: "54px",
                          }}
                        >
                          D, E, & I
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: "white",
                            color: "#5A93ED",
                            fontSize: "14px",
                            fontWeight: "600",
                            maxWidth: "115px",
                          }}
                        >
                          STANDARDIZATION
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: "white",
                            color: "#5A93ED",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        ></StyledTableCell>
                      </TableRow>
                    </TableHead>
                  </Table>

                  <div
                    className="max-h-[calc(100vh-341px)]"
                    style={{
                      overflow: "auto",
                      WebkitOverflowScrolling: "touch",
                      scrollbarWidth: "none",
                      msOverflowStyle: "none",
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                    }}
                  >
                    <Table aria-label="customized table">
                      <TableBody>
                        {data?.map((data, i) => (
                          <StyledTableRow key={data?.customerid}>
                            <StyledTableCell
                              id={data?.customerid}
                              style={{
                                borderBottom: "none",
                                maxWidth: "102px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                <div className="green-dot"></div>
                                <p
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "500",
                                    color: "#1F2E39",
                                    cursor: "default",
                                  }}
                                >
                                  <Tooltip
                                    title={
                                      data?.customername?.length > 10
                                        ? data?.customername
                                        : ""
                                    }
                                  >
                                    {data?.customername?.length > 10
                                      ? `${data?.customername.substring(
                                          0,
                                          10
                                        )}...`
                                      : data?.customername}
                                  </Tooltip>
                                </p>
                              </div>
                            </StyledTableCell>

                            <StyledTableCell
                              id={data?.customerid}
                              style={{ borderBottom: "none", maxWidth: "68px" }}
                            >
                              <div className="cursor-past">
                                <Tooltip
                                  title={
                                    data?.industryname?.length > 10
                                      ? data?.industryname
                                      : ""
                                  }
                                >
                                  {data?.industryname
                                    ? data?.industryname?.length > 10
                                      ? `${data?.industryname.substring(
                                          0,
                                          10
                                        )}...`
                                      : data?.industryname
                                    : ""}
                                </Tooltip>
                              </div>
                            </StyledTableCell>

                            <StyledTableCell
                              id={data?.customerid}
                              style={{ borderBottom: "none", maxWidth: "90px" }}
                            >
                              <p
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "400",
                                  color: "#1F2E39",
                                }}
                              >
                                {formatDate2(data?.createddate)}
                              </p>
                            </StyledTableCell>

                            <StyledTableCell
                              id={data?.customerid}
                              style={{
                                borderBottom: "none",
                                maxWidth: "140px",
                              }}
                            >
                              <p
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "400",
                                  color: "#1F2E39",
                                  cursor: "default",
                                }}
                              >
                                <Tooltip
                                  title={
                                    data?.customerpanelurl?.length > 24
                                      ? data?.customerpanelurl
                                      : ""
                                  }
                                >
                                  {data?.customerpanelurl?.length > 24
                                    ? `${data?.customerpanelurl.substring(
                                        0,
                                        24
                                      )}...`
                                    : data?.customerpanelurl}
                                </Tooltip>
                              </p>
                            </StyledTableCell>

                            <StyledTableCell
                              id={data?.customerid}
                              style={{
                                borderBottom: "none",
                                maxWidth: "120px",
                              }}
                            >
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  id={`${data?.customerid}-autoAtsSync`}
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      data?.customerid,
                                      "autoAtsSync",
                                      e.target.checked
                                    )
                                  }
                                  checked={
                                    rowData
                                      ? rowData[data?.customerid]?.autoAtsSync
                                      : false
                                  }
                                />
                                <span className="slider round"></span>
                              </label>
                            </StyledTableCell>

                            <StyledTableCell
                              id={data?.customerid}
                              style={{
                                borderBottom: "none",
                                maxWidth: "140px",
                              }}
                            >
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  id={`${data?.customerid}-deAndI`}
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      data?.customerid,
                                      "deAndI",
                                      e.target.checked
                                    )
                                  }
                                  checked={
                                    rowData
                                      ? rowData[data?.customerid]?.deAndI
                                      : false
                                  }
                                />
                                <span className="slider round"></span>
                              </label>
                            </StyledTableCell>

                            <StyledTableCell
                              id={data?.customerid}
                              style={{
                                borderBottom: "none",
                                maxWidth: "130px",
                              }}
                            >
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  id={`${data?.customerid}-standardization`}
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      data?.customerid,
                                      "standardization",
                                      e.target.checked
                                    )
                                  }
                                  checked={
                                    rowData
                                      ? rowData[data?.customerid]
                                          ?.standardization
                                      : false
                                  }
                                />
                                <span className="slider round"></span>
                              </label>
                            </StyledTableCell>

                            <StyledTableCell
                              id={data?.customerid}
                              style={{ borderBottom: "none" }}
                            >
                              <p
                                onClick={() => btnEdit(data)}
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "500",
                                  color: "#21D6AA",
                                  cursor: "pointer",
                                }}
                              >
                                EDIT
                              </p>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </TableContainer>
              </div>
            )}
            {noDataAvailable ? (
              ""
            ) : (
              <Pagination
                canNextPage={currentPage < totalPages}
                canPrevPage={currentPage > 1}
                totalPages={totalPages}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
              />
            )}
          </Box>
        </Box>
      )}
    </>
  );
}
