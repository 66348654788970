import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import ShareResult from "../../components/ShareResult/ShareResult";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Navbar from "../../components/Navbar/Navbar";
import Box from "@mui/material/Box";
import "./CandidateResult.css";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useCandidateData } from "../../data/candidatedata";
import MarkFavorite from "../../components/MarkFavorite/MarkFavorite";
import { useMediaQuery } from "@mui/material";
import AccordionGroup from "@mui/joy/AccordionGroup";
import Accordion from "@mui/joy/Accordion";
import AccordionDetails from "@mui/joy/AccordionDetails";
import AccordionSummary from "@mui/joy/AccordionSummary";
import Typography from "@mui/material/Typography";
import { storage } from "../../libs/localStorage";
import { Helmet } from "react-helmet";
import RatingMatch from "../../components/RatingMatch/RatingMatch";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import StdResumeListPopup from "./StdResumeListPopup";
import { useJobsData } from "../../data/jobsdata";
import { commonData } from "../../data/commondata";
import { GetStdCvTemplateList } from "../../data/standardrization";

const StyledTableCell = styled(TableCell)(({ theme, columnIndex }) => ({
  backgroundColor: columnIndex % 2 === 1 ? "#CBDFFF" : "inherit",
  color: theme.palette.common.black,
  border: "none",
  fontSize: "14px",
  color: "#1F2E39",
  fontWeight: "400",
}));

const HeaderTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#5A93ED",
  color: theme.palette.common.white,
  fontSize: "14px",
  fontWeight: "bold",
}));

const LastTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "#5A93ED",
}));

export default function CandidateResult({ pdfContentRef }) {
  // const { analysisid } = useParams();
  const [data, setData] = useState(null);
  const userType = localStorage.getItem("usertype");
  const [customer, setcustomer] = useState(null);
  const navigate = useNavigate();
  const { retrieveToken } = storage();
  const { GetCandidateResult, MarkAnalysisFavorite, GetCustomerById } =
    useCandidateData();
  const { GetDownloadUrl } = commonData();
  const activetab = "";
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("820"));
  const isMobileView = useMediaQuery(theme.breakpoints.down("422"));
  const [index, setIndex] = React.useState(0);
  const [isOpened, setOpened] = React.useState(false);
  const [expanded, setExpanded] = useState(false);
  const [TemplateData, setTemplatedata] = useState();
  const [description, setDescription] = useState();
  const token = retrieveToken();
  const { GetAtsUrlByAtsJobcode } = useJobsData();
  const capitalizeWords = (str) => {
    return str?.replace(/\b\w/g, (char) => char?.toUpperCase());
  };
  const location = useLocation();
  const params = location.state;
  const [indexCount, setIndexCount] = useState(0);
  const [explicit, setExplicit] = useState("");

  useEffect(() => {
    if (token) {
      GetCustomerById()
        .then((response) => {
          let increment = 0;
          const showDeni = response?.data[0]?.show_deni;
          const middleEast = response?.data[0]?.ismiddleeast;

          if (showDeni) increment += 1;
          if (middleEast) increment += 1;

          if (increment > 0) {
            setIndexCount(increment);
          }
          setcustomer(response?.data[0]);
        })
        .catch((error) => {
          console.error(error);
        });
      GetCandidateResult(parseInt(params))
        .then((response) => {
          setData(response?.data[0]);
          response?.data[0]?.nationality == "Unknown"
            ? setExplicit(response?.data[0]?.nationality)
            : response?.data[0]?.isnationalityexplicit
            ? setExplicit(response?.data[0]?.nationality)
            : setExplicit("Most likely " + response?.data[0]?.nationality);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      // navigate("/Unauthorized");
      window.location.replace(process.env.REACT_APP_API_LOGIN_URL);
    }
  }, []);

  useEffect(() => {
    GetStdCvTemplateList({ customerid: customer?.customerid })
      .then((response) => {
        setTemplatedata(response?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const btnMarkFavorite = (cand) => {
    MarkAnalysisFavorite({
      analysisid: cand?.analysisid,
      isfavorite: !cand?.isfavorite,
    })
      .then((response) => {
        setData({ ...data, isfavorite: !cand?.isfavorite });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const redirectToAts = (params) => {
    navigate("/processedcandidate", { state: params });
    const { atsjobcode, atsid } = params;
    if (atsid) {
      GetAtsUrlByAtsJobcode({
        atsjobcode: atsjobcode,
      })
        .then((response) => {
          window.open(response?.data[0]?.atsurl, "_blank");
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
    }
  };

  const downloadResume = (url) => {
    url &&
      GetDownloadUrl({
        url: url,
      })
        .then((response) => {
          window.open(response?.data?.url, "_blank");
        })
        .catch((error) => {
          console.error(error);
        });
  };

  const updateParentRating = (value) => {
    setData({ ...data, rating: value.rating, remark: value.remark });
  };

  const btnStdResume = () => {
    if (TemplateData && TemplateData?.length == 1) {
      window.open(
        `${process.env.REACT_APP_API_BASE_URL}/api/downloadstandardizecv?token=${token}&analysisid=${data?.analysisid}&templateid=${TemplateData[0]?.templateid}`,
        "_blank"
      );
    } else {
      setOpened(true);
    }
    // userType == "A"
    //   ? setOpened(true)
    //   : data?.standardizedcvjson &&
    //     window.open(
    //       process.env.REACT_APP_API_BASE_URL +
    //         `/api/downloadstandardizecv?token=${token}&analysisid=${data?.analysisid}`,
    //       "_blank"
    //     );
  };

  return (
    <>
      {isMobile ? (
        <>
          <Helmet>
            <title>Match Result</title>
            {/* <meta name="description" content="App Description" /> */}
            {/* <meta name="theme-color" content="#008f68" /> */}
          </Helmet>
          <React.Fragment>
            <div style={{ position: "sticky", top: "0" }}>
              <p
                style={{
                  color: "#1F2E39",
                  background: "white",
                  fontSize: "17px",
                  fontWeight: "700",
                  marginTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "10px",
                  display: "flex",
                  boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.1)",
                }}
              >
                <img
                  onClick={() => window.history.back()}
                  src="/images/Back.svg"
                  style={{ cursor: "pointer" }}
                />
                &nbsp;&nbsp;&nbsp; Match Result
              </p>
            </div>

            <div
              class="JobId-mobile-row"
              style={{
                marginLeft: "10px",
                marginRight: "10px",
                marginTop: "20px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div class="JobId-mobile-column">
                <p
                  style={{ color: "rgba(33, 214, 170, 1)", fontWeight: "600" }}
                  onClick={() =>
                    redirectToAts({
                      atsjobcode: data?.atsjobcode,
                      atsid: data?.atsid,
                    })
                  }
                >
                  JOB ID: {data?.atsjobcode}
                </p>
              </div>
              <div className="flex flex-row gap-3">
                {data?.standardizedcvjson && userType == "A" && (
                  <div className="flex w-6 h-6">
                    <img
                      src="/images/Standardization1.svg"
                      style={{
                        cursor: "pointer",
                        marginBottom: "3px",
                      }}
                      alt=""
                      onClick={() =>
                        navigate(`/standardizationfile/${data?.analysisid}`)
                      }
                    />
                    {/* <button  className="text-[#21D6AA] font-medium"  >
                          VIEW STD
                          </button> */}
                  </div>
                )}
                <div>
                  <RatingMatch
                    analysisid={data?.analysisid}
                    preRating={data?.rating}
                    preRemark={data?.remark}
                    updateParentRating={updateParentRating}
                    updateRating={(rating) => setData({ ...data, rating })}
                  />
                </div>
                <div>
                  <ShareResult
                    contentRef={pdfContentRef}
                    analysisid={params}
                    candidatename={data?.candidatename}
                    jobid={data?.atsjobcode}
                  />
                </div>
                <div
                  class="JobId-mobile-column"
                  onClick={(e) => btnMarkFavorite(data)}
                >
                  <MarkFavorite
                    imgUrl={
                      data?.isfavorite
                        ? "/images/filled-bookmark.svg"
                        : "/images/bookmark.svg"
                    }
                    style={{ width: "70%", marginRight: "5px" }}
                  />
                </div>
              </div>
            </div>

            <AccordionGroup
              sx={{
                maxWidth: "100%",
                marginTop: "10px",
                padding: "5px",
                borderRadius: "5px",
              }}
            >
              <Accordion
                expanded={index === 1}
                onChange={(event, expanded) => {
                  setIndex(expanded ? 1 : null);
                }}
              >
                <AccordionSummary
                  sx={{
                    backgroundColor: "#21D6AA",
                    borderRadius: expanded ? "5px 5px 0 0" : "5px 5px 5px 5px",
                    position: "sticky",
                    top: "0",
                    "&:hover": {
                      backgroundColor: "#21D6AA", // maintain the same color as the default to remove hover effect
                    },
                  }}
                  onClick={() => setExpanded(!expanded)}
                >
                  <Typography
                    variant="body1"
                    style={{
                      color: index === 1 ? "white" : "white",
                      fontFamily: "Rubik",
                      fontWeight: "500",
                    }}
                  >
                    JOB DESCRIPTION
                  </Typography>
                </AccordionSummary>

                <div class="accordionsummary">
                  <AccordionDetails style={{ fontSize: "13px" }}>
                    {/* {data?.jobdescription} */}
                    <ReactQuill
                      value={data?.jobdescription}
                      readOnly={true}
                      modules={{ toolbar: false }}
                    />
                  </AccordionDetails>
                </div>
              </Accordion>
            </AccordionGroup>
            <p class="p-Rank" style={{ marginLeft: "10px", fontSize: "17px" }}>
              Ranking Table
            </p>
            <div>
              <p style={{ background: "#CBDFFF", padding: "13px 10px" }}>
                <text style={{ fontWeight: "600" }}> Candidate Name:</text>{" "}
                {data?.candidatename}
              </p>
              {customer?.ismiddleeast && (
                <p
                  style={{
                    background: 1 % 2 === 0 ? "#CBDFFF" : "#ffff",
                    padding: "13px 10px",
                  }}
                >
                  <text style={{ fontWeight: "600" }}>Nationality:</text>{" "}
                  {capitalizeWords(explicit)}
                </p>
              )}
              {customer?.show_deni && (
                <p
                  style={{
                    background:
                      (customer?.ismiddleeast ? 2 : 1) % 2 === 0
                        ? "#CBDFFF"
                        : "#ffff",
                    padding: "13px 10px",
                  }}
                >
                  <text style={{ fontWeight: "600" }}>DE&I:</text>{" "}
                  {capitalizeWords(data?.ethnicity)}
                </p>
              )}
              {/* <p style={{padding:'13px 10px'}}><text style={{fontWeight:'600'}}> Ethnicity:</text>  African American or Unknown</p> */}
              {/* <p style={{ padding: "13px 10px", lineHeight: "23px" }}>
                <text style={{ fontWeight: "600" }}> Matching Skills:</text>{" "}
                {data?.matching_skills}
              </p> */}
              <p
                style={{
                  background: (indexCount + 1) % 2 === 0 ? "#CBDFFF" : "#ffff",
                  padding: "13px 10px",
                  lineHeight: "23px",
                }}
              >
                <text style={{ fontWeight: "600" }}> Recent Title:</text>{" "}
                {data?.current_job_title}
              </p>
              <p
                style={{
                  padding: "13px 10px",
                  lineHeight: "23px",
                  background: (indexCount + 2) % 2 === 0 ? "#CBDFFF" : "#ffff",
                }}
              >
                <text style={{ fontWeight: "600" }}> Education:</text>{" "}
                {data?.education}
              </p>
              <p
                style={{
                  background: (indexCount + 3) % 2 === 0 ? "#CBDFFF" : "#ffff",
                  padding: "13px 10px",
                  lineHeight: "23px",
                  borderBottom: "10px solid #5A93ED",
                }}
              >
                <text style={{ fontWeight: "600" }}> Match %:</text>{" "}
                {data?.match_percentage}%
              </p>
            </div>

            <div
              style={{
                marginRight: "10px",
                marginLeft: "10px",
                marginTop: "20px",
                marginBottom: "20px",
                lineHeight: "20px",
              }}
            >
              <div
                className={`${
                  isMobileView ? "flex flex-col gap-3" : "flex flex-row"
                }`}
                style={{
                  justifyContent: "space-between",
                }}
              >
                <div class="prof">Analysis Summary</div>
                <div className="flex gap-3">
                  <div
                    className="flex gap-1.5 cursor-pointer"
                    onClick={() => downloadResume(data?.resumeurl)}
                  >
                    <img alt="downloadResume" src="/Result.svg" />
                    <p
                      className="text-[15px] font-rubik font-medium cursor-pointer"
                      style={{ color: "#21D6AA" }}
                    >
                      RESUME
                    </p>
                  </div>
                  {userType == "A" &&
                    data?.standardizedcvjson &&
                    data?.isstandardized && (
                      <div className="flex gap-1.5" onClick={btnStdResume}>
                        <img
                          src={
                            data?.standardizedcvjson
                              ? "/Result.svg"
                              : "/Result_1.svg"
                          }
                          style={{
                            cursor: data?.standardizedcvjson
                              ? "pointer"
                              : "not-allowed",
                          }}
                        />

                        <p
                          className="text-[15px] font-rubik font-medium"
                          style={{
                            color: data?.standardizedcvjson
                              ? "#21D6AA"
                              : "#708ea4",
                            cursor: data?.standardizedcvjson
                              ? "pointer"
                              : "not-allowed",
                          }}
                        >
                          STD.RESUME
                        </p>
                      </div>
                    )}
                </div>
              </div>
              <br />
              <p class="highlys">{data?.analysis_summary}</p>
              <br />
              <p class="helthcares">
                <text style={{ fontWeight: "600" }}>Pros:</text> {data?.pros}
              </p>
              <br />
              <p class="helthcares">
                <text style={{ fontWeight: "600" }}>Cons:</text> {data?.cons}
              </p>
            </div>
          </React.Fragment>
        </>
      ) : (
        <>
          <Helmet>
            <style>{"body { background-color: #FFFFFF;}"}</style>
            <title>Match Result</title>
            {/* <meta name="description" content="App Description" /> */}
            {/* <meta name="theme-color" content="#008f68" /> */}
          </Helmet>
          <Box sx={{ display: "flex", height: "100vh" }}>
            <Navbar data={activetab} />
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                maxHeight: "100vh",
                overflow: "auto",
                paddingTop: "24px",
                paddingLeft: "24px",
                paddingRight: "24px",
              }}
            >
              <div class="fulldiv">
                <div class="match-dta">
                  <div class="matchdta-child">
                    <button onClick={() => navigate(-1)} className="p-match">
                      <img
                        style={{ width: "16px" }}
                        src="/images/arrow-left.svg"
                      />
                      Back
                    </button>
                    <p
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        redirectToAts({
                          atsjobcode: data?.atsjobcode,
                          atsid: data?.atsid,
                          jobid: data?.jobid,
                        })
                      }
                      class="p-job"
                    >
                      JOB ID: {data?.atsjobcode}
                    </p>
                  </div>
                  <div class="match-dta2 ">
                    <div className=" flex analyst-child gap-5">
                      <div class="  button-parent">
                        {data?.standardizedcvjson && userType == "A" && (
                          <div class=" analist-cjild">
                            <img
                              src="/images/Standardization1.svg"
                              className="mt-1"
                              style={{
                                width: "22%",
                                marginRight: "6px",
                                cursor: "pointer",
                              }}
                              alt=""
                            />

                            <button
                              className="mt-1"
                              onClick={() =>
                                navigate(
                                  `/standardizationfile/${data?.analysisid}`
                                )
                              }
                            >
                              VIEW STD
                            </button>
                          </div>
                        )}
                        <div
                          className="flex items-center mt-6"
                          class="analist-cjild"
                          onClick={(e) => btnMarkFavorite(data)}
                        >
                          <MarkFavorite
                            imgUrl={
                              data?.isfavorite
                                ? "/images/filled-bookmark.svg"
                                : "/images/bookmark.svg"
                            }
                            style={{
                              width: "60%",
                              marginRight: "5px",
                              cursor: "pointer",
                              marginTop: "5px",
                            }}
                          />
                          <p style={{ cursor: "pointer", marginTop: "3px" }}>
                            {data?.isfavorite
                              ? "ADDED TO FAVORITES"
                              : "ADD TO FAVORITES"}
                          </p>
                        </div>
                        <div className="mt-2">
                          <ShareResult
                            contentRef={pdfContentRef}
                            analysisid={params}
                            candidatename={data?.candidatename}
                            jobid={data?.atsjobcode}
                          />
                        </div>
                      </div>

                      <div class="button-child2">
                        <RatingMatch
                          analysisid={data?.analysisid}
                          preRating={data?.rating}
                          preRemark={data?.remark}
                          updateParentRating={updateParentRating}
                          updateRating={(rating) =>
                            setData({ ...data, rating })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <AccordionGroup
                selected="false"
                sx={{
                  maxWidth: "100%",
                  marginBottom: "10px",
                  borderRadius: "5px",
                  boxShadow:
                    "8px 8px 15px rgba(33, 214, 170, 0.1), -8px -8px 15px rgba(33, 214, 170, 0.1), 8px -8px 15px rgba(33, 214, 170, 0.1), -8px 8px 15px rgba(33, 214, 170, 0.1)",
                }}
                style={{ marginBottom: "20px" }}
              >
                <Accordion
                  expanded={index === 1}
                  onChange={(event, expanded) => {
                    setIndex(expanded ? 1 : null);
                  }}
                >
                  <AccordionSummary
                    sx={{
                      backgroundColor: "#21D6AA !important",
                      borderRadius: expanded
                        ? "5px 5px 0 0"
                        : "5px 5px 5px 5px",
                      // position: 'sticky',
                      top: "0",
                    }}
                    disableRipple
                    onClick={() => setExpanded(!expanded)}
                  >
                    <Typography
                      variant="body1"
                      style={{
                        color: "black",
                        fontFamily: "Rubik, Medium",
                        fontSize: "13px",
                      }}
                    >
                      JOB DESCRIPTION: {data?.jobtitle}
                    </Typography>
                  </AccordionSummary>

                  <div class="accordionsummary">
                    <AccordionDetails style={{ fontSize: "13px" }}>
                      {/* {data?.jobdescription} */}
                      <ReactQuill
                        className="w-full"
                        style={{ paddingTop: "10px" }}
                        value={data?.jobdescription}
                        readOnly={true}
                        modules={{ toolbar: false }}
                      />
                    </AccordionDetails>
                  </div>
                </Accordion>
              </AccordionGroup>

              {/* <p class="p-Rank">Ranking Table</p> */}
              <TableContainer component={Paper} style={{ boxShadow: "none" }}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <HeaderTableCell
                        align="left"
                        style={{
                          whiteSpace: "nowrap",
                          fontFamily: "Rubik, Bold",
                          padding: "8px 16px",
                        }}
                      >
                        Candidate Name
                      </HeaderTableCell>
                      {/* <HeaderTableCell align="left">Ethnicity</HeaderTableCell> */}

                      {customer?.ismiddleeast && (
                        <HeaderTableCell
                          align="left"
                          style={{
                            whiteSpace: "nowrap",
                            fontFamily: "Rubik, Bold",
                            padding: "8px 16px",
                          }}
                        >
                          Nationality
                        </HeaderTableCell>
                      )}

                      {customer?.show_deni && (
                        <HeaderTableCell
                          align="left"
                          style={{
                            whiteSpace: "nowrap",
                            fontFamily: "Rubik, Bold",
                            padding: "8px 16px",
                          }}
                        >
                          DE&I
                        </HeaderTableCell>
                      )}
                      {/* <HeaderTableCell
                        align="left"
                        style={{
                          whiteSpace: "nowrap",
                          fontFamily: "Rubik, Bold",
                          padding: "8px 16px",
                        }}
                      >
                        Matching Skills
                      </HeaderTableCell> */}
                      <HeaderTableCell
                        align="left"
                        style={{
                          whiteSpace: "nowrap",
                          fontFamily: "Rubik, Bold",
                          padding: "8px 16px",
                        }}
                      >
                        Recent Title
                      </HeaderTableCell>
                      <HeaderTableCell
                        align="left"
                        style={{
                          whiteSpace: "nowrap",
                          fontFamily: "Rubik, Bold",
                          padding: "8px 16px",
                        }}
                      >
                        Education
                      </HeaderTableCell>
                      <HeaderTableCell
                        align="left"
                        style={{
                          whiteSpace: "nowrap",
                          fontFamily: "Rubik, Bold",
                          padding: "8px 16px",
                        }}
                      >
                        Match %
                      </HeaderTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <StyledTableCell
                        columnIndex={0}
                        style={{
                          verticalAlign: "top",
                          background: 0 % 2 === 0 ? "#ffff" : "#CBDFFF",
                        }}
                      >
                        <p>{data?.candidatename}</p>
                        <div
                          className="flex gap-3 cursor-pointer"
                          onClick={() => downloadResume(data?.resumeurl)}
                        >
                          <img src="/Result.svg" />
                          <p
                            className="text-[15px] font-rubik font-medium mt-2 cursor-pointer"
                            style={{ color: "#21D6AA" }}
                          >
                            RESUME
                          </p>
                        </div>
                        {userType == "A" &&
                          data?.standardizedcvjson &&
                          data?.isstandardized && (
                            <div className="flex gap-3" onClick={btnStdResume}>
                              <img
                                src={
                                  data?.standardizedcvjson
                                    ? "/Result.svg"
                                    : "/Result_1.svg"
                                }
                                style={{
                                  cursor: data?.standardizedcvjson
                                    ? "pointer"
                                    : "not-allowed",
                                }}
                              />

                              <p
                                className="text-[15px] font-rubik font-medium mt-2"
                                style={{
                                  color: data?.standardizedcvjson
                                    ? "#21D6AA"
                                    : "#708ea4",
                                  cursor: data?.standardizedcvjson
                                    ? "pointer"
                                    : "not-allowed",
                                }}
                              >
                                STD.RESUME
                              </p>
                            </div>
                          )}
                        <StdResumeListPopup
                          setOpen={setOpened}
                          open={isOpened}
                          templatedata={TemplateData}
                          data={data}
                        />
                      </StyledTableCell>
                      {customer?.ismiddleeast && (
                        <StyledTableCell
                          columnIndex={1}
                          style={{
                            verticalAlign: "top",
                            background: 1 % 2 === 0 ? "#ffff" : "#CBDFFF",
                          }}
                        >
                          <p>
                            {explicit.split(" ").map(function (item, idx) {
                              return (
                                <span key={idx}>
                                  {capitalizeWords(item)}
                                  <br />
                                </span>
                              );
                            })}
                          </p>
                        </StyledTableCell>
                      )}
                      {customer?.show_deni && (
                        <StyledTableCell
                          columnIndex={customer?.ismiddleeast ? 2 : 1}
                          style={{
                            verticalAlign: "top",
                            background:
                              (customer?.ismiddleeast ? 2 : 1) % 2 === 0
                                ? "#ffff"
                                : "#CBDFFF",
                          }}
                        >
                          <p>
                            {data?.ethnicity
                              .split(" ")
                              .map(function (item, idx) {
                                return (
                                  <span key={idx}>
                                    {capitalizeWords(item)}
                                    <br />
                                  </span>
                                );
                              })}
                          </p>
                        </StyledTableCell>
                      )}
                      {/* <StyledTableCell
                        columnIndex={customer?.show_deni ? 1 + 1 : 1}
                        style={{ verticalAlign: "top" }}
                      >
                        
                        <p>{data?.matching_skills}</p>
                      </StyledTableCell> */}

                      <StyledTableCell
                        columnIndex={indexCount + 1}
                        style={{
                          verticalAlign: "top",
                          background:
                            (indexCount + 1) % 2 === 0 ? "#ffff" : "#CBDFFF",
                        }}
                      >
                        <p>
                          {data?.current_job_title
                            .split(" ")
                            .map(function (item, idx) {
                              return (
                                <span key={idx}>
                                  {item}
                                  <br />
                                </span>
                              );
                            })}
                        </p>
                      </StyledTableCell>

                      <StyledTableCell
                        columnIndex={indexCount + 2}
                        style={{
                          verticalAlign: "top",
                          background:
                            (indexCount + 2) % 2 === 0 ? "#ffff" : "#CBDFFF",
                        }}
                      >
                        <p>{data?.education}</p>
                      </StyledTableCell>

                      <StyledTableCell
                        columnIndex={indexCount + 3}
                        style={{
                          verticalAlign: "top",
                          background:
                            (indexCount + 3) % 2 === 0 ? "#ffff" : "#CBDFFF",
                        }}
                      >
                        <p>{data?.match_percentage}%</p>
                      </StyledTableCell>
                    </TableRow>

                    <LastTableRow>
                      {Array.from({
                        length: indexCount + 4,
                      }).map((_, columnIndex) => (
                        <StyledTableCell
                          key={columnIndex}
                          style={{ padding: "0px 0px 10px" }}
                        />
                      ))}
                    </LastTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <br />

              <p class="prof">Analysis Summary</p>
              <br />
              <p class="highly">{data?.analysis_summary}</p>
              <br />
              <p class="helthcare">
                <text style={{ fontWeight: "600", fontFamily: "Rubik" }}>
                  <span className="prosandcons">Pros:</span>
                </text>{" "}
                {data?.pros}
              </p>
              <br />
              <p class="helthcare">
                <text style={{ fontWeight: "600", fontFamily: "Rubik" }}>
                  <span className="prosandcons">Cons:</span>
                </text>{" "}
                {data?.cons}
              </p>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
